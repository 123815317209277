import { Tournament } from './data';

export function getBudgetSummary(
  tournament: Tournament,
  team: string
): {
  totalSpent: number;
  availableNextBid: number;
  locked: number;
  totalAvailable: number;
  currentCategory: string;
  categories: Array<{
    name: string;
    bought: number;
    remaining: number;
    lockedAmount: number;
  }>;
} {
  const teamInfo = tournament?.teams?.find((x: any) => x.name === team);
  let teamBudget = tournament?.auction?.teamBudget;

  if (
    teamInfo &&
    teamInfo.purse?.type === 'custom' &&
    teamInfo.purse?.totalBudget
  ) {
    teamBudget = teamInfo.purse.totalBudget;
  }

  const players =
    tournament?.players?.filter(
      (z: any) => z.status === 'sold' && z.team === team
    ) || [];

  const totalSpent = players.reduce((prev: number, curr: any) => {
    return prev + (curr.soldAt || 0);
  }, 0);

  const finalCategories: Array<{
    name: string;
    bought: number;
    remaining: number;
    lockedAmount: number;
  }> = [];

  let currentCategory = '';
  let totalLockedAmount = 0;
  if (teamInfo) {
    for (const cat of tournament?.auction?.categories || []) {
      const playerToBuy =
        (teamInfo.purse?.type === 'custom' &&
        teamInfo.purse?.playersToBuy?.find((x: any) => x.category === cat.name)
          ? teamInfo.purse?.playersToBuy?.find(
              (x: any) => x.category === cat.name
            )?.noOfPlayers
          : cat.players) || 0;

      const catToPush: {
        name: string;
        bought: number;
        remaining: number;
        lockedAmount: number;
      } = {
        name: cat.name,
        bought: players.filter((x: any) => x.category === cat.name).length || 0,
        remaining: 0,
        lockedAmount: 0,
      };

      catToPush.remaining = playerToBuy - catToPush.bought;
      if (catToPush.remaining > 0 && !currentCategory) {
        currentCategory = cat.name;
      }

      if (catToPush.remaining > 0) {
        catToPush.lockedAmount =
          cat.name === currentCategory
            ? (catToPush.remaining - 1) * cat.baseValue
            : catToPush.remaining * cat.baseValue;
        totalLockedAmount += catToPush.lockedAmount;
      }

      finalCategories.push(catToPush);
    }
  }

  return {
    totalSpent: totalSpent,
    locked: totalLockedAmount,
    availableNextBid: teamBudget - totalSpent - totalLockedAmount,
    totalAvailable: teamBudget - totalSpent,
    currentCategory: currentCategory,
    categories: finalCategories,
  };
}
