import {
  AdvancedLoadingButton,
  downloadFile,
  ModalProps,
  renderModal,
} from '@erp_core/erp-ui-components';
import { CogIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
// import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ErpV2Api } from '../../../models/interfaces/adapters/erp-v2-api';
import { renderPerformAuction } from './auction';
import { formatCurrency, Player, Team, Tournament } from './data';
import { renderEditTournament } from './edit-tournament';
import { PlayerCard } from './player-card';
import { AuctionRoulette } from './player-selector';
import { Rules } from './rules';
import { getBudgetSummary } from './util';

const utilityServiceUrl = 'https://utility-api.recat.in'; // 'http://localhost:9123';

export function renderAuction({ erpV2Api }: { erpV2Api: ErpV2Api }) {
  const PerformAuction = renderPerformAuction();
  return function Auction() {
    const Modal = renderModal();
    const [tab, setTab] = useState<'teams' | 'players' | 'rules'>('teams');
    const [tournament, setTournament] = useState<Tournament>(
      JSON.parse(JSON.stringify({}))
    );
    const [modalState, setModalState] = useState<ModalProps>({
      isVisible: false,
      body: <></>,
      title: '',
      onClose: () => {
        setModalState((ms) => ({ ...ms, visible: false }));
      },
      size: 'large',
    });

    useEffect(() => {
      new Promise((resolve) => resolve(true)).then(async () => {
        const httpInstance = await erpV2Api.getPlainInstance({
          apiUrl: utilityServiceUrl,
        });
        const tournamentId = '5E33420E-4A64-49C3-BE95-B54B5CF2EB02';
        const res = await httpInstance.get(
          `/api/v1/tournaments/${tournamentId}`
        );
        console.log(res);
        if (res.data.details) {
          setTournament(res.data.details);
        }
      });
    }, []);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const [filter, setFilter] = useState<string>('');
    const [playerList, setPlayerList] = useState<{
      batsmen: Array<Player>;
      bowlers: Array<Player>;
      keepers: Array<Player>;
      allrounders: Array<Player>;
    }>({
      batsmen: [],
      bowlers: [],
      keepers: [],
      allrounders: [],
    });

    async function saveTournament(trnmt: Tournament) {
      setTournament(trnmt);
      localStorage.setItem('auction', JSON.stringify(trnmt));

      const httpInstance = await erpV2Api.getPlainInstance({
        apiUrl: utilityServiceUrl,
      });
      const tournamentId = '5E33420E-4A64-49C3-BE95-B54B5CF2EB02';
      const payload = {
        id: tournamentId,
        details: trnmt,
      };
      const res = await httpInstance.patch(
        `/api/v1/tournaments/${tournamentId}`,
        payload
      );
      console.log(res);
    }

    useEffect(() => {
      if (tab === 'teams') {
        // setSelectedPlayerCategory(null);
      }

      if (tab === 'players') {
        setSelectedTeam(null);
      }

      if (tab === 'rules') {
        setSelectedTeam(null);
      }

      setPlayerList({
        batsmen: [],
        bowlers: [],
        keepers: [],
        allrounders: [],
      });
    }, [tab]);

    const playersFilter = [
      {
        name: 'All Players',
        filter: () => {
          return tournament.players;
        },
      },
      {
        name: 'Pending',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'pending');
        },
      },
      {
        name: 'Sold Players',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'sold');
        },
      },
      {
        name: 'UnSold Players',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'unsold');
        },
      },
      ...(tournament.auction?.categories || []).map((y) => {
        return {
          name: `${y.name} Category`,
          filter: () => {
            return tournament.players.filter((x) => x.category === y.name);
          },
        };
      }),
    ];

    useEffect(() => {
      if (selectedTeam) {
        const players = tournament.players.filter(
          (x) => x.status === 'sold' && x.team === selectedTeam.name
        );
        setPlayerList({
          batsmen: players.filter((y) => y.skills === 'batsman'),
          bowlers: players.filter((y) => y.skills === 'bowler'),
          allrounders: players.filter((y) => y.skills === 'allrounder'),
          keepers: players.filter((y) => y.skills === 'keeper'),
        });
      }

      // console.log(tournament?.players?.map(x => {
      //   return {
      //     '1-playerNumber': x.serial,
      //     '2-name': x.name,
      //     '3-team': x.team,
      //     '4-soldAt': x.soldAt,
      //     '5-tshirtName': x.tshirtName,
      //     '6-tshirtSize': x.tshirtSize,
      //     '7-contact': x.contact
      //   }
      // }))
    }, [selectedTeam, tournament.players]);

    async function selectPlayer(sp: { player: Player }) {
      console.log('Player', sp.player.name, 'is selected');
      setModalState({
        size: 'large',
        isVisible: true,
        title: `Player ${sp.player.name} is selected`,
        body: (
          <div>
            <PerformAuction
              player={sp.player}
              tournament={tournament}
              onClose={() => {
                setModalState((ms) => ({ ...ms, isVisible: false }));
              }}
              onSold={async ({ player, bid }) => {
                console.log('plaer sod', player, bid);
                const p = tournament.players.find((x) => {
                  return x.name === player.name;
                });
                const t = tournament.teams.find(
                  (x) => x.name === bid.topBidder
                );

                if (p && t) {
                  p.soldAt = bid.value;
                  p.status = 'sold';
                  p.team = bid.topBidder;
                  p.bidActivities = bid.activities || [];
                  // t.purse.spent = t.purse.spent + bid.value;
                  // t.purse.remaining = t.purse.remaining - bid.value;
                  downloadFile({
                    fileType: 'application/json',
                    fileName: `mepl-${p?.name}-${moment().format('MMDD-HHmm')}`,
                    result: JSON.stringify(tournament),
                  });
                  await saveTournament({ ...tournament });
                }
              }}
              onUnsold={() => {}}
            />
          </div>
        ),
        onClose: () => {
          console.log('ye click hua');
          setModalState((ms) => ({ ...ms, isVisible: false }));
        },
      });
    }

    return (
      <div className='bg-black w-full text-white'>
        <div className='bg-gray-700 my-2 mx-2 h-500'>
          {/* <img src='https://lh3.googleusercontent.com/d/1m-uOoFzHn4oUGlEsDSEfPBbJ2QhBJzlM' /> */}
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/4'>
              <div className='m-1 bg-gray-600'>
                <img
                  // src='https://lh3.googleusercontent.com/d/1FIeOZSmnHSECEil3-TIDXGIvKUg9GY5f'
                  src={tournament.logo}
                  alt=''
                  className='h-48 mx-auto bg-gray-200 border rounded-full animate-hflip'
                />
                <div className='text-4xl text-center text-yellow-500'>
                  {tournament.name}
                  <AdvancedLoadingButton
                    text={<CogIcon className='h-8 inline animate-none' />}
                    defaultStyle='p-0 bg-none'
                    behaviour='modal'
                    modal={{
                      size: 'large',
                      title: 'Edit Tournament',
                      content: ({ onClose }) => {
                        const EditTournament = renderEditTournament();
                        return (
                          <EditTournament
                            tournament={tournament}
                            saveTournament={saveTournament}
                            onClose={onClose}
                          />
                        );
                      },
                    }}
                  />
                </div>
                <div className='flex'>
                  <div className='w-1/2 text-center animate-pulse'>
                    <div className='text-lg'>Organized By</div>
                    <div className='text-xl'>
                      {tournament.organizer?.photo ? (
                        <img
                          className='h-10 inline'
                          alt={tournament.organizer.name}
                          src={tournament.organizer.photo}
                        />
                      ) : null}
                      {tournament.organizer?.name}
                    </div>
                  </div>
                  <div className='w-1/2 text-center'>
                    <div className='text-lg animate-pulse'>Auctioned By</div>
                    <div className='text-xl'>
                      {tournament.auctionedBy?.avatar ? (
                        <img
                          className='h-10 inline'
                          alt={tournament.auctionedBy.name}
                          src={tournament.auctionedBy.avatar}
                        />
                      ) : null}
                      {tournament.auctionedBy?.name}
                      <AdvancedLoadingButton
                        text={<DevicePhoneMobileIcon className='inline w-6' />}
                        defaultStyle='bg-none'
                        behaviour='modal'
                        modal={{
                          size: 'large',
                          title: `Contact ${tournament.auctionedBy?.name}`,
                          content: () => {
                            return (
                              <div className='text-center text-black'>
                                <div className='text-xl'>
                                  This auction event is hosted by:
                                </div>
                                <div className='text-3xl'>
                                  {tournament.auctionedBy.name}
                                </div>
                                <div>
                                  <img
                                    className='mx-auto w-96'
                                    alt={tournament.auctionedBy.name}
                                    src={
                                      tournament.auctionedBy.photo ||
                                      'https://lh3.googleusercontent.com/d/1fazFzr2oIgyBTdowvNaHSMwfUx8ufFtR'
                                    }
                                  />
                                </div>
                                <div className='text-3xl'>
                                  {tournament.auctionedBy.contact ||
                                    '9876543210'}
                                </div>
                              </div>
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-3/4'>
              <div className='bg-gray-800'>
                <div className='flex justify-center'>
                  <div
                    className='border border-gray-700 rounded-lg p-2 text-center w-64 m-1 cursor-pointer'
                    onClick={() => {
                      if (tab !== 'teams') {
                        setTab('teams');
                      }
                    }}
                  >
                    Teams
                  </div>
                  <div
                    className='border border-gray-700 rounded-lg py-2 text-center w-64 m-1 cursor-pointer'
                    onClick={() => {
                      if (tab !== 'players') {
                        setTab('players');
                      }
                    }}
                  >
                    Players
                  </div>
                  <div
                    className='border border-gray-700 rounded-lg py-2 text-center w-64 m-1 cursor-pointer'
                    onClick={() => {
                      if (tab !== 'rules') {
                        setTab('rules');
                      }
                    }}
                  >
                    Rules
                  </div>
                </div>
                {tab === 'teams' ? (
                  <>
                    <div className='flex flex-wrap justify-center'>
                      {tournament.teams?.map((x, idx) => (
                        <div
                          key={idx}
                          className={`cursor-pointer w-1/4 lg:w-1/6 ${
                            selectedTeam?.name === x.name
                              ? 'bg-gray-700'
                              : 'bg-gray-900'
                          }`}
                          onClick={() => {
                            setSelectedTeam(x);
                          }}
                        >
                          <div className='text-center border border-gray-700 rounded-md hover:bg-black'>
                            <img
                              src={x.logo || '/unknown-team.png'}
                              alt=''
                              className='h-16 mx-auto hover:scale-105'
                            />
                            <div className='text-2xl text-center'>{x.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {selectedTeam ? (
                      <div className='bg-gray-900 mx-24'>
                        <div className='flex justify-center items-center'>
                          <div className='bg-gray-700 [background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.yellow.500)_86%,_theme(colors.yellow.300)_90%,_theme(colors.yellow.500)_94%,_theme(colors.slate.600/.48))_border-box] rounded-full border border-transparent animate-border rounded-lg'>
                            <img
                              alt=''
                              src={selectedTeam.logo || '/unknown-team.png'}
                              className='h-48 mx-auto'
                            />
                          </div>
                          <div className='text-center mr-8'>
                            <div className='text-4xl text-yellow-500'>
                              {selectedTeam.name}
                            </div>
                            <div className='text-2xl'>
                              Owner: {selectedTeam.owner.name}
                            </div>
                            <div className='text-lg'>
                              Auction: Bought{' '}
                              {
                                tournament.players.filter(
                                  (x) =>
                                    x.status === 'sold' &&
                                    x.team === selectedTeam.name
                                ).length
                              }{' '}
                              players for{' '}
                              {formatCurrency(
                                getBudgetSummary(tournament, selectedTeam.name)
                                  .totalSpent
                              )}
                              {/* {formatCurrency(
                                tournament.players
                                  ?.filter(
                                    (x) =>
                                      x.status === 'sold' &&
                                      x.team === selectedTeam.name
                                  )
                                  ?.reduce((prev, curr) => {
                                    return prev + curr.soldAt;
                                  }, 0)
                              )} */}
                            </div>
                            <div className='text-lg animate-pulse text-red-500'>
                              Budget Locked:{' '}
                              {formatCurrency(
                                getBudgetSummary(tournament, selectedTeam.name)
                                  .locked
                              )}
                            </div>
                            <div className='text-lg animate-pulse text-orange-500'>
                              Budget Available for Next Bid:{' '}
                              {formatCurrency(
                                getBudgetSummary(tournament, selectedTeam.name)
                                  .availableNextBid
                              )}
                            </div>
                            <div className='text-lg animate-pulse text-green-500'>
                              Budget Remaining:{' '}
                              {formatCurrency(
                                getBudgetSummary(tournament, selectedTeam.name)
                                  .totalAvailable
                              )}
                            </div>
                          </div>
                          <div>
                            <img
                              alt=''
                              src={
                                selectedTeam.owner.photo ||
                                'https://www.iplt20.com/assets/images/default-headshot.png'
                              }
                              className='h-28 mx-auto rounded-full'
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {tab === 'players' ? (
                  <>
                    <div className='flex flex-wrap justify-center my-5'>
                      {playersFilter.map((x, idx) => (
                        <div
                          key={idx}
                          className='bg-gray-900 hover:bg-black w-1/4'
                          onClick={() => {
                            const players = x.filter();
                            setFilter(x.name);
                            setPlayerList({
                              batsmen: players.filter(
                                (y) => y.skills === 'batsman'
                              ),
                              bowlers: players.filter(
                                (y) => y.skills === 'bowler'
                              ),
                              allrounders: players.filter(
                                (y) => y.skills === 'allrounder'
                              ),
                              keepers: players.filter(
                                (y) => y.skills === 'keeper'
                              ),
                            });
                          }}
                        >
                          <div
                            className={`border border-gray-700 rounded-md m-2 text-center ${
                              x.name === filter
                                ? 'border border-yellow-500'
                                : ''
                            }`}
                          >
                            <div className='text-2xl text-center'>{x.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
                {tab === 'rules' ? <Rules tournament={tournament} /> : null}
              </div>
            </div>
          </div>
        </div>
        {playerList.batsmen.length ||
        playerList.bowlers.length ||
        playerList.keepers.length ||
        playerList.allrounders.length ? (
          <div className='min-h-96 m-2 p-10 text-black'>
            <div className='flex justify-center'>
              {[
                ...playerList.allrounders.filter((x) => x.status === 'pending'),
                ...playerList.batsmen.filter((x) => x.status === 'pending'),
                ...playerList.bowlers.filter((x) => x.status === 'pending'),
                ...playerList.keepers.filter((x) => x.status === 'pending'),
              ].length ? (
                <AdvancedLoadingButton
                  text='Sell Player'
                  behaviour='modal'
                  modal={{
                    title: 'Sell Player',
                    size: 'large',
                    content: ({ onClose }) => {
                      return (
                        <AuctionRoulette
                          onPlayerSelect={selectPlayer}
                          tournament={tournament}
                          onClose={onClose}
                          teams={tournament.teams}
                          players={[
                            ...playerList.allrounders.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.batsmen.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.bowlers.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.keepers.filter(
                              (x) => x.status === 'pending'
                            ),
                          ]}
                          // onSold={({ player, bid }) => {
                          //   const p = tournament.players.find((x) => {
                          //     return x.name === player.name;
                          //   });
                          //   const t = tournament.teams.find(
                          //     (x) => x.name === bid.topBidder
                          //   );

                          //   if (p && t) {
                          //     p.soldAt = bid.value;
                          //     p.status = 'sold';
                          //     p.team = bid.topBidder;
                          //     p.bidActivities = bid.activities;
                          //     // t.purse.spent = t.purse.spent + bid.value;
                          //     // t.purse.remaining = t.purse.remaining - bid.value;
                          //     downloadFile({
                          //       fileType: 'application/json',
                          //       fileName: `mepl-${p?.name}-${moment().format(
                          //         'MMDD-HHmm'
                          //       )}`,
                          //       result: JSON.stringify(tournament),
                          //     });
                          //     saveTournament({ ...tournament });
                          //   }
                          // }}
                          onUnsold={({ player }) => {
                            const p = tournament.players.find((x) => {
                              return x.name === player.name;
                            });

                            if (p) {
                              p.status = 'unsold';
                            }

                            saveTournament({ ...tournament });
                          }}
                        />
                      );
                    },
                  }}
                />
              ) : null}
            </div>
            <div className='flex'>
              {playerList.batsmen?.length ? (
                <div className='w-1/4'>
                  <div className='mx-2 bg-gray-100 '>
                    <div className='text-center text-4xl'>
                      Batsmen
                      <img
                        alt=''
                        src='https://www.iplt20.com/assets/images/teams-batsman-icon.svg'
                        // src='https://lh3.googleusercontent.com/d/1ddgvoCQ3BGN1zJS2heH_rHrAli7aiT7P'
                        className='inline bg-gray-200 p-1 rounded-full'
                      />
                    </div>
                    <div>
                      {playerList.batsmen.map((x) => (
                        <div className='bg-gray-800'>
                          <PlayerCard
                            selectPlayer={selectPlayer}
                            player={x}
                            teams={tournament.teams}
                            size='small'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {playerList.bowlers?.length ? (
                <div className='w-1/4'>
                  <div className='mx-2 bg-gray-100'>
                    <div className='text-center  text-4xl'>
                      Bowlers
                      <img
                        alt=''
                        src='https://www.iplt20.com/assets/images/teams-bowler-icon.svg'
                        className='inline bg-gray-200 p-1 rounded-full'
                      />
                    </div>
                    <div>
                      {playerList.bowlers.map((x) => (
                        <div className='bg-gray-800'>
                          <PlayerCard
                            selectPlayer={async (p) => {
                              console.log(p);
                              selectPlayer({ player: p.player });
                            }}
                            player={x}
                            teams={tournament.teams}
                            size='small'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {playerList.keepers?.length ? (
                <div className='w-1/4'>
                  <div className='mx-2 bg-gray-100'>
                    <div className='text-center  text-4xl'>
                      Wicket Keeper
                      <img
                        alt=''
                        src='https://www.iplt20.com/assets/images/teams-wicket-keeper-icon.svg'
                        className='inline bg-gray-200 p-1 rounded-full'
                      />
                    </div>
                    <div>
                      {playerList.keepers.map((x) => (
                        <div className='bg-gray-800'>
                          <PlayerCard
                            selectPlayer={async (p) => {
                              console.log(p);
                              selectPlayer({ player: p.player });
                            }}
                            player={x}
                            teams={tournament.teams}
                            size='small'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className='w-full'>
                <div className='mx-2 bg-gray-800'>
                  <div className='text-center text-white text-4xl'>
                    Allrounder
                    <img
                      alt=''
                      src='https://www.iplt20.com/assets/images/teams-all-rounder-icon.svg'
                      className='inline bg-gray-200 p-1 rounded-full'
                    />
                  </div>
                  <div className='flex flex-wrap'>
                    {playerList.allrounders.map((x) => (
                      <div className='bg-gray-800 w-1/4'>
                        <PlayerCard
                          selectPlayer={async (p) => {
                            console.log(p);
                            selectPlayer({ player: p.player });
                          }}
                          player={x}
                          teams={tournament.teams}
                          size='small'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isVisible={modalState.isVisible}
              size={modalState.size}
              title={modalState.title}
              body={modalState.body}
              onClose={modalState.onClose}
            />
          </div>
        ) : null}
      </div>
    );
  };
}

// async function getPlayers(): Promise<Player[]> {
//   const players: Player[] = await axios.get('/players.json').then((res) => {
//     console.log(res);
//     return res.data.map((x) => {
//       return {
//         name: `${x['First Name']} ${x['Last Name']}`,
//         photo: '',
//         category: x.Category,
//         description: '',
//         skills: getSkills(x),
//         status: 'pending',
//         team: '',
//         basePrice: getBasePrice(x),
//         soldAt: 0,
//         bidActivities: [],
//       };
//     });
//   });

//   return players;
// }

// function getSkills(x): PlayerSkills {
//   const role = x['What is your primary role in the team?'];
//   if (role === 'Batsman') {
//     return 'batsman';
//   }

//   if (role === 'All-rounder') {
//     return 'allrounder';
//   }

//   return 'bowler';
// }

// function getBasePrice(x) {
//   const categoryWise = [
//     {
//       name: 'A+',
//       maximumPlayers: 1,
//       maximumBudget: 2500000,
//       baseValue: 1000000,
//     },
//     {
//       name: 'A',
//       maximumPlayers: 1,
//       maximumBudget: 1700000,
//       baseValue: 700000,
//     },
//     {
//       name: 'B',
//       maximumPlayers: 2,
//       maximumBudget: 0,
//       baseValue: 500000,
//     },
//     {
//       name: 'C',
//       maximumPlayers: 5,
//       maximumBudget: 0,
//       baseValue: 200000,
//     },
//   ];
//   const cat = categoryWise.find((y) => x.Category === y.name);
//   if (cat) {
//     return cat.baseValue;
//   }

//   return 0;
// }
