import { Recat } from '@erp_core/erp-icons/icons/recat';
import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  Congratulations,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseCombinedGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseCombinedOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import { UseCombinedOffSiteWorkHour } from '../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import { AttendanceInterface } from '../../../../models/interfaces/hrd/attendance';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../../models/interfaces/planning/log-record';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderActions } from '../day-book/components/table/table-actions';
import { renderAttendanceBody } from '../day-book/components/table/table-body';
import { Header } from '../day-book/components/table/table-header';
import { saveNewOvertime } from '../util/add-overtime';

export function renderEmployeePendingActionsPage({
  attendanceService,
  leaveService,
  useCombinedEmployeeProfile,
  useCombinedGatePass,
  useCombinedOffSiteWorkHour,
  useCombinedOvertime,
  useCombinedAttendance,
  useCurrentUserRoles,
  userRendererService,
  logRecordService,
  useCombinedLeave,
}: {
  attendanceService: AttendanceInterface;
  leaveService: LeaveInterface;
  useCombinedLeave: UseCombinedLeave;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGatePass: UseCombinedGatePass;
  useCombinedOffSiteWorkHour: UseCombinedOffSiteWorkHour;
  useCombinedOvertime: UseCombinedOvertime;
  useCombinedAttendance: UseCombinedAttendance;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
  logRecordService: LogRecordInterface;
}): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();

  return function EmployeePendingActionsPage(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const [pendingActionsData, setPendingActionsData] = useState<
      CombinedAttDataType[]
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<{
      mode: 'date' | 'month';
      value: string;
    }>(
      JSON.parse(
        localStorage.getItem('employee-pending-action-month') ||
          JSON.stringify({
            mode: 'month',
            value: moment.utc().format('YYYY-MM'),
          })
      )
    );
    const { getSync: getEmployeeSync } = useCombinedEmployeeProfile();
    const { syncSet: setGatePass } = useCombinedGatePass();
    const { syncSet: setOffsite } = useCombinedOffSiteWorkHour();
    const { getListSync: getLeavesSync } = useCombinedLeave();
    const { syncSet: setLeave } = useCombinedLeave();
    const { getListSync: getAllAttendanceSync } = useCombinedAttendance();
    const { syncSet: setAttendance } = useCombinedAttendance();
    const { syncSet: setOvertime } = useCombinedOvertime();
    const {
      company: currentCompany,
      cgSetting: companyGroupSetting,
    } = useContext(CurrentContext);
    const { data: currentUserRoles } = useCurrentUserRoles();

    const [isHr, setIsHr] = useState<boolean>(false);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }

      setIsSystemAdmin(
        currentUserRoles?.find((x) => x.name === 'System-Admin') ? true : false
      );
    }, [currentUserRoles]);

    async function empPendingActnsData({
      month,
      userId,
      toRefresh,
    }: {
      month: string;
      userId: string;
      toRefresh?: string;
    }) {
      if (pendingActionsData.length === 0) {
        setLoading(true);
      }
      const empActnsData = await attendanceService.getEmployeePendingActions({
        month,
        employee: userId, // '29DEA8FE-54D8-4753-9CF1-FECBECCCB67B', // userId, // '468D91E4-083C-4C34-B0BD-F7BA4DF48219'
        toRefresh,
      });
      setPendingActionsData(empActnsData);
      setLoading(false);
    }

    useEffect(() => {
      if (date.value && currentUser.id) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.id, date]);

    async function fetchData() {
      empPendingActnsData({
        month: date.value,
        userId: currentUser.id,
      });
    }

    const AttBody = renderAttendanceBody({
      isDaybook: false,
      userRendererService,
      currentUser,
      getAllAttendanceSync,
      setAttendance,
      fetchData,
    });

    const tableBody: TableBody = AttBody({
      type: 'pending-actions',
      list: pendingActionsData?.map((x) => {
        return {
          attendance: x,
        };
      }),
    });

    const Actions = renderActions({
      isHr,
      setAttendance,
      leaveService,
      currentUser,
      useCombinedEmployeeProfile,
      useCombinedLeave,
      getEmployeeSync,
      setGatePass,
      setOffsite,
      useCombinedOvertime,
      saveNewOvertime,
      setOvertime,
      currentCompany,
      companyGroupSetting,
      fetchData,
      isSystemAdmin,
      attendanceService,
      logRecordService,
      getLeavesSync,
      setLeave,
    });

    const actions = Actions();

    function renderBody() {
      if (loading) {
        return (
          <div className='text-center'>
            <Recat className='h-5 inline animate-pulse mx-4' />
          </div>
        );
      }

      if (pendingActionsData.length) {
        return (
          <Table
            isDataLoading={loading}
            header={Header({ type: 'pending-action' })}
            body={tableBody}
            auth={currentUser.authorization}
            actions={actions}
          />
        );
      }

      return (
        <div>
          <Congratulations message='Seems like you have completed all your computed pending actions! Great Job! Come again after sometime for more tasks..' />
        </div>
      );
    }

    return (
      <>
        <Card
          header={{
            title: `${currentUser?.name || 'Employee'} Pending Actions`,
            subheading: (
              <div>
                <span className='text-blue-700 font-bold'>
                  <span className='capitalize text-black'>{date.mode}: </span>{' '}
                  {moment(
                    date.value,
                    date.mode === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD'
                  ).format(
                    date.mode === 'date' ? 'ddd MMM DD YYYY' : 'MMM, YYYY'
                  )}
                </span>
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>
                      Select {date.mode}
                    </span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={date.value}
                      onChange={(m) =>
                        setDate({
                          mode: date.mode,
                          value: m,
                        })
                      }
                    />
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: renderBody(),
          }}
        />
      </>
    );
  };
}
