import { Approval } from '@erp_core/erp-icons/icons/approval';
import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  ArrowRightStartOnRectangleIcon,
  BoltIcon,
  CalculatorIcon,
  CheckBadgeIcon,
  ClockIcon,
  CurrencyRupeeIcon,
  TicketIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

export type renderSummaryProps = {
  d: CombinedAttDataType;
};

export function renderSummary(): (props: renderSummaryProps) => JSX.Element {
  return function Summary({ d }: renderSummaryProps): JSX.Element {
    return (
      <>
        <div>
          {d.summary?.map((x, idx) => (
            <div key={idx}>
              <div className='font-semibold'>
                ‣ <SummaryIcon summary={x} /> {x.topic}
              </div>
              {x.description ? (
                <div className='italic'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{x.description}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </>
    );
  };
}

function SummaryIcon({
  summary,
}: {
  summary: {
    topic: string;
    description?: string;
    icon: string;
    action?: string;
    iconColor: string;
    authorization?: {
      show: boolean;
      role: string;
      action: string;
    };
  };
}) {
  if (summary.topic === 'Supervisor Verification Pending') {
    return (
      <UserCircleIcon className='text-red-500 w-5 h-5 inline animate-pulse' />
    );
  }

  if (summary.topic.startsWith('Late mark: rejected by')) {
    return <CheckBadgeIcon className='text-green-500 w-4 h-4 inline' />;
  }

  if (summary.topic.endsWith(' arrived late for the shift')) {
    return <ClockIcon className='text-red-500 w-4 h-4 inline animate-pulse' />;
  }

  if (summary.topic.startsWith('Verified by ')) {
    return (
      <Approval className='inline w-4 h-4 text-green-500 stroke-green-500 stroke-0' />
    );
  }

  if (summary.topic.startsWith('Total Work Hours:')) {
    return <CalculatorIcon className='inline w-4 h-4' />;
  }

  if (summary.topic.startsWith('Payable Hours:')) {
    return <CurrencyRupeeIcon className='inline w-4 h-4 text-green-500' />;
  }

  if (summary.topic.startsWith('Finalized By')) {
    return <BoltIcon className='inline w-4 h-4 text-amber-500' />;
  }

  if (summary.topic.startsWith('Total Out Hours')) {
    return (
      <ArrowRightStartOnRectangleIcon className='inline w-4 h-4 text-red-500' />
    );
  }

  if (summary.topic.includes('Gatepass')) {
    return <TicketIcon className='inline w-4 h-4' />;
  }

  return <></>;
}
