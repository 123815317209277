import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  propType?: string;
  currentValue: Array<string>;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

export type EditTagsChangeReqFormType = {
  currentValue: Array<string>;
  newValue: Array<string>;
  reason: string;
};

export const renderTagsEditChangeRequest = () => {
  return function TagsEditChangeRequest({
    propType,
    currentValue,
    onSave,
  }: EditChangeReqProps) {
    const formProps: FormProps<EditTagsChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'tags',
          label: 'Current Value',
          readonly: true,
        },

        {
          property: 'newValue',
          type: 'tags',
          required: true,
          label: 'New Value',
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue || [],
        newValue: '',
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason,
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditTagsChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditTagsChangeReqFormType>
    ): EditTagsChangeReqFormType {
      return {
        currentValue: b.currentValue || currentValue,
        newValue: b.newValue,
        reason: b.reason,
      } as EditTagsChangeReqFormType;
    }
  };
};
