import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { renderSearchBox } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';

export function SearchSelectRenderer({
  currentValue,
  searchSelectOptions,
  onChange,
  useCombinedMetric,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedGST,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  currentValue: any;
  searchSelectOptions:
    | 'useMetrics'
    | 'useGST'
    | 'useAppearances'
    | 'useOdours'
    | 'useColors'
    | 'useEmployees'
    | 'useGrades'
    | 'useHazardClass';

  onChange: (p: { id: string; name: string }) => void;
  useCombinedMetric: UseCombinedMetric;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedColor: UseCombinedColor;
  useCombinedOdour: UseCombinedOdour;
  useCombinedGST: UseCombinedGST;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}): JSX.Element {
  const useSearchResource = (useResources: string) => {
    switch (useResources) {
      case 'useMetrics':
        return {
          resource: useCombinedMetric,
          body: (data) => {
            return (
              <div>
                <div className='font-bold'>{data.name}</div>
                <div>{data.symbol}</div>
              </div>
            );
          },
        };
      case 'useGST':
        return {
          resource: useCombinedGST,
          body: undefined,
        };
      case 'useAppearances':
        return {
          resource: useCombinedAppearance,
          body: undefined,
        };
      case 'useColors':
        return {
          resource: useCombinedColor,
          body: undefined,
        };
      case 'useOdours':
        return {
          resource: useCombinedOdour,
          body: undefined,
        };
      case 'useEmployees':
        return {
          resource: useCombinedEmployeeProfile,
          body: undefined,
        };
      case 'useGrade':
        return {
          resource: useCombinedGrade,
          body: undefined,
        };
      case 'useHazardClass':
        return {
          resource: useCombinedHazardGhsClass,
          body: (data) => {
            return (
              <div>
                <div className='font-bold'>{data.name}</div>
                <div>
                  {data.chapterNo} {data.categoryName}
                </div>
              </div>
            );
          },
        };
      default:
        return null;
    }
  };

  const onValueSelected = (x) => {
    if (searchSelectOptions === 'useMetrics') {
      return onChange({
        id: x.id,
        name: x.icon,
      });
    }

    return onChange({ id: x.id, name: x.name });
  };

  const SearchableSelect = renderSearchBox<any>({
    useSearchValues: useSearchResource(searchSelectOptions)?.resource as any,
    searchOptionsBody: {
      customBody: useSearchResource(searchSelectOptions)?.body,
    },
    onSearchValueSelect: (x) => {
      if (x.id !== currentValue?.id) {
        onValueSelected(x.id ? x : { id: '', name: '' });
      }
      // setSelectedDesignations([...selectedDesignations, { ...x }]);
    },
  });

  return (
    <>
      <SearchableSelect
        currentSelected={
          currentValue && currentValue?.id
            ? {
                id: currentValue.id,
                name: currentValue.value || currentValue.name,
              }
            : null
        }
        searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm'
      />
    </>
  );
}

const mapper = {
  number: 'number',
  date: 'date',
  input: 'input',
  formula: 'input',
};

export function GetPropertyEditor({
  itemProperty,
  setNewValues,
  keyProp,
  val,
  useCombinedGST,
  useCombinedMetric,
  useCombinedAppearance,
  useCombinedOdour,
  useCombinedColor,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  setNewValues: React.Dispatch<React.SetStateAction<any[]>>;
  itemProperty: ItemProperty;
  keyProp: string;
  val: any;
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}): JSX.Element {
  if (
    itemProperty.childrenListProperties &&
    itemProperty.childrenListProperties[keyProp] &&
    itemProperty.childrenListProperties[keyProp].name &&
    ['input', 'number', 'date', 'formula'].includes(
      itemProperty.childrenListProperties[keyProp].type
    )
  ) {
    return (
      <input
        type={
          mapper[
            itemProperty.childrenListProperties[keyProp].type || 'input'
          ] || 'input'
        }
        step={
          itemProperty.childrenListProperties[keyProp].type === 'number'
            ? 'any'
            : undefined
        }
        placeholder={
          itemProperty.childrenListProperties[keyProp].placeholder || ''
        }
        className='border-gray-100 p-1'
        value={val[itemProperty.childrenListProperties[keyProp].name] || ''}
        onChange={(evt) => {
          if (evt.target.value && itemProperty.childrenListProperties) {
            const finalval =
              itemProperty.childrenListProperties[keyProp].type === 'date'
                ? moment(evt.target.value).format('YYYY-MM-DD')
                : evt.target.value;
            if (itemProperty.childrenListProperties) {
              val[itemProperty.childrenListProperties[keyProp].name] = finalval;
              setNewValues((v) => [...v]);
            }
          }
        }}
      />
    );
  }

  if (
    itemProperty.childrenListProperties &&
    itemProperty.childrenListProperties[keyProp] &&
    itemProperty.childrenListProperties[keyProp].name &&
    itemProperty.childrenListProperties[keyProp].type === 'select'
  ) {
    return (
      <select
        className='border-gray-100 p-1'
        value={val[itemProperty.childrenListProperties[keyProp].name] || ''}
        onChange={(evt) => {
          if (
            evt.target.value &&
            itemProperty.childrenListProperties &&
            itemProperty.childrenListProperties[keyProp]
          ) {
            val[itemProperty.childrenListProperties[keyProp].name] =
              evt.target.value;
            setNewValues((v) => [...v]);
          }
        }}
      >
        <option value=''>Select</option>
        {itemProperty.childrenListProperties[keyProp]?.selectOptions?.map(
          (op) => {
            return (
              <option key={op} value={op}>
                {op}
              </option>
            );
          }
        )}
      </select>
    );
  }

  if (
    itemProperty.childrenListProperties &&
    itemProperty.childrenListProperties[keyProp] &&
    itemProperty.childrenListProperties[keyProp].name &&
    ['searchable-select'].includes(
      itemProperty.childrenListProperties[keyProp].type
    )
  ) {
    return (
      <SearchSelectRenderer
        useCombinedGST={useCombinedGST}
        useCombinedGrade={useCombinedGrade}
        useCombinedHazardGhsClass={useCombinedHazardGhsClass}
        useCombinedMetric={useCombinedMetric}
        useCombinedAppearance={useCombinedAppearance}
        useCombinedColor={useCombinedColor}
        useCombinedOdour={useCombinedOdour}
        useCombinedEmployeeProfile={useCombinedEmployeeProfile}
        currentValue={val[itemProperty.childrenListProperties[keyProp].name]}
        searchSelectOptions={
          itemProperty.childrenListProperties[keyProp].searchSelectOptions
        }
        onChange={(newValue) => {
          if (
            itemProperty.childrenListProperties &&
            newValue.id !==
              val[itemProperty.childrenListProperties[keyProp].name]?.id
          ) {
            val[itemProperty.childrenListProperties[keyProp].name] = {
              id: newValue.id,
              name: newValue.name,
            };
            setNewValues((v) => [...v]);
          }
        }}
      />
    );
  }

  if (
    itemProperty.childrenListProperties &&
    itemProperty.childrenListProperties[keyProp] &&
    itemProperty.childrenListProperties[keyProp].name &&
    ['object'].includes(itemProperty.childrenListProperties[keyProp].type)
  ) {
    return (
      <>
        {itemProperty.childrenListProperties &&
          _.keys(
            itemProperty.childrenListProperties[keyProp].childrenProperties
          ).map((childKey) => {
            return (
              <div key={childKey} className='my-1 border border-gray-200'>
                <div className='text-center font-bold'>{childKey}</div>
                <div>
                  {itemProperty.childrenListProperties &&
                  itemProperty.childrenListProperties[keyProp]
                    .childrenProperties[childKey].type === 'input' ? (
                    <input
                      placeholder={
                        itemProperty.childrenListProperties[keyProp]
                          .childrenProperties[childKey].placeholder || ''
                      }
                      className='border-gray-100 p-1'
                      value={
                        (itemProperty.childrenListProperties &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ] &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ][childKey]) ||
                        ''
                      }
                      onChange={(evt) => {
                        if (evt.target.value) {
                          if (itemProperty.childrenListProperties) {
                            if (
                              !val[
                                itemProperty.childrenListProperties[keyProp]
                                  .name
                              ]
                            ) {
                              val[
                                itemProperty.childrenListProperties[
                                  keyProp
                                ].name
                              ] = {};
                            }
                            val[
                              itemProperty.childrenListProperties[keyProp].name
                            ][childKey] = evt.target.value;
                            setNewValues((v) => [...v]);
                          }
                        }
                      }}
                    />
                  ) : null}

                  {itemProperty.childrenListProperties &&
                  itemProperty.childrenListProperties[keyProp]
                    .childrenProperties[childKey].type === 'select' ? (
                    <select
                      className='border-gray-100 p-1'
                      value={
                        (itemProperty.childrenListProperties &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ] &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ][childKey]) ||
                        ''
                      }
                      onChange={(evt) => {
                        if (evt.target.value) {
                          if (itemProperty.childrenListProperties) {
                            if (
                              !val[
                                itemProperty.childrenListProperties[keyProp]
                                  .name
                              ]
                            ) {
                              val[
                                itemProperty.childrenListProperties[
                                  keyProp
                                ].name
                              ] = {};
                            }
                            val[
                              itemProperty.childrenListProperties[keyProp].name
                            ][childKey] = evt.target.value;
                            setNewValues((v) => [...v]);
                          }
                        }
                      }}
                    >
                      <option value={''}>Select</option>
                      {itemProperty.childrenListProperties &&
                        itemProperty.childrenListProperties[
                          keyProp
                        ].childrenProperties[childKey].selectOptions.map(
                          (so) => (
                            <option key={so} value={so}>
                              {so}
                            </option>
                          )
                        )}
                    </select>
                  ) : null}

                  {itemProperty.childrenListProperties &&
                  itemProperty.childrenListProperties[keyProp]
                    .childrenProperties[childKey].type ===
                    'searchable-select' ? (
                    <SearchSelectRenderer
                      useCombinedGST={useCombinedGST}
                      useCombinedMetric={useCombinedMetric}
                      useCombinedGrade={useCombinedGrade}
                      useCombinedHazardGhsClass={useCombinedHazardGhsClass}
                      useCombinedAppearance={useCombinedAppearance}
                      useCombinedColor={useCombinedColor}
                      useCombinedOdour={useCombinedOdour}
                      useCombinedEmployeeProfile={useCombinedEmployeeProfile}
                      currentValue={
                        (itemProperty.childrenListProperties &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ] &&
                          val[
                            itemProperty.childrenListProperties[keyProp].name
                          ][childKey]) ||
                        ''
                      }
                      searchSelectOptions={
                        itemProperty.childrenListProperties &&
                        itemProperty.childrenListProperties[keyProp]
                          .childrenProperties[childKey].searchSelectOptions
                      }
                      onChange={(newValue) => {
                        if (itemProperty.childrenListProperties) {
                          if (
                            !val[
                              itemProperty.childrenListProperties[keyProp].name
                            ]
                          ) {
                            val[
                              itemProperty.childrenListProperties[keyProp].name
                            ] = {};
                          }

                          if (
                            itemProperty.childrenListProperties &&
                            newValue.id !==
                              val[
                                itemProperty.childrenListProperties[keyProp]
                                  .name
                              ][childKey]?.id
                          ) {
                            val[
                              itemProperty.childrenListProperties[keyProp].name
                            ][childKey] = {
                              id: newValue.id,
                              name: newValue.name,
                            };
                            setNewValues((v) => [...v]);
                          }
                        }
                      }}
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
      </>
    );
  }

  return (
    <>
      {itemProperty.childrenListProperties &&
        itemProperty.childrenListProperties[keyProp] &&
        itemProperty.childrenListProperties[keyProp].type}
    </>
  );
}

export function GetComplexPropertyEditor({
  entityId,
  itemProperty,
  setNewValues,
  keyProp,
  val,
  useCombinedGST,
  useCombinedMetric,
  useCombinedAppearance,
  useCombinedOdour,
  useCombinedColor,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  entityId: string;
  setNewValues: any;
  itemProperty: ItemProperty;
  keyProp: string;
  val: any;
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}): JSX.Element {
  if (
    itemProperty.type &&
    ['input', 'number', 'formula'].includes(itemProperty.type)
  ) {
    if (
      itemProperty.valueType === 'fixed' &&
      entityId !== itemProperty.owner?.id
    ) {
      return val;
    }
    return (
      <>
        <input
          type={itemProperty.type === 'number' ? 'number' : 'text'}
          step={itemProperty.type === 'number' ? 'any' : undefined}
          className='border-gray-100 p-1'
          placeholder={itemProperty.placeholder || ''}
          defaultValue={val}
          onChange={(evt) => {
            setNewValues(evt.target.value);
          }}
        />
      </>
    );
  }

  if (itemProperty.type && itemProperty.type === 'select') {
    if (
      itemProperty.valueType === 'fixed' &&
      entityId !== itemProperty.owner?.id
    ) {
      return val;
    }
    return (
      <select
        className='border-gray-100 p-1'
        value={val}
        onChange={(evt) => {
          setNewValues(evt.target.value);
        }}
      >
        <option value=''>Select</option>
        {itemProperty.selectOptions?.map((op) => {
          return (
            <option key={op} value={op}>
              {op}
            </option>
          );
        })}
      </select>
    );
  }

  if (itemProperty.type === 'value-uom') {
    return (
      <div className='flex'>
        <input
          className='border-gray-100 p-1'
          type='number'
          step='any'
          defaultValue={val?.value}
          onBlur={(evt) => {
            setNewValues({
              value: evt.target.value,
              uom: val.uom,
            });
          }}
        />
        {itemProperty.valueType === 'fixed-uom' &&
        entityId !== itemProperty.owner?.id ? (
          <>{val?.uom?.name}</>
        ) : (
          <SearchSelectRenderer
            useCombinedGrade={useCombinedGrade}
            useCombinedHazardGhsClass={useCombinedHazardGhsClass}
            useCombinedGST={useCombinedGST}
            useCombinedMetric={useCombinedMetric}
            useCombinedAppearance={useCombinedAppearance}
            useCombinedColor={useCombinedColor}
            useCombinedOdour={useCombinedOdour}
            useCombinedEmployeeProfile={useCombinedEmployeeProfile}
            currentValue={val?.uom || ''}
            searchSelectOptions='useMetrics'
            onChange={(newValue) => {
              setNewValues({
                value: val.value,
                uom: {
                  id: newValue.id,
                  name: newValue.name || (newValue as any).value,
                },
              });
            }}
          />
        )}
      </div>
    );
  }

  if (itemProperty.type === 'exp-value-uom') {
    return (
      <div className='flex'>
        <select
          value={val?.exp}
          onChange={(evt) => {
            setNewValues({
              exp: evt.target.value,
              value: val.value,
              uom: val.uom,
            });
          }}
        >
          <option value=''>Select</option>
          <option value='>'>{'>'}</option>
          <option value='>='>{'>='}</option>
          <option value='<'>{'<'}</option>
          <option value='<='>{'<='}</option>
          <option value='='>{'='}</option>
          <option value='!='>{'!='}</option>
          <option value='><'>{'><'}</option>
        </select>
        <input
          className='border-gray-100 p-1'
          step='any'
          defaultValue={val?.value}
          onBlur={(evt) => {
            const newValue = evt.target.value;

            if (newValue) {
              if (newValue === 'NA') {
                setNewValues({
                  exp: val.exp,
                  value: newValue,
                  uom: val.uom,
                });
              } else {
                const pattern = new RegExp('^[-+]?[0-9]+(.[0-9]+)?$');
                const inBetweenPattern = new RegExp(
                  'Between (d|.)+ and (d|.)+'
                );
                const test =
                  val.exp !== '><'
                    ? pattern.test(newValue)
                    : inBetweenPattern.test(newValue as string);
                if (!test) {
                  if (val.exp !== '><') {
                    setNewValues({
                      exp: val.exp,
                      value: 'Invalid Value: Enter Number or NA',
                      uom: val.uom,
                    });
                  } else {
                    setNewValues({
                      exp: val.exp,
                      value:
                        'Value pattern fails: "Between xxx and xxx" where xxx are min-max range',
                      uom: val.uom,
                    });
                  }
                } else {
                  setNewValues({
                    exp: val.exp,
                    value: newValue,
                    uom: val.uom,
                  });
                }
              }
            }
          }}
        />
        {itemProperty.valueType === 'fixed-uom' &&
        entityId !== itemProperty.owner?.id ? (
          <>{val?.uom?.name}</>
        ) : (
          <SearchSelectRenderer
            useCombinedGrade={useCombinedGrade}
            useCombinedHazardGhsClass={useCombinedHazardGhsClass}
            useCombinedGST={useCombinedGST}
            useCombinedMetric={useCombinedMetric}
            useCombinedAppearance={useCombinedAppearance}
            useCombinedColor={useCombinedColor}
            useCombinedOdour={useCombinedOdour}
            useCombinedEmployeeProfile={useCombinedEmployeeProfile}
            currentValue={val?.uom || ''}
            searchSelectOptions='useMetrics'
            onChange={(newValue) => {
              setNewValues({
                exp: val.exp,
                value: val.value,
                uom: {
                  id: newValue.id,
                  name: newValue.name || (newValue as any).value,
                },
              });
            }}
          />
        )}
      </div>
    );
  }

  if (['searchable-select'].includes(itemProperty.type)) {
    if (
      itemProperty.valueType === 'fixed' &&
      entityId !== itemProperty.owner?.id
    ) {
      return val?.name || val?.value;
    }
    return (
      <SearchSelectRenderer
        useCombinedGrade={useCombinedGrade}
        useCombinedHazardGhsClass={useCombinedHazardGhsClass}
        useCombinedGST={useCombinedGST}
        useCombinedMetric={useCombinedMetric}
        useCombinedAppearance={useCombinedAppearance}
        useCombinedColor={useCombinedColor}
        useCombinedOdour={useCombinedOdour}
        useCombinedEmployeeProfile={useCombinedEmployeeProfile}
        currentValue={val}
        searchSelectOptions={itemProperty.searchSelectOptions as any}
        onChange={(newValue) => {
          setNewValues(newValue);
        }}
      />
    );
  }

  if (['object'].includes(itemProperty.type)) {
    return (
      <>
        {_.keys(itemProperty.childrenProperties).map((childKey) => {
          return (
            <div key={childKey} className='my-1 border border-gray-200'>
              <div className='text-center font-bold'>{childKey}</div>
              <div>
                {itemProperty.childrenProperties &&
                ['input', 'number', 'formula'].includes(
                  itemProperty.childrenProperties[childKey].type
                ) ? (
                  <input
                    type={
                      itemProperty.childrenProperties[childKey].type ===
                      'number'
                        ? 'number'
                        : 'text'
                    }
                    step={
                      itemProperty.childrenProperties[childKey].type ===
                      'number'
                        ? 'any'
                        : undefined
                    }
                    placeholder={
                      itemProperty.childrenProperties[childKey].placeholder ||
                      ''
                    }
                    className='border-gray-100 p-1'
                    value={val[childKey] || ''}
                    onChange={(evt) => {
                      if (evt.target.value) {
                        const newVal = JSON.parse(
                          JSON.stringify(typeof val === 'object' ? val : {})
                        );
                        if (
                          itemProperty.childrenProperties &&
                          itemProperty.childrenProperties[childKey].type ===
                            'number'
                        ) {
                          newVal[childKey] = parseFloat(evt.target.value);
                        } else {
                          newVal[childKey] = evt.target.value;
                        }
                        setNewValues(newVal);
                      }
                    }}
                  />
                ) : null}

                {itemProperty.childrenProperties &&
                itemProperty.childrenProperties[childKey].type === 'select' ? (
                  <select
                    className='border-gray-100 p-1'
                    value={val[childKey]}
                    onChange={(evt) => {
                      if (evt.target.value) {
                        const newVal = JSON.parse(
                          JSON.stringify(typeof val === 'object' ? val : {})
                        );
                        newVal[childKey] = evt.target.value;
                        setNewValues(newVal);
                      }
                    }}
                  >
                    <option value={''}>Select</option>
                    {itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[childKey] &&
                      itemProperty.childrenProperties[
                        childKey
                      ].selectOptions?.map((so) => (
                        <option key={so} value={so}>
                          {so}
                        </option>
                      ))}
                  </select>
                ) : null}

                {itemProperty.childrenProperties &&
                itemProperty.childrenProperties[childKey] &&
                itemProperty.childrenProperties[childKey].type ===
                  'searchable-select' ? (
                  <SearchSelectRenderer
                    useCombinedGrade={useCombinedGrade}
                    useCombinedHazardGhsClass={useCombinedHazardGhsClass}
                    useCombinedGST={useCombinedGST}
                    useCombinedMetric={useCombinedMetric}
                    useCombinedAppearance={useCombinedAppearance}
                    useCombinedColor={useCombinedColor}
                    useCombinedOdour={useCombinedOdour}
                    useCombinedEmployeeProfile={useCombinedEmployeeProfile}
                    currentValue={val[childKey]}
                    searchSelectOptions={
                      itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[childKey] &&
                      (itemProperty.childrenProperties[childKey]
                        .searchSelectOptions as any)
                    }
                    onChange={(newValue) => {
                      const newVal = JSON.parse(
                        JSON.stringify(typeof val === 'object' ? val : {})
                      );
                      if (newValue.id !== newVal[childKey]?.id) {
                        newVal[childKey] = {
                          id: newValue.id,
                          value: newValue.name,
                        };
                        setNewValues(newVal);
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return <>{itemProperty.type}</>;
}
