import { renderCardComponent } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseCombinedQuestionnaireQuestion } from '../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedQuestionnaireSurveyAnswer } from '../../../../hooks/hrd/questionnaire/questionnaire-survey-ans/use-questionnaire-survey-answer';
import { renderSurveyAnswerForm } from '../survey-answers/form';

export function renderSubmittedAnswers({
  useCombinedQuestionnaireQuestion,
  useCombinedQuestionnaireSurveyAnswer,
}: {
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedQuestionnaireSurveyAnswer: UseCombinedQuestionnaireSurveyAnswer;
}) {
  const Card = renderCardComponent();
  return function SubmittedAnswers() {
    const { id, ans } = useParams();
    const {
      resource: answer,
      get: getAnswer,
    } = useCombinedQuestionnaireSurveyAnswer();

    useEffect(() => {
      if (id && ans) {
        getAnswer(ans);
      }
      // eslint-disable-next-line
    }, []);

    const SurveyAnswersForm = renderSurveyAnswerForm({
      useCombinedQuestionnaireQuestion,
    });

    return (
      <div>
        {answer ? (
          <Card
            header={{
              title: (
                <div>
                  Submitted Answers for {answer.topic?.name} on{' '}
                  {moment(answer.createdAt).format('MMM DD')}
                </div>
              ),
            }}
            body={{
              type: 'jsx-component',
              body: (
                <div>
                  {id ? (
                    <SurveyAnswersForm
                      answers={answer.details.surveyAns}
                      id={id}
                      onSave={async () => {}}
                      date={answer.details.date}
                      mode='readonly'
                    />
                  ) : null}
                </div>
              ),
            }}
          />
        ) : null}
      </div>
    );
  };
}
