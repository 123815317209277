import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { formatCurrency, Player, PlayerSkills, Team } from './data';

export function PlayerCard({
  player,
  teams,
  size,
  selectPlayer,
}: {
  player: Player;
  teams: Array<Team>;
  size: 'small' | 'large';
  selectPlayer: ({ player }: { player: Player }) => Promise<void>;
}) {
  const team = teams.find((x) => x.name === player.team)?.logo;
  return (
    <div
      className={`cursor-pointer ${
        size === 'large' ? 'animate-pulse' : ''
      } flex justify-center items-center bg-gray-900 mx-2 my-1 text-white hover:scale-105 hover:bg-gray-800 border border-gray-700 rounded-lg`}
    >
      <div>
        <img
          alt=''
          src={
            player.photo ||
            'https://lh3.googleusercontent.com/d/1ipgdvh0c3VOyF3FIOCybBptEzdk6C1iP'
            // 'https://www.iplt20.com/assets/images/default-headshot.png'
          }
          className={`${
            size === 'small' ? 'h-28 rounded-full w-28' : 'h-72'
          }  mx-auto`}
        />
      </div>
      <div className='text-center mr-8'>
        <div
          className={`${
            size === 'small' ? 'text-2xl' : 'text-6xl'
          } text-yellow-500`}
        >
          #{player.serial} {player.name}
          {player.status === 'sold' ? (
            <img
              alt=''
              className='h-10 inline'
              src='https://cdn-icons-png.flaticon.com/128/6188/6188755.png'
            />
          ) : null}
        </div>
        {size === 'large' ? (
          <div className={`${size === 'large' ? 'text-3xl' : 'text-3xl'}`}>
            {player.description}
          </div>
        ) : null}
        {size === 'large' ? (
          <div className={`${size === 'large' ? 'text-3xl' : 'text-xl'}`}>
            <span className='text-yellow-500'>Age:</span>
            {player.age}&nbsp;
            <span className='text-yellow-500'>Skills:</span> {player.skills}{' '}
            <Skills skill={player.skills} />
          </div>
        ) : null}
        {size === 'large' ? (
          <>
            <div className='text-3xl'>
              <span className='text-yellow-500'>Batting:</span> {player.batting}{' '}
              <span className='text-yellow-500'>Bowling:</span> {player.bowling}
            </div>
            <div className='text-3xl'>
              <span className='text-yellow-500'>Flat:</span> {player.flatNo}{' '}
              <span className='text-yellow-500'>Wing:</span>
              {player.wing}
            </div>
          </>
        ) : null}
        {size === 'small' ? (
          <div className={`${size === 'small' ? 'text-xl' : 'text-5xl'}`}>
            <span className='text-yellow-500'>Status:</span> {player.status}{' '}
            {player.soldAt ? `${formatCurrency(player.soldAt)}` : ''}
            {player.status === 'pending' || player.status === 'unsold'
              ? LoadingButton({
                  defaultStyle: 'bg-none border border-yellow-500',
                  text: 'Sell',
                  behaviorFn: async () => {
                    selectPlayer({ player });
                  },
                })
              : null
            // player.status === 'pending' || player.status === 'unsold' ?
            // <AdvancedLoadingButton
            //   text='Sell Player'
            //   behaviour='modal'
            //   modal={{
            //     title: 'Sell Player',
            //     size: 'large',
            //     content: ({ onClose }) => {
            //       return (
            //         tournament && saveTournament ? <AuctionRoulette
            //           tournament={tournament}
            //           onClose={onClose}
            //           teams={tournament.teams}
            //           players={[
            //             player
            //           ]}
            //           onSold={({ player, bid }) => {
            //             const p = tournament.players.find((x) => {
            //               return x.name === player.name;
            //             });
            //             const t = tournament.teams.find(
            //               (x) => x.name === bid.topBidder
            //             );

            //             if (p && t) {
            //               p.soldAt = bid.value;
            //               p.status = 'sold';
            //               p.team = bid.topBidder;
            //               p.bidActivities = bid.activities;
            //               t.purse.spent = t.purse.spent + bid.value;
            //               t.purse.remaining = t.purse.remaining - bid.value;
            //             }

            //             saveTournament({ ...tournament });
            //           }}
            //           onUnsold={({ player }) => {
            //             const p = tournament.players.find((x) => {
            //               return x.name === player.name;
            //             });

            //             if (p) {
            //               p.status = 'unsold';
            //             }

            //             saveTournament({ ...tournament });
            //           }}
            //         />
            //         : <></>
            //       );
            //     },
            //   }}
            // /> : null
            }
          </div>
        ) : null}
        {player.status === 'sold' ? (
          <>
            <div className={`${size === 'small' ? 'text-lg' : 'text-4xl'}`}>
              Team :{' '}
              {team ? <img alt='' src={team} className='h-14 inline' /> : null}
              {player.team}
            </div>
            <div className='flex justify-end'>
              <div>
                Wanted by:{' '}
                {_.uniqBy(player.bidActivities, 'by').map((x) => (
                  <img
                    alt=''
                    key={x.by}
                    src={
                      teams.find((y) => y.name === x.by)?.logo ||
                      '/unknown-team.png'
                    }
                    className='h-10 inline mx-auto hover:scale-125'
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className={`${size === 'small' ? 'text-lg' : 'text-3xl'}`}>
            <span className='text-yellow-500'>Base Price :</span>{' '}
            {team ? <img alt='' src={team} className='h-10 inline' /> : null}
            {formatCurrency(player.basePrice)}
          </div>
        )}
      </div>
    </div>
  );
}

export function Skills({ skill }: { skill: PlayerSkills }) {
  if (skill === 'batsman') {
    return (
      <img
        alt=''
        src='https://www.iplt20.com/assets/images/teams-batsman-icon.svg'
        className='inline bg-gray-200 p-1 rounded-full animate-swing'
      />
    );
  }

  if (skill === 'bowler') {
    return (
      <img
        alt=''
        src='https://www.iplt20.com/assets/images/teams-bowler-icon.svg'
        className='inline bg-gray-200 p-1 rounded-full animate-wiggle'
      />
    );
  }

  if (skill === 'allrounder') {
    return (
      <img
        alt=''
        src='https://www.iplt20.com/assets/images/teams-all-rounder-icon.svg'
        className='inline bg-gray-200 p-1 rounded-full animate-flash'
      />
    );
  }

  if (skill === 'keeper') {
    return (
      <img
        alt=''
        src='https://www.iplt20.com/assets/images/teams-wicket-keeper-icon.svg'
        className='inline bg-gray-200 p-1 rounded-full animate-wobble'
      />
    );
  }

  return <></>;
}
