import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  currentValue: Array<IdName>;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
  useResources: string;
};

type EditPropertyArrayIdNameChangeReqFormType = {
  currentValue: Array<IdName>;
  newValue: Array<IdName>;
  reason: string;
};

export const renderEditSearchableMultiSelectPropertyChangeReq = ({
  useCombinedMetric,
  useCombinedGST,
  useCombinedColor,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
  useCombinedAppearance,
  useCombinedOdour,
}: {
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedOdour: UseCombinedOdour;
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedColor: UseCombinedColor;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}) => {
  return function EditSearchableMultiSelectPropertyChangeReq({
    currentValue,
    onSave,
    useResources,
  }: EditChangeReqProps) {
    const useSearchResource = (useResources: string) => {
      switch (useResources) {
        case 'useMetrics':
          return {
            resource: useCombinedMetric,
            body: (data) => {
              return (
                <div>
                  <div className='font-bold'>{data.name}</div>
                  <div>{data.symbol}</div>
                </div>
              );
            },
          };
        case 'useGST':
          return {
            resource: useCombinedGST,
            body: undefined,
          };
        case 'useAppearances':
          return {
            resource: useCombinedAppearance,
            body: undefined,
          };
        case 'useColors':
          return {
            resource: useCombinedColor,
            body: undefined,
          };
        case 'useOdours':
          return {
            resource: useCombinedOdour,
            body: undefined,
          };
        case 'useEmployees':
          return {
            resource: useCombinedEmployeeProfile,
            body: undefined,
          };
        case 'useGrade':
          return {
            resource: useCombinedGrade,
            body: undefined,
          };
        case 'useHazardClass':
          return {
            resource: useCombinedHazardGhsClass,
            body: (data) => {
              return (
                <div>
                  <div className='font-bold'>{data.name}</div>
                  <div>
                    {data.chapterNo} {data.categoryName}
                  </div>
                </div>
              );
            },
          };
        default:
          return null;
      }
    };

    const searchResource = useSearchResource(useResources);
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyArrayIdNameChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'jsx',
          label: 'Current Value',
          readonly: true,
          renderObject: ({ formData, field }) => {
            return (
              <div className='flex'>
                {((formData.currentValue as Array<IdName>) || []).map((y) => (
                  <div
                    className='mx-1 border rounded-full border-gray-100 p-0.5'
                    key={y.id}
                  >
                    {y.name}
                  </div>
                ))}
              </div>
            );
          },
        },
        {
          property: 'newValue',
          type: 'searchable-multi-select',
          required: true,
          label: 'New Value',
          searchOptions: {
            useSearch: searchResource.resource,
            searchOptionsBody: searchResource.body
              ? {
                  customBody: searchResource.body,
                }
              : undefined,
            filter:
              searchResource.resource === useCombinedEmployeeProfile
                ? { crossGroup: 'true' }
                : undefined,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue,
        newValue: [],
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyArrayIdNameChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyArrayIdNameChangeReqFormType>(
      formProps
    );

    return (
      <>
        <Form />;
      </>
    );
  };
};
