import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { GetPropertyEditor } from './common';
import { EditPropertyValProps } from './types';

export const renderEditArrayObjectPropertyChangeReq = ({
  useCombinedMetric,
  useCombinedGST,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}) => {
  const Table = renderTableComponent();
  return function EditArrayObjectPropertyChangeReq({
    entityId,
    itemProperty,
    currentValue,
    onSave,
  }: EditPropertyValProps) {
    const [newValues, setNewValues] = useState<any[]>(
      currentValue && Array.isArray(currentValue) ? [...currentValue] : []
    );

    const [reason, setReason] = useState<string>();

    let currentList: any = JSON.parse(JSON.stringify(currentValue)) || [];
    if (!Array.isArray(currentList)) {
      currentList = [];
    }
    const TableBody: TableBody =
      itemProperty.childrenListProperties &&
      _.keys(itemProperty.childrenListProperties).length &&
      newValues.length
        ? newValues.map((data, idx) => {
            return {
              cells: _.keys(itemProperty.childrenListProperties).map(
                (clp, clpIdx) => {
                  if (itemProperty.childrenListProperties) {
                    // if its a fixed-array-object type then first value is fixed. hence we make it editable only for
                    // current level renderer
                    if (
                      clpIdx === 0 &&
                      itemProperty.type === 'fixed-array-object' &&
                      entityId !== itemProperty.owner?.id
                    ) {
                      return {
                        value: (
                          <div>
                            <div>
                              {
                                data[
                                  itemProperty.childrenListProperties[clp].name
                                ]
                              }
                            </div>
                            <div className='italic font-xs text-gray-500'>
                              [readonly]
                            </div>
                          </div>
                        ),
                      };
                    }
                    return {
                      value: (
                        <div>
                          <div>
                            New Value:{' '}
                            <GetPropertyEditor
                              setNewValues={setNewValues}
                              itemProperty={itemProperty}
                              keyProp={clp}
                              val={data}
                              useCombinedGrade={useCombinedGrade}
                              useCombinedHazardGhsClass={
                                useCombinedHazardGhsClass
                              }
                              useCombinedGST={useCombinedGST}
                              useCombinedMetric={useCombinedMetric}
                              useCombinedAppearance={useCombinedAppearance}
                              useCombinedColor={useCombinedColor}
                              useCombinedOdour={useCombinedOdour}
                              useCombinedEmployeeProfile={
                                useCombinedEmployeeProfile
                              }
                            />
                          </div>
                        </div>
                      ),
                    };
                  }
                  return {
                    value: <>-</>,
                  };
                }
              ),
            };
          })
        : [];

    return (
      <div>
        <div className='flex'>
          <div className='text-center flex-auto'>
            <b>Type:</b> {itemProperty.type}
          </div>
          <div className='text-center flex-auto'>
            <b>Edit Property:</b> {itemProperty.name}
          </div>
          <div className='text-center flex-auto'>
            <b>Ownership: </b>{' '}
            {entityId === itemProperty.owner?.id
              ? 'Owned at Current Level'
              : 'Not owned at Current Level'}
          </div>
          <div className='flex-none'>
            {['array-object'].includes(itemProperty.type) ? (
              <LoadingButton
                defaultStyle='bg-green-500 text-white'
                behaviorFn={async () => {
                  setNewValues((nv) => [...nv, ...[{}]]);
                }}
                text='Add Row'
              />
            ) : null}
            {['fixed-array-object'].includes(itemProperty.type) &&
            entityId === itemProperty.owner?.id ? (
              <LoadingButton
                defaultStyle='bg-green-500 text-white'
                behaviorFn={async () => {
                  setNewValues((nv) => [...nv, ...[{}]]);
                }}
                text='Add'
              />
            ) : null}
          </div>
        </div>

        <Table
          header={[
            [
              {
                colSpan: (_.keys(itemProperty.childrenListProperties) || [])
                  .length,
                name: 'Properties',
                style: 'text-center',
              },
            ],
            (_.keys(itemProperty.childrenListProperties) || []).map((prop) => {
              return {
                name:
                  itemProperty.childrenListProperties &&
                  itemProperty.childrenListProperties[prop]?.type ===
                    'object' ? (
                    <div>
                      <div className='border border-gray-300'>{prop}</div>
                      <div className='flex'>
                        {itemProperty.childrenListProperties &&
                          _.keys(
                            itemProperty.childrenListProperties[prop]
                              .childrenProperties
                          ).map((childKey) => (
                            <div
                              className={`border border-gray-300 w-1/${
                                _.keys(
                                  itemProperty.childrenListProperties &&
                                    itemProperty.childrenListProperties[prop]
                                      .childrenProperties
                                ).length
                              }`}
                              key={childKey}
                            >
                              {childKey}
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    prop
                  ),
                style: 'text-center',
              };
            }),
          ]}
          body={TableBody}
        />
        <div className='flex justify-center'>
          <label className='font-bold '>Reason : </label>{' '}
          <input
            type='text'
            className='border border-gray text-left h-50'
            onChange={(evt) => {
              setReason(evt.target.value);
            }}
          />
        </div>
        <div className='flex justify-center'>
          <LoadingButton
            behaviorFn={async () =>
              await onSave({
                reason: reason || '',
                changeRequest: {
                  currentValue: {
                    value: {
                      data: currentValue,
                    },
                  },
                  newValue: {
                    value: {
                      data: newValues,
                    },
                  },
                },
              })
            }
            defaultStyle='bg-green-500 text-white p-1'
            text='Save'
          />
        </div>
      </div>
    );
  };
};
