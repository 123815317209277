import {
  SalesOrderItems,
  SalesOrderItemsFilter,
} from '@erp_core/erp-types/dist/types/modules/order/sales/sales-order-items';
import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class SalesOrdersItemsHttpRepo extends GenericHttpWithLogsRepo<
  SalesOrderItems,
  SalesOrderItemsFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/sales/sales-order-items',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
