import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import _ from 'lodash';
import moment from 'moment';

export async function generateSalary({
  salrev,
  date,
  employee,
  saveSalary,
  salaryParams,
  company,
  oldSalaryId,
}: {
  salrev: SalaryRevisionType;
  date: string;
  employee: EmployeeProfileType;
  salaryParams: any;
  saveSalary: (s: SalaryType) => Promise<SalaryType>;
  company: Company;
  oldSalaryId?: string;
}) {
  const today = moment().format('YYYY-MM');
  const dataToSend = {
    name: `${today}-${employee.name}-${employee.details.employeeId}-Salary`,
    year: moment(date, 'YYYY-MM-DD').format('YYYY'),
    month: moment(date, 'YYYY-MM-DD').format('MM'),
    status: 'pending',
    details: { salaryParams, oldSalaryId },
    employee: { id: employee.id, name: employee.name },
    salaryRevision: salrev,
    // TODO: This is not present in type
    amount: salrev.details.basic + (salrev.details.basic * 10) / 100,
    remark: '',
    bankAccountName: company.details?.bankAccounts[0]?.name || '', // TODO: Pick this from preferred bank setting once its introduced
    bankAccountNumber: company.details?.bankAccounts[0]?.account || '',
  } as any;
  const res = await saveSalary(dataToSend);
  console.log(res);
}

export function formatParam({
  value,
  metric,
  scale,
  plain,
}: {
  value: any;
  metric: 'money' | 'number' | 'percentage';
  scale?: number;
  plain?: boolean;
}) {
  if (metric === 'money') {
    return value?.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: scale || 0,
    });
  }
  if (metric === 'number') {
    if (plain) {
      const val = parseFloat(`${value}`);
      return val?.toFixed(scale || 0);
    }
    return (
      value?.toLocaleString('en-In', { maximumFractionDigits: scale || 0 }) ||
      ''
    );
  }

  if (metric === 'percentage') {
    return `${value * 100}%`;
  }

  return value;
}

export function findParamType(
  key: string,
  obj: SalaryParamGroup,
  type: 'group' | 'metric' | 'scale'
) {
  if (obj && obj.parameters && obj.parameters.length) {
    const p = obj.parameters.find((x) => _.camelCase(x.name) === key);

    if (p) {
      return p[type];
    }
  }

  if (obj && obj.subgroups && obj.subgroups.length) {
    let ans: any = undefined;
    for (let i = 0; i < obj.subgroups.length; i++) {
      const subg = obj.subgroups[i];
      ans = findParamType(key, subg, type);
      if (ans) {
        break;
      }
    }

    if (ans) {
      return ans;
    }
  }

  return;
}
