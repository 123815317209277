import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';

export function renderPackingRequest(): () => JSX.Element {
  return function PackingRequest(): JSX.Element {
    const Card = renderCardComponent();

    const Table = renderTableComponent();

    const data = [
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        batches: ['FG-229/008/22', 'FG-229/008/23'],
        quantity: 200,
        noOfPackages: '50',
        packingType: 'Drum-HN-FDPE with LDPE bag, Open top, 50.0000',
        wpp: '30.00',
        tareWeight: '2.90',
        status: 'scheduled',
      },
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        batches: ['FG-229/008/22', 'FG-229/008/23'],
        quantity: 200,
        noOfPackages: '50',
        packingType: 'Drum-HN-FDPE with LDPE bag, Open top, 50.0000',
        wpp: '30.00',
        tareWeight: '2.90',
        status: 'packed',
      },
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        batches: ['FG-229/008/22', 'FG-229/008/23'],
        quantity: 200,
        noOfPackages: '50',
        packingType: 'Drum-HN-FDPE with LDPE bag, Open top, 50.0000',
        wpp: '30.00',
        tareWeight: '2.90',
        status: 'batch-labelled',
      },
      {
        id: '8AF13D1E-67B3-4DD1-A4C6-2EF5DA0EA5B2',
        batches: ['FG-229/008/22', 'FG-229/008/23'],
        quantity: 200,
        noOfPackages: '50',
        packingType: 'Drum-HN-FDPE with LDPE bag, Open top, 50.0000',
        wpp: '30.00',
        tareWeight: '2.90',
        status: 'ready-to-dispatch',
      },
    ];
    const tableHeader: TableHeader = [
      [
        { name: 'Batch Nos' },
        { name: 'Quantity' },
        { name: 'No of Pkgs' },
        { name: 'Packing Type' },
        { name: 'WPP' },
        { name: 'Tare Weight' },
        { name: 'Status' },
      ],
    ];

    const tableData = (data || []).map((item) => ({
      rowData: {
        item,
      },
      cells: [
        { value: item.batches.join() },
        {
          value: item.quantity,
        },
        { value: item.noOfPackages },
        { value: item.packingType },
        { value: item.wpp },
        { value: item.tareWeight },
        { value: item.status },
      ],
    }));

    const tableBody: TableBody = tableData;

    const cardHeader: CardHeader = {
      title: 'Packing Request',
      subheading:
        'this page for display the list of scheduled packing requests',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Mark Packing Done',
              show: ({ item }) => item.status === 'scheduled',
              behaviour: 'modal',
              modal: {
                title: 'Mark Packing Done',
                content: ({ data: { item }, onClose }) => {
                  return (
                    <div>
                      <div>User confirm packing done</div>
                    </div>
                  );
                },
              },
            },
            {
              name: 'Print Batch label',
              show: ({ item }) => item.status === 'packed',
              behaviour: 'modal',
              modal: {
                title: 'Print Batch Label',
                content: ({ data: { item }, onClose }) => {
                  return (
                    <div>
                      <div>User finishes printing label</div>
                    </div>
                  );
                },
              },
            },
            {
              name: 'Mark Package Ready to Disptach',
              show: ({ item }) => item.status === 'batch-labelled',
              behaviour: 'modal',
              modal: {
                title: 'Mark Package Ready to Dispatch',
                content: ({ data: { item }, onClose }) => {
                  return (
                    <div>
                      <div>
                        User Mark Package ready to dispatch and notify sales
                        officer
                      </div>
                    </div>
                  );
                },
              },
            },
          ]}
        />
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
