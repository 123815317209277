import { Toaster } from 'react-hot-toast';

import { RoutesComponent } from './routes';

import { ErpV2ApiAdapter } from './adapter/erp-v2-api';
import { ADMIN_SERVER_URI } from './config/server-uri';
import { Config } from './models/interfaces/common/app-config';

export function App({ config }: { config: Config }): JSX.Element {
  const erpV2Api = new ErpV2ApiAdapter({ adminApiUrl: ADMIN_SERVER_URI });
  return (
    <main className='h-full max-h-screen min-h-screen bg-indigo-900'>
      <RoutesComponent config={config} erpV2Api={erpV2Api} />
      <Toaster
        position='bottom-right'
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
      <div className='min-w-[95%]'></div>
    </main>
  );
}
