import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  ExpressionType,
  ItemProperty,
} from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { ChangeRequestDataType } from './types';

type EditExpValueUOMChangeReqProps = {
  itemProperty: ItemProperty;
  iscurrentLevelOwnerShip: boolean;
  currentValue: {
    exp: ExpressionType;
    value: number;
    uom: IdName;
    remarks?: {
      type?: 'text' | 'uom' | '';
      value?: string | number;
      uom?: IdName;
    };
  };
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

type EditPropertyExpValueUOMChangeReqFormType = {
  currentExp: ExpressionType;
  currentValue: number;
  currentUOM: IdName;
  newExp: ExpressionType;
  newValue: string;
  newUOM: IdName;
  newRemarkType: 'text' | 'uom' | '';
  newRemarkValue: string;
  newRemarkUOM: IdName;
  reason: string;
};

export const renderEditExpValueUOMPropertyChangeReq = ({
  useCombinedMetric,
}: {
  useCombinedMetric: UseCombinedMetric;
}) => {
  return function EditExpValueUOMPropertyChangeReq({
    itemProperty,
    iscurrentLevelOwnerShip,
    currentValue,
    onSave,
  }: EditExpValueUOMChangeReqProps) {
    const useSearchResource = () => {
      return useCombinedMetric;
    };

    const searchResource = useSearchResource();
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyExpValueUOMChangeReqFormType> = {
      fieldsData: [
        {
          groupName: '1. Current Value',
          property: 'currentValue',
          type: 'jsx',
          label: 'Current Value',
          readonly: true,
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                {formData.currentExp}
                {formData.currentValue &&
                ['number', 'string'].includes(typeof formData.currentValue)
                  ? (formData.currentValue as string)
                  : ''}{' '}
                {(formData.currentUOM as any)?.name || ''}
                {currentValue?.remarks && currentValue?.remarks?.type ? (
                  <div className='text-right'>
                    {currentValue?.remarks?.value}{' '}
                    {currentValue?.remarks?.uom?.name || ''}
                  </div>
                ) : null}
              </div>
            );
          },
        },
        {
          groupName: '2. New Value',
          property: 'newExp',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: '>', value: '>' },
            { text: '>=', value: '>=' },
            { text: '<', value: '<' },
            { text: '<=', value: '<=' },
            { text: '=', value: '=' },
            { text: '!=', value: '!=' },
            { text: '><', value: '><' },
          ],
        },
        {
          groupName: '2. New Value',
          property: 'newValue',
          type: 'input',
          label: 'New Value',
          required: true,
          validate: async (form) => {
            if (form.newValue) {
              if (form.newValue === 'NA') {
                return {};
              }
              const pattern = new RegExp('^[-+]?[0-9]+(.[0-9]+)?$');
              const inBetweenPattern = new RegExp('Between (d|.)+ and (d|.)+');
              const test =
                (form.newExp as string) !== '><'
                  ? pattern.test(form.newValue as string)
                  : inBetweenPattern.test(form.newValue as string);
              if (!test) {
                return {
                  newValue:
                    form.newExp !== '><'
                      ? 'Invalid Value: Enter Number or NA'
                      : 'Value pattern fails: "Between xxx and xxx" where xxx are min-max range',
                } as any;
              }
            }

            return {};
          },
        },
        {
          groupName: '2. New Value',
          property: 'newUOM',
          type: 'searchable-select',
          readonly:
            itemProperty.valueType === 'fixed-uom' && !iscurrentLevelOwnerShip,
          required: true,
          label: 'UOM',
          searchOptions: {
            useSearch: searchResource,
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              customBody: (p) => (
                <div>
                  <div className='text-gray-600'>
                    {p.name} <b className='text-black'>{p.icon}</b>
                  </div>
                </div>
              ),
            },
          },
        },
        {
          groupName: '3. Remarks',
          property: 'newRemarkType',
          type: 'select',
          options: [
            { text: 'None', value: '' },
            { text: 'Text', value: 'text' },
            { text: 'UOM', value: 'uom' },
          ],
        },
        {
          groupName: '3. Remarks',
          property: 'newRemarkValue',
          type: 'input',
          dependentOn(formData) {
            return formData.newRemarkType !== '';
          },
        },
        {
          groupName: '3. Remarks',
          property: 'newRemarkUOM',
          type: 'searchable-select',
          dependentOn(formData) {
            return formData.newRemarkType === 'uom';
          },
          searchOptions: {
            useSearch: searchResource,
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              customBody: (p) => (
                <div>
                  <div className='text-gray-600'>
                    {p.name} <b className='text-black'>{p.icon}</b>
                  </div>
                </div>
              ),
            },
          },
        },
        {
          groupName: '4. Reason',
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentExp: currentValue?.exp || '',
        currentValue: currentValue?.value || '',
        currentUOM: currentValue?.uom || { id: '', name: '' },
        newRemarkType: currentValue?.remarks?.type || '',
        newRemarkUOM: currentValue?.remarks?.uom || { id: '', name: '' },
        newRemarkValue: currentValue?.remarks?.value || '',
        newExp: currentValue?.exp || '',
        newValue: currentValue.value || '',
        newUOM: currentValue.uom || { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        const finalData: any = {
          exp: f.newExp || currentValue.exp,
          value: f.newValue || currentValue.value,
          uom: f.newUOM?.id
            ? f.newUOM
            : currentValue.uom || { id: '', name: '' },
        };

        if (f.newRemarkType && f.newRemarkValue) {
          finalData.remarks = {
            type: f.newRemarkType,
            value: f.newRemarkValue,
          };
          if (f.newRemarkType === 'uom' && f.newRemarkUOM?.id) {
            finalData.remarks.uom = f.newRemarkUOM;
          }
        }

        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: currentValue,
              },
            },
            newValue: {
              value: {
                data: finalData,
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyExpValueUOMChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyExpValueUOMChangeReqFormType>(
      formProps
    );

    return (
      <>
        <Form />;
      </>
    );
  };
};
