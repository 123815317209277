import { QuestionsType } from '@erp_core/erp-types/dist/modules/utility';
import { SurveyAnswerType } from '../form';

export function AnswerComponent({
  mode,
  question,
  surveyAns,
  setSurveyAns,
}: {
  mode: 'edit' | 'readonly';
  question: QuestionsType;
  surveyAns: SurveyAnswerType;
  setSurveyAns: React.Dispatch<React.SetStateAction<SurveyAnswerType>>;
}): JSX.Element {
  const answerTypeJSx = AnswerInputTypes({
    question,
    surveyAns,
    setSurveyAns,
    mode,
  });
  return <div>{answerTypeJSx}</div>;
}

export function AnswerInputTypes({
  question,
  surveyAns,
  setSurveyAns,
  mode,
}: {
  mode: 'edit' | 'readonly';
  question: QuestionsType;
  surveyAns: SurveyAnswerType;
  setSurveyAns: React.Dispatch<React.SetStateAction<SurveyAnswerType>>;
}): JSX.Element {
  const currentAns =
    surveyAns.find((x) => x.questionId === question.id)?.answerValue || '';
  const reason =
    surveyAns.find((x) => x.questionId === question.id)?.reason || '';

  if (mode === 'readonly') {
    return (
      <>
        <div>
          {typeof currentAns === 'string'
            ? currentAns
            : JSON.stringify(currentAns)}
        </div>
        <div>{reason ? `Reason:- ${reason}` : ''}</div>
      </>
    );
  }
  switch (question.answerType) {
    case 'binary':
      return (
        <div className='pl-6'>
          <>
            <label>
              <input
                type='radio'
                value='yes'
                name={question.id}
                className='text-gray-700 text-xm'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              &nbsp;Yes
            </label>{' '}
            &nbsp;&nbsp;
            <label>
              <input
                type='radio'
                value='no'
                name={question.id}
                className='text-gray-700 text-xm  mb-2'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              &nbsp;No
            </label>
          </>
          <br />
        </div>
      );
    case 'binaryWithText':
      return (
        <div className='pl-6'>
          <>
            <label>
              <input
                type='radio'
                value='yes'
                name={question.id}
                className='text-gray-700 text-xm  mb-2'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              &nbsp;Yes
            </label>{' '}
            &nbsp;&nbsp;
            <label>
              <input
                type='radio'
                value='no'
                name={question.id}
                className='text-gray-700 text-xm  mb-2'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              &nbsp;No
            </label>
          </>
          <br />
          <input
            className='block w-1/2 p-2 border border-gray-300 rounded-lg'
            type='text'
            onChange={(e) => {
              const newVal = e.target.value;
              const sa = surveyAns.find((x) => x.questionId === question.id);
              if (sa) {
                sa.reason = newVal;
              }
              setSurveyAns([...surveyAns]);
            }}
          ></input>
        </div>
      );
    case 'text':
      return (
        <div className='pl-6'>
          <input
            type='text'
            className='block w-1/2 p-2 border border-gray-300 rounded-lg'
            value={currentAns}
            onChange={(e) => {
              const newVal = e.target.value;
              const sa = surveyAns.find((x) => x.questionId === question.id);
              if (sa) {
                sa.answerValue = newVal;
              }
              setSurveyAns([...surveyAns]);
            }}
          ></input>
        </div>
      );
    case 'multiselect':
      return (
        <div className='pl-6'>
          {question.details.answers?.map((item, index) => (
            <div key={index}>
              <input
                value={item}
                type='checkbox'
                name='mselect'
                className='text-gray-700 text-xm  mb-2'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              <span>&nbsp;{item}</span>
            </div>
          ))}
        </div>
      );
    case 'multichoice':
      return (
        <div className='pl-6'>
          {question.details.answers?.map((item, index) => (
            <div key={index}>
              <input
                value={item}
                type='radio'
                name={question.id}
                className='text-gray-700 text-xm  mb-2'
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = surveyAns.find(
                    (x) => x.questionId === question.id
                  );
                  if (sa) {
                    sa.answerValue = newVal;
                  }
                  setSurveyAns([...surveyAns]);
                }}
              />
              <span>&nbsp;{item}</span>
            </div>
          ))}
        </div>
      );
    default:
      return <div>Invalid question type.</div>;
  }
}
