import { LogsType } from '@erp_core/erp-types/dist/modules/common';
import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  renderLogs,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { AttendanceInterface } from 'models/interfaces/hrd/attendance';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../../../../contexts/user';
import { UseCombinedAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderAttendanceBody } from '../day-book/components/table/table-body';
import { Header } from '../day-book/components/table/table-header';

export function renderAttendanceDetails({
  isDaybook,
  attendanceService,
  userRendererService,
  useCombinedAttendance,
}: {
  isDaybook: boolean;
  attendanceService: AttendanceInterface;
  userRendererService: UserRendererInterface;
  useCombinedAttendance: UseCombinedAttendance;
}) {
  const Table = renderTableComponent();

  return function AttendanceDetails() {
    const [att, setAtt] = useState<CombinedAttDataType | null>(null);
    const [logs, setLogs] = useState<LogsType[]>([]);
    const { id, date } = useParams();
    const tableHeader = Header({ type: 'employee-book' });
    const { user: currentUser } = useContext(UserContext);
    const { syncSet: setAttendance } = useCombinedAttendance();

    useEffect(() => {
      if (id && date) {
        attendanceService.getSingleAttendance(id, date).then((res) => {
          if (res.attendance) {
            setAtt(res.attendance);
          }
          if (res.activity) {
            setLogs(
              res.activity.map((x) => {
                (x as any).changes?.forEach((y) => {
                  if (_.isObject(y.initialValue)) {
                    y.initialValue = JSON.stringify(y.initialValue);
                  }
                  if (_.isObject(y.finalValue)) {
                    y.finalValue = JSON.stringify(y.finalValue);
                  }
                  return y;
                });

                return x;
              })
            );
          }
        });
      }
    }, [date, id]);
    const AttBody = renderAttendanceBody({
      isDaybook,
      userRendererService,
      currentUser,
      getAllAttendanceSync: async () => {
        if (id && date) {
          const res = await attendanceService.getSingleAttendance(id, date);
          setAtt(res.attendance);

          return res.attendance;
        }

        return {} as any;
      },
      setAttendance,
      fetchData: async () => {
        if (id && date) {
          const res = await attendanceService.getSingleAttendance(id, date);
          setAtt(res.attendance);
        }
      },
    });

    const newtableBody: TableBody = att
      ? AttBody({
          type: 'employee-book',
          list: [{ attendance: att }],
        })
      : [];

    if (!att) {
      return <div>Loading...</div>;
    }

    const Logs = renderLogs();
    return (
      <>
        <Table
          header={tableHeader}
          body={newtableBody}
          actions={[]}
          auth={currentUser.authorization}
        />
        <Logs title='History' data={logs} />
      </>
    );
  };
}
