import {
  CompanyGroupSetting,
  InventorySetting,
} from '@erp_core/erp-types/dist/types/modules/admin/companyGroupSetting';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';

export type ClassifierProps = {
  config: InventorySetting;
  setCGS: (s: CompanyGroupSetting) => Promise<CompanyGroupSetting>;
};

export function renderClassifiers(): (props: ClassifierProps) => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function Classfiers({ config, setCGS }: ClassifierProps): JSX.Element {
    const [classifiers] = useState<
      {
        sequence: number;
        name: string;
        description: string;
      }[]
    >(
      (config.classifiers || []).sort((a, b) => {
        a.sequence = a.sequence || 0;
        b.sequence = b.sequence || 0;
        const aSeq = parseInt(`${a.sequence}` || '0');
        const bSeq = parseInt(`${b.sequence}` || '0');

        return aSeq - bSeq;
      })
    );

    const tableBody: TableBody = classifiers.map((x, idx) => {
      return {
        rowData: {
          classifier: x,
          idx: idx,
        },
        cells: [
          { value: x.name },
          { value: x.sequence },
          { value: x.description },
        ],
      };
    });

    const header: CardHeader = {
      title: 'Item Classifiers',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Classifier',
              content: ({ onClose }) => {
                const Form = renderFormV2<{
                  sequence: number;
                  name: string;
                  description: string;
                }>({
                  fieldsData: [
                    {
                      label: 'Sequence',
                      property: 'sequence',
                      required: true,
                      type: 'number',
                    },
                    {
                      label: 'Name',
                      property: 'name',
                      required: true,
                      type: 'input',
                    },
                    {
                      label: 'Description',
                      property: 'description',
                      required: true,
                      type: 'input',
                    },
                  ],
                  onSubmit: async (form) => {
                    const inventorySetting = config;
                    inventorySetting.classifiers = [...classifiers, form];
                    await setCGS({
                      details: {
                        inventory: inventorySetting,
                      },
                    } as any);
                    onClose();
                    //save(form)
                  },
                  mapTToU: (p) => {
                    return p as any;
                  },
                  allowUnchanged: true,
                  initialFormState: { name: '', description: '', sequence: 0 },
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={[
              [{ name: 'Name' }, { name: 'Sequence' }, { name: 'Description' }],
            ]}
            body={tableBody}
            actions={[
              {
                name: 'Edit Sequence',
                show: () => true,
                behaviour: 'confirm',
                onConfirm: ({ classifier, idx }) => {
                  return {
                    title: 'Update Classifier Sequence',
                    showInput: true,
                    onConfirm: async (inp) => {
                      if (inp) {
                        const inventorySetting = config;
                        inventorySetting.classifiers = [...classifiers];
                        classifiers[idx].sequence = parseInt(inp);
                        await setCGS({
                          details: {
                            inventory: inventorySetting,
                          },
                        } as any);
                      }
                    },
                  };
                },
              },
            ]}
          />
        </>
      ),
    };
    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
