import _ from 'lodash';
import { useEffect, useState } from 'react';
import RoulettePro from 'react-roulette-pro';
import 'react-roulette-pro/dist/index.css';
import { v4 } from 'uuid';
import { Player, Team, Tournament } from './data';

export function AuctionRoulette({
  tournament,
  players,
  teams,
  onClose,
  // onSold,
  onUnsold,
  onPlayerSelect,
}: {
  tournament: Tournament;
  players: Player[];
  teams: Team[];
  onClose: () => void;
  // onSold: ({ player, bid }: { player: Player; bid: Bid }) => void;
  onUnsold: ({ player }: { player: Player }) => void;
  onPlayerSelect: ({ player }: { player: Player }) => void;
}) {
  const [start, setStart] = useState(false);
  const handleStart = () => {
    setStart((prevState) => !prevState);
  };

  let playersInflatedList = [...players];
  while (playersInflatedList.length < 100) {
    playersInflatedList = [...players, ...playersInflatedList];
  }

  const [list] = useState<Array<any>>(
    _.shuffle(_.shuffle(playersInflatedList)).map((x) => ({
      id: v4(),
      text: x.name,
      image:
        x.photo || 'https://www.iplt20.com/assets/images/default-headshot.png',
    }))
  );
  const [index, setIndex] = useState<number>(
    _.random(0, list.length - 1, false)
  );
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(
    players.length === 1 ? players[0] : null
  );

  useEffect(() => {
    if (!start) {
      console.log('resetting index');
      setIndex(_.random(0, list.length - 1, false));
    }
  }, [start, list.length]);

  const handlePrizeDefined = () => {
    console.log(
      '🥳 Prize defined! 🥳',
      list,
      index,
      'select hua',
      list[index].text
    );
    const player = players.find((x) => x.name === list[index].text);
    if (player) {
      setTimeout(() => {
        setSelectedPlayer(player);
      }, 3000);
    }
  };

  useEffect(() => {
    if (selectedPlayer) {
      onPlayerSelect({ player: selectedPlayer });
      onClose();
    }
    // eslint-disable-next-line
  }, [selectedPlayer]);

  return (
    <>
      {!selectedPlayer ? (
        <>
          <RoulettePro
            prizes={list}
            prizeIndex={index}
            spinningTime={10}
            start={start}
            // type='vertical'
            defaultDesignOptions={{
              prizesWithText: true,
            }}
            onPrizeDefined={handlePrizeDefined}
          />
          <div className='flex justify-center'>
            <button
              className='bg-gray-800 text-white p-2 my-2'
              onClick={handleStart}
            >
              {start ? 'Reset' : 'Start'}
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
