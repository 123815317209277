import { CurrentContextProvider } from '../datalayer/providers/current';
import { UserProvider } from '../datalayer/providers/user';
import { Config, Hooks } from '../models/interfaces/common/app-config';
import { UserRenderer } from '../modules/common/fragments/user';
import {
  accountGroupService,
  daybookService,
  ledgerService,
  useCombinedAccountGroup,
  useCombinedDaybook,
  useCombinedLedger,
  useCombinedVoucher,
  useCombinedVoucherTransaction,
  voucherService,
} from './accounts';
import {
  authorizationService,
  companyGroupAdminService,
  companyGroupSettingService,
  currencyService,
  deliveryTermsService,
  gstService,
  locationsService,
  loginService,
  metricsService,
  notificationActionService,
  paymentTermsService,
  resetPasswordService,
  roleAdminService,
  setPasswordService,
  useCombinedActionAdmin,
  useCombinedAppearance,
  useCombinedAuthorization,
  useCombinedColor,
  useCombinedCompanyAdmin,
  useCombinedCompanyGroupAdmin,
  useCombinedCompanyGroupSetting,
  useCombinedCurrency,
  useCombinedDeliveryTerm,
  useCombinedDepartment,
  useCombinedEmployeeCategory,
  useCombinedEmployeeDesignation,
  useCombinedGST,
  useCombinedHsn,
  useCombinedLocationAdmin,
  useCombinedMetric,
  useCombinedModuleAdmin,
  useCombinedNotificationAction,
  useCombinedOdour,
  useCombinedOnboardEmployee,
  useCombinedPaymentTerm,
  useCombinedResourcesAdmin,
  useCombinedRole,
  useCombinedUser,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useCurrentLocation,
  useCurrentUser,
  useCurrentUserRoles,
  useLogin,
  useLogout,
  userAdminService,
  useResetPassword,
  useSetPassword,
  useSisterLocation,
  useUserAuthorization,
  useUserRoles,
} from './admin';
import {
  dashboardService,
  hrdDashboardReportService,
  useBomEstimate,
  useCombinedDashboard,
  useHrdDashboardReport,
} from './analytics';
import { useCombinedTemplate } from './communication';
import {
  useCombinedMisconduct,
  useCombinedMisconductRuleBook,
  useCombinedRuleRequest,
} from './disciplinary';
import {
  fileTransferService,
  useFileTransfer,
  usePublicTransfer,
} from './file-transfer';
import {
  attendanceDeviceService,
  attendanceService,
  employeeExitService,
  employeeLettersService,
  employeeProfileService,
  esicReturnService,
  gatePassService,
  leaveService,
  minimumWagesService,
  offSiteWorkHoursService,
  overtimeService,
  pfReturnService,
  ptReturnService,
  statutoryReturnService,
  useAttendanceMonthlySummary,
  useCombinedAttendance,
  useCombinedAttendanceDevice,
  useCombinedDocument,
  useCombinedEmployeeEducation,
  useCombinedEmployeeFamily,
  useCombinedEmployeeLetter,
  useCombinedEmployeeProfile,
  useCombinedEmployeeRegistration,
  useCombinedESICReturn,
  useCombinedGatePass,
  useCombinedLeave,
  useCombinedMinimumWage,
  useCombinedOffSiteWorkHour,
  useCombinedOvertime,
  useCombinedPFReturn,
  useCombinedPTReturn,
  useCombinedStatutoryReturn,
} from './hrd';
import { useCombinedPolicy, useCombinedPremium } from './insurance';
import {
  batchItemGodownService,
  ghsPrecautionaryStatementService,
  godownService,
  godownTransferService,
  gradeService,
  hazardCategoryService,
  hazardGhsClassService,
  hazardStatementService,
  itemCategoryService,
  itemCategoryV2Service,
  itemV2Service,
  physicalStockService,
  revisionService,
  stockGroupService,
  useBatchGodownItemAllocate,
  useCombinedBatchItemGodown,
  useCombinedGodown,
  useCombinedGodownTransfer,
  useCombinedGrade,
  useCombinedHazardCategory,
  useCombinedHazardGhsClass,
  useCombinedHazardStatement,
  useCombinedItem,
  useCombinedItemCategory,
  useCombinedPrecautionaryStatement,
  useCombinedRevision,
  useCombinedStockGroup,
  useGodownsTree,
  useMRS,
  usePhysicalStock,
} from './inventory';
import {
  loanService,
  useAdvPaymentData,
  useCombinedLoan,
  useCombinedRepaymentSchedule,
  useDisbursementData,
  useDisbursementPostData,
  useSkipRepaymentData,
} from './loans';
import {
  notificationConfiguratorService,
  notificationService,
  useCombinedNotificationConfigurator,
} from './notification';
import {
  bomFlowService,
  bomService,
  enquiryQuotesService,
  manufacturingVoucherService,
  purchaseEnquiryService,
  purchaseOrderFulfilmentService,
  purchaseOrderService,
  purchaseRequisitionService,
  salesEnquiryService,
  salesOrderService,
  useCombinedBom,
  useCombinedBomDependency,
  useCombinedBomFlow,
  useCombinedBomProject,
  useCombinedClient,
  useCombinedClientItem,
  useCombinedEnquiryQuote,
  useCombinedLeadCustomer,
  useCombinedLeadCustomerEnquiry,
  useCombinedLeadEnquiry,
  useCombinedLicenseContract,
  useCombinedLicenseContractDocType,
  useCombinedManufacturingVoucher,
  useCombinedProductBatch,
  useCombinedPurchaseEnquiry,
  useCombinedPurchaseOrder,
  useCombinedPurchaseOrderFulfilment,
  useCombinedPurchaseRequisition,
  useCombinedSalesEnquiry,
  useCombinedSalesOrder,
  useCombinedSalesOrders,
  useCombinedSalesOrdersItems,
  useCombinedVendor,
  useVendorItem,
  useVirtualItemAllocate,
  useVirtuallyReserveMfVoucher,
  useVirtualMfStock,
  useVirtualStock,
  vendorService,
  virtualManufacturingStockService,
  virtualStockService,
} from './order';
import {
  payrollReportsService,
  salaryRevisionService,
  salaryService,
  useCombinedPayrollReport,
  useCombinedSalary,
  useCombinedSalaryParam,
  useCombinedSalaryRevision,
} from './payroll';
import {
  empShiftScheduleService,
  locationHolidaysService,
  logRecordService,
  machineScheduleService,
  machineService,
  machineShiftService,
  rotationalShiftDayService,
  rotationalShiftScheduleService,
  shiftScheduleService,
  switchShiftScheduleService,
  useCombinedEmpShiftSchedule,
  useCombinedLocationHoliday,
  useCombinedLogRecord,
  useCombinedMachine,
  useCombinedMachineSchedule,
  useCombinedMachineShift,
  useCombinedRotationalShiftDay,
  useCombinedRotationalShiftSchedule,
  useCombinedShiftSchedule,
  useCombinedSwitchShift,
  useGetMachineSchedule,
} from './planning';
import {
  useCombinedChangeAssesment,
  useCombinedClosureDatePeriod,
} from './quality-assurance';
import {
  questionsService,
  topicService,
  useCombinedQuestionnaireQuestion,
  useCombinedQuestionnaireSurveyAnswer,
  useCombinedQuestionnaireTopic,
  useCombinedTopic,
  useQuestion,
  useQuestions,
} from './questionnaire';
import { reportsService, useCombinedReport } from './reporting';
import {
  useCombinedSalaryAdvance,
  useSalaryAdvanceEligibleAmt,
} from './salary-advance';
import { useCombinedSalaryBonus } from './salary-bonus';
import { useCombinedJob, useCombinedTask } from './scheduler';

export const config: Config = {
  mode: 'production',
  services: {
    voucherService,
    dashboardService,
    hrdDashboardReportService,
    loansService: loanService,
    userRendererService: new UserRenderer({
      fileService: fileTransferService,
      employeeService: employeeProfileService,
    }),
    revisionService,
    login: loginService,
    resetPassword: resetPasswordService,
    setPassword: setPasswordService,
    authorizationService,
    companyGroupService: companyGroupAdminService,
    locations: locationsService,
    roleAdmin: roleAdminService,
    userService: userAdminService,
    metricsService,
    gstService,
    deliveryTermsService,
    paymentTermsService,
    currencyService,
    godownService,
    stockGroupService,
    itemCategoryV2Service,
    itemCategoryService,
    fileTransferService,
    vendorService,
    hazardCategoryService,
    hazardStatementService,
    ghsPrecautionaryStatementService,
    hazardGhsClassService,
    employeeProfileService,
    employeeLettersService,
    statutoryReturnService,
    pfReturnService,
    minimumWagesService,
    ptReturnService,
    esicReturnService,
    employeeExitService,
    attendanceService,
    attendanceDeviceService,
    gatePassService,
    leaveService,
    overtimeService,
    offSiteWorkHoursService,
    itemV2Service,
    gradeService,
    bomService,
    physicalStockService,
    virtualStockService,
    batchItemGodownService,
    purchaseOrderService,
    purchaseRequisitionService,
    purchaseEnquiryService,
    enquiryQuotesService,
    purchaseOrderFulfilmentService,
    salesEnquiryService,
    virtualManufacturingStockService,
    manufacturingVoucherService,
    salesOrderService,
    godownTransferService,
    shiftScheduleService,
    empShiftScheduleService,
    locationHolidaysService,
    logRecordService,
    machineService,
    machineShiftService,
    machineScheduleService,
    daybookService,
    accountGroupService,
    ledgerService,
    notificationService,
    companyGroupSettingService,
    notificationActionService,
    topicService,
    questionsService,
    salaryService,
    salaryRevisionService,
    payrollReportsService,
    rotationalShiftScheduleService,
    rotationalShiftDayService,
    switchShiftScheduleService,
    bomFlowService,
    reportsService,
    notificationConfiguratorService,
  },
  hooks: createHooks(),
  providers: {
    current: CurrentContextProvider,
    user: UserProvider,
  },
};

export function createHooks(): Hooks {
  return {
    useCombinedProductBatch,
    useCombinedSalaryParam,
    useCombinedDashboard,
    useSisterLocation,
    useCombinedSalary,
    useCombinedSalaryRevision,
    useCombinedPayrollReport,
    useCombinedPaymentTerm,
    useCombinedEmployeeCategory,
    useCombinedEmployeeDesignation,
    useCombinedDeliveryTerm,
    useUserAuthorization,
    useCurrentUser,
    useCombinedAuthorization,
    useLogin,
    useLogout,
    useResetPassword,
    useSetPassword,
    useCurrentCompanyGroup,
    useCombinedLocationAdmin,
    useCurrentLocation,
    useCombinedRole,
    useCombinedModuleAdmin,
    useCombinedPrecautionaryStatement,
    useCombinedHazardCategory,
    useCombinedHazardStatement,
    useCombinedResourcesAdmin,
    useCombinedActionAdmin,
    useCurrentCompany,
    useCombinedCompanyAdmin,
    useCombinedCompanyGroupAdmin,
    useUserRoles,
    useGodownsTree,
    useCombinedVendor,
    useVendorItem,
    useCombinedEmployeeProfile,
    useCombinedStatutoryReturn,
    useCombinedMinimumWage,
    useCombinedPFReturn,
    useCombinedPTReturn,
    useCombinedESICReturn,
    useCombinedGrade,
    useCombinedItem,
    useCombinedBom,
    usePhysicalStock,
    useVirtualStock,
    useCombinedPurchaseRequisition,
    useCombinedPurchaseOrder,
    useCombinedPurchaseEnquiry,
    useCombinedManufacturingVoucher,
    useCombinedBatchItemGodown,
    useCombinedEnquiryQuote,
    useCombinedSalesEnquiry,
    useCombinedLicenseContractDocType,
    useCombinedLicenseContract,
    useBatchGodownItemAllocate,
    useCombinedPurchaseOrderFulfilment,
    useVirtualItemAllocate,
    useMRS,
    useCombinedGodownTransfer,
    useCombinedStockGroup,
    useCombinedGodown,
    useCombinedBomFlow,
    useCombinedBomDependency,
    useCombinedShiftSchedule,
    useCombinedEmpShiftSchedule,
    useCombinedLocationHoliday,
    useCombinedClientItem,
    useCombinedLeadCustomer,
    useCombinedLeadCustomerEnquiry,
    useVirtualMfStock,
    useVirtuallyReserveMfVoucher,
    useCombinedSalesOrder,
    useCombinedMachine,
    useCombinedMachineShift,
    usePublicTransfer,
    useFileTransfer,
    useCombinedMachineSchedule,
    useGetMachineSchedule,
    useCombinedLogRecord,
    useCombinedCompanyGroupSetting,
    useBomEstimate,
    useHrdDashboardReport,
    useCombinedNotificationAction,
    useCombinedCurrency,
    useCombinedMetric,
    useCombinedUser,
    useCombinedItemCategory,
    useCombinedGST,
    useCombinedHazardGhsClass,
    useCombinedClient,
    useCombinedAccountGroup,
    useCombinedLedger,
    useCombinedDaybook,
    useCombinedAttendance,
    useAttendanceMonthlySummary,
    useCombinedAttendanceDevice,
    useCombinedGatePass,
    useCombinedOvertime,
    useCombinedLeave,
    useCurrentUserRoles,
    useCombinedTopic,
    useCombinedOffSiteWorkHour,
    useQuestion,
    useQuestions,
    useCombinedRotationalShiftSchedule,
    useCombinedRotationalShiftDay,
    useCombinedSwitchShift,
    useCombinedQuestionnaireTopic,
    useCombinedQuestionnaireQuestion,
    useCombinedQuestionnaireSurveyAnswer,
    useCombinedMisconduct,
    useCombinedMisconductRuleBook,
    useCombinedRuleRequest,
    useCombinedSalaryAdvance,
    useSalaryAdvanceEligibleAmt,
    useCombinedSalaryBonus,
    useCombinedPolicy,
    useCombinedPremium,
    useCombinedLoan,
    useCombinedRepaymentSchedule,
    useDisbursementData,
    useDisbursementPostData,
    useSkipRepaymentData,
    useAdvPaymentData,
    useCombinedEmployeeRegistration,
    useCombinedEmployeeEducation,
    useCombinedEmployeeFamily,
    useCombinedDocument,
    useCombinedTask,
    useCombinedJob,
    useCombinedTemplate,
    useCombinedReport,
    useCombinedNotificationConfigurator,
    useCombinedDepartment,
    useCombinedBomProject,
    useCombinedOnboardEmployee,
    useCombinedRevision,
    useCombinedAppearance,
    useCombinedOdour,
    useCombinedColor,
    useCombinedHsn,
    useCombinedEmployeeLetter,
    useCombinedChangeAssesment,
    useCombinedClosureDatePeriod,
    useCombinedVoucher,
    useCombinedVoucherTransaction,
    useCombinedLeadEnquiry,
    useCombinedSalesOrders,
    useCombinedSalesOrdersItems,
  };
}
