import {
  CompanyGroup,
  CurrentUserType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  Revision,
  RevisionType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { BoltIcon } from '@heroicons/react/24/outline';
import { eval as expEval, parse } from 'expression-eval';
import _ from 'lodash';
import { useState } from 'react';
import { UseCombinedAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { createChangeRequest } from './change-request';
import { ValueRenderer } from './value-renderer/value-renderer';

export function renderPropertyValueDetails({
  useFileTransfer,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedGST,
  useCombinedMetric,
  useCombinedOdour,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  useFileTransfer: UseFileTransfer;
  useCombinedMetric: UseCombinedMetric;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGST: UseCombinedGST;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}) {
  return function PropertyValueDetails({
    parentValues,
    currentValue,
    previousValue,
    changeRequestValue,
    type,
    onClose,
    entityId,
    currentUser,
    currentCompanyGroup,
    setRevision,
    properties,
  }: {
    parentValues: ItemPropertyValue[];
    currentValue: ItemPropertyValue;
    previousValue: ItemPropertyValue;
    changeRequestValue: ItemPropertyValue;
    type: RevisionType;
    entityId: string;
    onClose: () => void;
    currentUser: CurrentUserType;
    setRevision: (data: Revision) => Promise<void>;
    currentCompanyGroup: CompanyGroup;
    properties: ItemPropertyValue[];
  }): JSX.Element {
    const ChangeRequest = createChangeRequest({
      useCombinedAppearance,
      useCombinedColor,
      useCombinedGST,
      useCombinedMetric,
      useCombinedOdour,
      useCombinedEmployeeProfile,
      useFileTransfer,
      useCombinedGrade,
      useCombinedHazardGhsClass,
    });

    let propOptions = currentValue?.selectOptions || '';
    const invalidValueRules =
      currentValue.rules?.filter((r) => r.type === 'invalid-value') || [];

    invalidValueRules.forEach((q) => {
      const availableValues: any = {};
      let condition = q.condition;
      properties.forEach((z) => {
        if (z.value?.data) {
          const camelCased = _.camelCase(z.name);
          if (condition.includes(z.name)) {
            condition = condition.replaceAll(z.name, camelCased);
          }
          availableValues[camelCased] = z.value.data;
        }
      });
      try {
        const expression = parse(condition);
        const evaluated = expEval(expression, availableValues);
        if (evaluated) {
          // In this case we must remove the ignored values from options
          const allInvalidValues = (
            q.invalidValues?.split(',') || []
          ).map((x) => x.trim());

          const finalOptions: string[] = [];
          (propOptions as string[]).forEach((op) => {
            if (!allInvalidValues.includes(op)) {
              finalOptions.push(op);
            }
          });

          propOptions = finalOptions;
        }
      } catch (e) {
        console.log('failed to evaluate', e);
      }
    });

    const [showChangeRequest, setShowChangeRequest] = useState<boolean>(false);
    return (
      <div>
        <div className='flex'>
          <div className='p-1 border border-gray-200 rounded-md text-red-500 w-1/3'>
            <div className='text-center'>Previous Value</div>
            <div>
              Value:{' '}
              <ValueRenderer
                parentValues={parentValues}
                x={previousValue}
                useFileTransfer={useFileTransfer}
              />
              {currentValue.value ? <div>set at TODO</div> : null}
            </div>
          </div>
          <div className='p-1 border border-gray-200 rounded-md text-black w-1/3'>
            <div className='text-center'>Current Value</div>
            <div>
              Value:{' '}
              <ValueRenderer
                parentValues={parentValues}
                x={currentValue}
                useFileTransfer={useFileTransfer}
              />
              {currentValue.value ? (
                <div>set at level: {currentValue.value?.owner?.name}</div>
              ) : null}
            </div>
          </div>
          <div className='p-1 border border-gray-200 rounded-md text-green-500 w-1/3'>
            <div className='text-center'>Change Request Value</div>
            <div>
              Value:{' '}
              <ValueRenderer
                parentValues={parentValues}
                x={changeRequestValue}
                useFileTransfer={useFileTransfer}
              />
            </div>
            <div>
              {!changeRequestValue.value ? (
                <LoadingButton
                  text='Request'
                  behaviorFn={async () => {
                    setShowChangeRequest(true);
                  }}
                  behaviourParams={{}}
                />
              ) : null}
            </div>
          </div>
        </div>

        {showChangeRequest ? (
          <div>
            <div className='text-center'>Change Request</div>
            {currentValue.rules?.length ? (
              <>
                {currentValue.rules.map((x, idx) => (
                  <div key={idx}>
                    <BoltIcon className='inline w-5 text-yellow-500 mx-2' />
                    {x.description}
                  </div>
                ))}
              </>
            ) : null}
            <ChangeRequest
              currentCompanyGroup={currentCompanyGroup}
              propType={(currentValue?.type as string) || ''}
              value={currentValue.value}
              property={currentValue.name}
              itemProperty={currentValue}
              entityId={entityId}
              currentUser={currentUser}
              setRevision={setRevision}
              type={type}
              onClose={onClose}
              propOptions={propOptions}
              currentValue={currentValue}
              searchSelectOptions={
                (currentValue?.searchSelectOptions as string) || ''
              }
            />
          </div>
        ) : null}
      </div>
    );
  };
}
