import { QuestionsType } from '@erp_core/erp-types/dist/modules/utility';
import { renderTableComponent } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { SurveyAnswerType } from '../survey-answers/form';

export type QuestionRendererProps = {
  mode: 'edit' | 'readonly';
  surveyAns: SurveyAnswerType;
  r: QuestionsType;
  dynamicData: any;
  setSurveyAns: React.Dispatch<React.SetStateAction<SurveyAnswerType>>;
};

export const customQuestionRenderer: {
  [key: string]: Array<{
    functionName: string;
    renderer: (p: QuestionRendererProps) => JSX.Element;
  }>;
} = {
  '85025EE1-E570-43D1-A390-4A141E31BE7D': [
    {
      functionName: 'chemicalCompositionRenderer',
      renderer: chemicalCompositionRenderer,
    },
    {
      functionName: 'gcTest',
      renderer: GCTest,
    },
    {
      functionName: 'impurityRenderer',
      renderer: impurityRenderer,
    },
  ],
  'A49079C2-8852-471B-A86E-FCD70D53DBDE': [
    {
      functionName: 'chemicalCompositionRenderer',
      renderer: chemicalCompositionRenderer,
    },
    {
      functionName: 'gcTest',
      renderer: GCTest,
    },
    {
      functionName: 'impurityRenderer',
      renderer: impurityRenderer,
    },
  ],
};

function impurityRenderer(p: QuestionRendererProps): JSX.Element {
  const impurities: Array<{
    impurity: string;
    'unit-of-measurement': string;
    MoA: string;
    'test-done': string;
    'lower-value': string;
    'upper-value': string;
  }> = p.dynamicData.properties?.find(
    (x) => x.name === 'assay-of-other-impurity'
  )?.value?.data;

  return (
    <div>
      {impurities?.map((i, index) => {
        const surAns = p.surveyAns?.find((x) => x.questionId === p.r.id);
        const ansVal =
          surAns?.answerValue && (surAns as any).answerValue[index].actual;

        if (i['test-done'] === 'yes') {
          return (
            <div key={index} className='border border-gray-200 p-1 rounded'>
              <div className='bg-slate-100 p-1'>
                <div className='text-lg'>
                  Record {i.impurity} in {i['unit-of-measurement']}.
                </div>
                <div className='italic'>The Moa is {i.MoA}</div>
                <div className='italic text-red-500'>
                  Ideal Answer: Between {i['lower-value']} and{' '}
                  {i['upper-value']}
                </div>
              </div>

              <div className='pl-6'>
                <input
                  type='text'
                  className='block w-1/2 p-2 border border-gray-300 rounded-lg'
                  value={ansVal}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    const sa = p.surveyAns.find((x) => x.questionId === p.r.id);
                    if (sa) {
                      if (!sa.answerValue) {
                        (sa as any).answerValue = impurities?.map((t) => ({
                          name: t.impurity,
                          expected: `Between ${i['lower-value']} and ${i['upper-value']}`,
                          actual: '',
                        }));
                      }
                      (sa as any).answerValue[index].actual = newVal;
                      p.setSurveyAns([...p.surveyAns]);
                    }
                  }}
                ></input>
              </div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}

function chemicalCompositionRenderer(p: QuestionRendererProps): JSX.Element {
  // console.log(p.dynamicData);
  const chemComp: Array<{
    component: string;
    moa: string;
    specification: string;
    uom: string;
  }> = p.dynamicData.properties?.find((x) => x.name === 'chemical-composition')
    ?.value?.data;
  // console.log(chemComp)
  return (
    <div>
      {chemComp?.map((i, index) => {
        const surAns = p.surveyAns?.find((x) => x.questionId === p.r.id);
        const ansVal =
          surAns?.answerValue && (surAns as any).answerValue[index].actual;
        return (
          <div key={index} className='border border-gray-200 p-1 rounded'>
            <div className='bg-slate-100 p-1'>
              <div className='text-lg'>
                Record {i.component} in {i.uom}.
              </div>
              <div className='italic'>The Moa is {i.moa}</div>
              <div className='italic text-red-500'>
                Ideal Answer: {i.specification}
              </div>
            </div>

            <div className='pl-6'>
              <input
                type='text'
                className='block w-1/2 p-2 border border-gray-300 rounded-lg'
                value={ansVal}
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = p.surveyAns.find((x) => x.questionId === p.r.id);
                  if (sa) {
                    if (!sa.answerValue) {
                      (sa as any).answerValue = chemComp?.map((t) => ({
                        name: t.component,
                        expected: t.specification,
                        actual: '',
                      }));
                    }
                    (sa as any).answerValue[index].actual = newVal;
                    p.setSurveyAns([...p.surveyAns]);
                  }
                }}
              ></input>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function GCTest(p: QuestionRendererProps): JSX.Element {
  const [tests, setTests] = useState<Array<{ name: string; status: string }>>(
    []
  );
  const [chemicals, setChemicals] = useState<
    Array<{
      name: string;
      equalityExp: string;
      tests: Array<{
        name: string;
        uom: string;
        expected: string;
        actual: string;
        status: string;
      }>;
    }>
  >([]);

  useEffect(() => {
    if (p.mode === 'readonly' || p.mode === 'edit') {
      const ans = p.surveyAns?.find((x) => x.questionId === p.r.id)
        ?.answerValue;
      if (ans) {
        setChemicals(ans as any);
      }
    }
  }, [p.mode, p.surveyAns, p.r?.id]);

  useEffect(() => {
    if (
      p.dynamicData?.properties?.['chemical-composition-2']?.headerRows?.[0]
    ) {
      const row =
        p.dynamicData?.properties?.['chemical-composition-2']?.headerRows?.[0];
      const tests: Array<{ name: string; status: string }> = [];
      _.each(_.keys(row), (key) => {
        if (!['name-of-the-chem', 'equality-expression'].includes(key)) {
          tests.push({ name: key, status: row[key] });
        }
      });

      const allRows: Array<
        {
          'name-of-the-chem': string;
          'equality-expression': string;
        } & {
          [key: string]: { value: string; uom: { id: string; name: string } };
        }
      > = [
        ...p.dynamicData?.properties?.['chemical-composition-2']?.headerRows,
        ...p.dynamicData?.properties?.['chemical-composition-2']?.variableRows,
        ...p.dynamicData?.properties?.['chemical-composition-2']?.trailerRows,
      ];

      const chems: Array<{
        name: string;
        equalityExp: string;
        tests: Array<{
          name: string;
          uom: string;
          expected: string;
          actual: string;
          status: string;
        }>;
      }> = [];

      allRows.forEach((x) => {
        if (!['Perform Test', 'Total'].includes(x['name-of-the-chem'])) {
          const testValues: Array<{
            name: string;
            uom: string;
            expected: string;
            actual: string;
            status: string;
          }> = [];
          _.each(_.keys(x), (key) => {
            if (
              !['name-of-the-chem', 'equality-expression'].includes(key) &&
              tests.find((q) => q.name === key)
            ) {
              testValues.push({
                name: key,
                uom: x[key]?.uom?.name || '',
                expected: x[key]?.value || 'not-set',
                actual: '',
                status: 'not-determined',
              });
            }
          });
          chems.push({
            name: x['name-of-the-chem'],
            equalityExp: x['equality-expression'],
            tests: testValues,
          });
        }
      });
      setTests(tests);
      if (p.mode === 'edit') {
        setChemicals(chems);
      }
    }
    // eslint-disable-next-line
  }, []);

  if (!p.dynamicData?.properties?.['chemical-composition-2']) {
    return <div>Cannot find valid Chemical Composition Information</div>;
  }
  const Table = renderTableComponent();

  return (
    <div className='border border-gray-100 p-1'>
      {tests.map((x) => (
        <div className='border border-gray-100 my-1' key={x.name}>
          <div className='text-center font-semibold capitalize'>
            Perform {x.name} Test - {x.status}
          </div>
          {x.status === 'Yes' ? (
            <>
              <Table
                header={[
                  [
                    { name: 'Chemical' },
                    { name: 'Expected' },
                    { name: 'Actual' },
                    { name: 'Test Status' },
                  ],
                ]}
                body={chemicals
                  .filter((c) => c.tests.find((t) => t.name === x.name))
                  .map((c) => {
                    return {
                      cells: [
                        { value: c.name },
                        {
                          value: `${c.equalityExp} ${
                            c.tests.find((t) => x.name === t.name)?.expected
                          } ${c.tests.find((t) => x.name === t.name)?.uom}`,
                        },
                        {
                          value: (
                            <input
                              readOnly={p.mode === 'readonly'}
                              defaultValue={
                                c.tests.find((t) => x.name === t.name)?.actual
                              }
                              onBlur={(evt) => {
                                const newVal = parseFloat(evt.target.value);
                                const newChems = [...chemicals];
                                const chem = newChems.find(
                                  (nc) => c.name === nc.name
                                );
                                const testToUpdate = chem?.tests.find(
                                  (z) => z.name === x.name
                                );
                                if (chem && testToUpdate) {
                                  testToUpdate.actual = `${newVal}`;
                                  const expectedValue = parseFloat(
                                    testToUpdate.expected
                                  );
                                  if (chem.equalityExp === '<') {
                                    testToUpdate.status =
                                      newVal < expectedValue ? 'pass' : 'fail';
                                  } else if (chem.equalityExp === '<=') {
                                    testToUpdate.status =
                                      newVal <= expectedValue ? 'pass' : 'fail';
                                  } else if (chem.equalityExp === '>') {
                                    testToUpdate.status =
                                      newVal > expectedValue ? 'pass' : 'fail';
                                  } else if (chem.equalityExp === '>=') {
                                    testToUpdate.status =
                                      newVal >= expectedValue ? 'pass' : 'fail';
                                  } else {
                                    testToUpdate.status =
                                      newVal === expectedValue
                                        ? 'pass'
                                        : 'fail';
                                  }

                                  setChemicals(newChems);
                                }
                              }}
                            />
                          ),
                        },
                        {
                          value: `${
                            c.tests.find((t) => x.name === t.name)?.status
                          }`,
                        },
                      ],
                    };
                  })}
              />
            </>
          ) : null}
        </div>
      ))}
      {p.mode === 'edit' ? (
        <div className='text-right'>
          <button
            onClick={() => {
              const sa = p.surveyAns.find((x) => x.questionId === p.r.id);
              if (sa) {
                if (!sa.answerValue) {
                  (sa as any).answerValue = chemicals;
                }
                p.setSurveyAns([...p.surveyAns]);
              }
            }}
            className='bg-green-500 text-white p-1'
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  );
}
