import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCurrentCompanyGroup, useCurrentUser } from '../../../config/admin';
import { UseCombinedAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { GradeInterface } from '../../../models/interfaces/inventory/grade';
import { createChangeRequest } from '../../../modules/inventory/properties/change-request';
import { ValueRenderer } from '../../../modules/inventory/properties/value-renderer/value-renderer';

export function renderClientGradesDetails({
  useCombinedClient,
  useCombinedGrade,
  gradeService,
  useFileTransfer,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedGST,
  useCombinedMetric,
  useCombinedOdour,
  useCombinedEmployeeProfile,
  useCombinedHazardGhsClass,
  useCombinedClientItem,
}: {
  useCombinedClient: UseCombinedClient;
  useCombinedGrade: UseCombinedGrade;
  gradeService: GradeInterface;
  useFileTransfer: UseFileTransfer;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedColor: UseCombinedColor;
  useCombinedGST: UseCombinedGST;
  useCombinedMetric: UseCombinedMetric;
  useCombinedOdour: UseCombinedOdour;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
  useCombinedClientItem: UseCombinedClientItem;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function ClientGradeDetails() {
    const [gradeProperties, setGradeProperties] = useState<ItemPropertyValue[]>(
      []
    );
    const [clientItemId, setClientItemId] = useState<string>('');
    const [clientItemProperties, setClientItemProperties] = useState<
      ItemPropertyValue[]
    >([]);
    const { id, gradeId } = useParams();
    const { resource: client, get: getClient } = useCombinedClient();
    const { resource: grade, get: getGrade } = useCombinedGrade();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const { data: currentUser } = useCurrentUser();
    const { syncSet: setClientItem } = useCombinedClientItem();

    const ChangeRequest = createChangeRequest({
      useCombinedAppearance,
      useCombinedColor,
      useCombinedGST,
      useCombinedMetric,
      useCombinedOdour,
      useCombinedEmployeeProfile,
      useFileTransfer,
      useCombinedGrade,
      useCombinedHazardGhsClass,
    });

    useEffect(() => {
      if (id) {
        getClient(id);
      }
      // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      if (gradeId) {
        getGrade(gradeId);
        gradeService.getProperties(gradeId).then((c) => {
          console.log(c);
          setGradeProperties(c);
        });
      }
      // eslint-disable-next-line
    }, [gradeId]);

    useEffect(() => {
      if (gradeId && client) {
        const targettedGrade = client?.items?.find((itm) =>
          itm.details?.grades?.find((g) => g.id === gradeId)
        );
        console.log(targettedGrade);
        if (targettedGrade) {
          setClientItemId(targettedGrade.id);
        }
        console.log(client);
        if (
          targettedGrade &&
          targettedGrade.details?.gradeSpecifications?.[gradeId]?.propertyValues
        ) {
          console.log(
            'ye set hoga',
            targettedGrade.details?.gradeSpecifications?.[gradeId]
              ?.propertyValues
          );
          setClientItemProperties(
            targettedGrade.details?.gradeSpecifications?.[gradeId]
              ?.propertyValues || []
          );
        }
      }
    }, [client, gradeId]);

    return (
      <div>
        <Card
          header={{
            title: `Grade Specifications: ${grade?.name}`,
            subheading: <>{client?.name || ''}</>,
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={[
                    [
                      {
                        name: 'Property',
                        style: 'w-1/5',
                      },
                      {
                        name: 'Client Value',
                        style: 'w-2/5',
                      },
                      {
                        name: 'Internal Value',
                        style: 'w-2/5',
                      },
                    ],
                  ]}
                  body={gradeProperties.map((p) => {
                    const clientProperty = clientItemProperties.find(
                      (x) => x.name === p.name
                    );
                    const currentValue: ItemPropertyValue = JSON.parse(
                      JSON.stringify(p)
                    );
                    if (clientProperty?.value) {
                      currentValue.value = clientProperty.value;
                    }
                    return {
                      cells: [
                        { value: p.displayName || p.name, style: 'capitalize' },
                        {
                          value: clientProperty?.value ? (
                            <ValueRenderer
                              x={currentValue}
                              parentValues={[]} // TODO: This may require values
                              useFileTransfer={useFileTransfer}
                            />
                          ) : (
                            <></>
                          ),
                          button: {
                            show: () => true,
                            icon: ({ className, onClick }) => (
                              <PencilIcon
                                className={className}
                                onClick={onClick}
                              />
                            ),
                            style:
                              'inline ml-2 w-5 h-5 text-gray-400 cursor-pointer',
                            behaviour: 'modal',
                            modal: {
                              size: 'large',
                              title: 'Edit Property',
                              content: ({ onClose }) => {
                                return (
                                  <ChangeRequest
                                    currentCompanyGroup={currentCompanyGroup}
                                    propType={
                                      (currentValue?.type as string) || ''
                                    }
                                    value={currentValue.value}
                                    property={currentValue.name}
                                    itemProperty={currentValue}
                                    entityId={currentValue.name}
                                    currentUser={currentUser}
                                    setRevision={async (data) => {
                                      const finalValue = {
                                        name:
                                          data.changeRequest?.currentValue
                                            ?.name,
                                        value:
                                          data.changeRequest?.newValue?.value,
                                      };
                                      console.log('final', finalValue);

                                      const newItemProps = [
                                        ...clientItemProperties,
                                      ];
                                      let valueInItemProps = newItemProps.find(
                                        (x) => x.name === p.name
                                      );
                                      if (!valueInItemProps) {
                                        newItemProps.push(finalValue as any);
                                      } else {
                                        valueInItemProps.value =
                                          finalValue.value;
                                      }

                                      const targettedGrade = client?.items?.find(
                                        (itm) =>
                                          itm.details?.grades?.find(
                                            (g) => g.id === gradeId
                                          )
                                      );

                                      console.log(
                                        clientItemId &&
                                          gradeId &&
                                          targettedGrade
                                      );
                                      if (
                                        clientItemId &&
                                        gradeId &&
                                        targettedGrade
                                      ) {
                                        const gradeSpecs: any =
                                          targettedGrade.details
                                            ?.gradeSpecifications || {};
                                        gradeSpecs[gradeId] = {
                                          propertyValues: newItemProps,
                                        };

                                        setClientItemProperties(
                                          targettedGrade.details
                                            ?.gradeSpecifications?.[gradeId]
                                            ?.propertyValues || []
                                        );
                                        await setClientItem({
                                          id: clientItemId,
                                          details: {
                                            gradeSpecifications: gradeSpecs,
                                          },
                                        } as any);
                                        if (id) {
                                          await getClient(id);
                                        }
                                      }

                                      if (clientItemId) {
                                      }
                                    }}
                                    type='grade'
                                    onClose={onClose}
                                    propOptions={[]} // TODO
                                    currentValue={currentValue}
                                    searchSelectOptions={
                                      (currentValue?.searchSelectOptions as string) ||
                                      ''
                                    }
                                  />
                                  // <ItemPropertyValueRender
                                  //   properties={properties}
                                  //   parentValues={parentValues}
                                  //   currentCompanyGroup={currentCompanyGroup}
                                  //   currentValue={x}
                                  //   previousValue={previousValue}
                                  //   changeRequestValue={revisionX}
                                  //   currentUser={currentUser}
                                  //   setRevision={setRevision}
                                  //   onClose={onClose}
                                  //   type={type}
                                  //   entityId={entityId}
                                  // />
                                );
                              },
                              onClose: () => {
                                console.log('Closed modal');
                              },
                            },
                          },
                        },
                        {
                          value: (
                            <ValueRenderer
                              x={p}
                              parentValues={[]} // TODO: This may require values
                              useFileTransfer={useFileTransfer}
                            />
                          ),
                        },
                      ],
                    };
                  })}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
