/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { isExpired } from 'react-jwt';

import { UserLoginRequest } from '@erp_core/erp-types/dist/modules/admin';
import { Buffer } from 'buffer';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import {
  LoginInterface,
  LoginStatus,
} from '../../../../models/interfaces/login';
import {
  getRefreshToken,
  getUserToken,
  resetUserToken,
  setUserToken,
} from '../../../../utils/token-helper';

export class HttpLoginRepo implements LoginInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
    this.checkLoginStatus();
  }

  async login(l: UserLoginRequest): Promise<void> {
    const httpInstance = await this.erpv2Api.getPlainInstance({
      apiUrl: this.apiUrl,
    });

    const payload = {
      email: l.email,
      password: Buffer.from(l.password).toString('base64'),
      domain: l.domain,
    };

    const res = await httpInstance.post('/api/v1/login', payload);

    if (res.status === 200) {
      const { data } = res;

      setUserToken(data.token, data.refreshToken);
      return;
    }

    throw new Error('login failed');
  }

  async refreshToken(): Promise<LoginStatus> {
    const token = getRefreshToken();
    if (token) {
      if (isExpired(token)) {
        return 'login-failed';
      }

      // const httpInstance = await this.erpv2Api.getPlainInstance({
      //   apiUrl: this.apiUrl,
      // });
      // const payload = {
      //   refreshToken: token,
      //   email: decodeToken<UserToken>(token)?.user.email,
      //   companyGroupId: decodeToken<UserToken>(token)?.companyGroup.id,
      // };
      // const res = await httpInstance.post(
      //   '/api/v1/login/refresh-token',
      //   payload
      // );

      const { token: newToken } = await this.erpv2Api.getRefreshToken();

      if (newToken) {
        // const { data } = res;

        // setUserToken(data.token, data.refreshToken);
        return 'login-success';
      }

      resetUserToken();
    }

    return 'login-failed';
  }

  checkLoginStatus(): LoginStatus {
    const token = getUserToken();
    if (token) {
      return isExpired(token) ? 'login-expired' : 'login-success';
    }

    return 'before-login';
  }
}
