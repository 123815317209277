import { ProductBatch } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCombinedQuestionnaireQuestion } from '../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedProductBatch } from '../../../hooks/order/sales/product-batch/use-product-batch';
import { GradeInterface } from '../../../models/interfaces/inventory/grade';
import {
  renderSurveyAnswerForm,
  SurveyAnswerType,
} from '../../../modules/miscellaneous/questionnaire/survey-answers/form';

export function renderProductBatchRegister({
  useCombinedGrade,
  useCombinedQuestionnaireQuestion,
  useCombinedProductBatch,
  gradeService,
}: {
  useCombinedGrade: UseCombinedGrade;
  useCombinedProductBatch: UseCombinedProductBatch;
  gradeService: GradeInterface;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
}) {
  const Table = renderTableComponent();
  const Card = renderCardComponent();
  return function ProductBatchRegister() {
    const {
      getList: getProductBatches,
      list: productBatches,
      syncSet: setProductBatch,
    } = useCombinedProductBatch();
    useEffect(() => {
      getProductBatches();
      // eslint-disable-next-line
    }, []);
    return (
      <div>
        <Card
          header={{
            title: 'Product Batch Register',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add new Batch',
                    content: ({ onClose }) => {
                      const Form = renderFormV2({
                        initialFormState: {
                          grade: { id: '', name: '' },
                          batch: '',
                          date: '',
                          quantity: 0,
                          tareWeight: 0,
                          grossWeight: 0,
                        },
                        fieldsData: [
                          {
                            property: 'grade',
                            type: 'searchable-select',
                            required: true,
                            searchOptions: {
                              useSearch: useCombinedGrade,
                              selectTarget: (form) => {
                                return {
                                  id: form.id,
                                  name: form.name,
                                  item: {
                                    id: form.item.id,
                                    name: form.item.name,
                                  },
                                };
                              },
                              onSearchValueSelect: (u: any) => {},
                              // filter?: any;
                              searchOptionsBody: {
                                customBody: (data: Grade) => {
                                  return (
                                    <div>
                                      <div className='font-bold'>
                                        {data.name}
                                      </div>
                                      <div>{data.item?.name}</div>
                                    </div>
                                  );
                                },
                              },
                            },
                          },
                          {
                            property: 'batch',
                            required: true,
                            type: 'input',
                          },
                          {
                            property: 'quantity',
                            required: true,
                            type: 'number',
                          },
                          {
                            property: 'date',
                            required: true,
                            type: 'date',
                          },
                          {
                            property: 'grossWeight',
                            required: true,
                            type: 'number',
                          },
                          {
                            property: 'tareWeight',
                            required: true,
                            type: 'number',
                          },
                        ],
                        style: 'w-1/3',
                        mapTToU: (x) => x,
                        onSubmit: async (form) => {
                          const pb: ProductBatch = {
                            name: (form.grade as IdName)?.name,
                            grade: form.grade as any,
                            status: 'batch-entered',
                            details: {
                              batch: form.batch,
                              quantity: form.quantity,
                              date: form.date,
                              grossWeight: form.grossWeight,
                              tareWeight: form.tareWeight,
                            },
                          } as any;
                          await setProductBatch(pb);
                          onClose();
                        },
                      });

                      return (
                        <div>
                          <Form />
                        </div>
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <Table
                header={[
                  [
                    {
                      name: 'Grade',
                    },
                    {
                      name: 'Batch #',
                    },
                    {
                      name: 'Date',
                    },
                    {
                      name: 'Quantity (Net weight)',
                    },
                    {
                      name: 'Tare Weight',
                    },
                    {
                      name: 'Gross Weight',
                    },
                    {
                      name: 'Status',
                    },
                  ],
                ]}
                body={(productBatches || []).map((pb) => {
                  return {
                    cells: [
                      { value: pb.grade.name },
                      { value: (pb.details as any)?.batch },
                      { value: (pb.details as any)?.date },
                      { value: (pb?.details as any)?.quantity },
                      { value: (pb?.details as any)?.tareWeight },
                      { value: (pb?.details as any)?.grossWeight },
                      { value: pb.status },
                    ],
                    rowData: {
                      grade: pb.grade,
                      productBatch: pb,
                    },
                  };
                })}
                actions={[
                  {
                    name: 'COA Test',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'batch-entered',
                    modal: {
                      title: 'COA Test',
                      content: ({ data: { grade, productBatch }, onClose }) => {
                        const SurveyForm = renderInspectionForm({
                          mode: 'edit',
                          answers: [],
                          gradeService,
                          useCombinedQuestionnaireQuestion,
                        });
                        return (
                          <div>
                            <SurveyForm
                              grade={grade}
                              onClose={onClose}
                              save={async (survey: any) => {
                                if (survey) {
                                  await setProductBatch({
                                    id: productBatch.id,
                                    status: 'coa-tested',
                                    details: {
                                      coaAnswers: survey,
                                    },
                                  } as any);
                                }
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Edit COA',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'coa-tested',
                    modal: {
                      title: 'Edit COA',
                      content: ({ data: { grade, productBatch }, onClose }) => {
                        const SurveyForm = renderInspectionForm({
                          mode: 'edit',
                          answers:
                            productBatch.details?.coaAnswers?.details
                              ?.surveyAns,
                          gradeService,
                          useCombinedQuestionnaireQuestion,
                        });

                        return (
                          <div>
                            <SurveyForm
                              grade={grade}
                              onClose={onClose}
                              save={async (survey: any) => {
                                if (survey) {
                                  await setProductBatch({
                                    id: productBatch.id,
                                    status: 'coa-tested',
                                    details: {
                                      coaAnswers: survey,
                                    },
                                  } as any);
                                }
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'View COA',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      [
                        'coa-tested',
                        'coa-accepted',
                        'batch-released',
                        'allocated-to-sales-order',
                        'raised-packing-request',
                        'material-packed',
                        'dispatch-release-qa-check',
                        'dispatch-qa-checked',
                        'ready-for-dispatch',
                        'dispatched',
                        'material-confirmed',
                      ].includes(productBatch.status),
                    behaviour: 'modal',
                    modal: {
                      title: 'View COA Test Result',
                      content: ({ data: { productBatch, grade }, onClose }) => {
                        const SurveyForm = renderInspectionForm({
                          mode: 'readonly',
                          answers:
                            productBatch.details?.coaAnswers?.details
                              ?.surveyAns,
                          gradeService,
                          useCombinedQuestionnaireQuestion,
                        });
                        return (
                          <>
                            <SurveyForm
                              grade={grade}
                              onClose={() => {
                                onClose();
                              }}
                              save={async () => {}}
                            />
                          </>
                        );
                      },
                    },
                  },
                  {
                    name: 'COA Approval',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'coa-tested',
                    behaviour: 'modal',
                    modal: {
                      title: 'Approve COA',
                      content: ({ data: { productBatch }, onClose }) => {
                        const Form = renderFormV2({
                          initialFormState: {
                            date: moment().format('YYYY-MM-DD'),
                          },
                          fieldsData: [
                            {
                              property: 'date',
                              required: true,
                              label: 'Coa Approval Date',
                              type: 'date',
                            },
                          ],
                          mapTToU: (u) => u,
                          onSubmit: async (form) => {
                            await setProductBatch({
                              id: productBatch.id,
                              status: 'coa-accepted',
                              details: {
                                coaApprovalDate:
                                  form.date || moment().format('YYYY-MM-DD'),
                              },
                            } as any);
                          },
                        });
                        return (
                          <div>
                            <Form />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Batch Release',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      (productBatch.status as any) === 'coa-accepted',
                    behaviour: 'modal',
                    modal: {
                      title: 'Batch Release',
                      content: ({ data: { productBatch }, onClose }) => {
                        const Form = renderFormV2({
                          initialFormState: {
                            date: moment().format('YYYY-MM-DD'),
                          },
                          fieldsData: [
                            {
                              property: 'date',
                              required: true,
                              label: 'Backtracing Document Completion Date',
                              type: 'date',
                            },
                          ],
                          mapTToU: (u) => u,
                          onSubmit: async (form) => {
                            await setProductBatch({
                              id: productBatch.id,
                              status: 'batch-released',
                              details: {
                                backtracingDocumentCompletionDate:
                                  form.date || moment().format('YYYY-MM-DD'),
                              },
                            } as any);
                          },
                        });
                        return (
                          <div>
                            <Form />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Allocate to Sales Order',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'batch-released',
                    modal: {
                      title: 'Allocate to Sales Order',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Form to select a Sales Order
                            <LoadingButton
                              text={'Allocate'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'allocated-to-sales-order',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Raise Packing Request',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'allocated-to-sales-order',
                    modal: {
                      title: 'Raise Packing RCOA Testequest',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Form to Raise Packing Request
                            <LoadingButton
                              text={'Raise Packing Request'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'raised-packing-request',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Mark as Packed',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'raised-packing-request',
                    modal: {
                      title: 'Mark as Packed',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Mark as Packed
                            <LoadingButton
                              text={'Mark as Packed'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'raised-packing-request',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'QA Check for Dispatch Release',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'material-packed',
                    modal: {
                      title: 'QA Approval Check',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            QA check for Dispatch Release
                            <LoadingButton
                              text={'QA Check for Dispatch Release'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'dispatch-release-qa-check',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Dispatch QA Checked',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'dispatch-release-qa-check',
                    modal: {
                      title: 'QA Approval Dispatch Check',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Mark as Packed
                            <LoadingButton
                              text={'Dispatch QA Checked'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'dispatch-qa-checked',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Ready for Dispatch',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'dispatch-qa-checked',
                    modal: {
                      title: 'Ready for Dispatch',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Ready for dispatch
                            <LoadingButton
                              text={'Ready for Dispatch'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'ready-for-dispatch',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Dispatch',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'ready-for-dispatch',
                    modal: {
                      title: 'Dispatch',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Form for Dispatch
                            <LoadingButton
                              text={'Dispatch'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'dispatched',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                  {
                    name: 'Material Confirmed',
                    behaviour: 'modal',
                    show: ({ productBatch }: { productBatch: ProductBatch }) =>
                      productBatch.status === 'dispatched',
                    modal: {
                      title: 'Material Confirmed',
                      content: ({ data: { productBatch }, onClose }) => {
                        return (
                          <div>
                            Form for Material Confirmed
                            <LoadingButton
                              text={'Material Confirmed'}
                              behaviorFn={async () => {
                                await setProductBatch({
                                  id: productBatch.id,
                                  status: 'material-confirmed',
                                } as any);
                              }}
                            />
                          </div>
                        );
                      },
                    },
                  },
                ]}
              />
            ),
          }}
        />
      </div>
    );
  };
}

export function renderInspectionForm({
  mode,
  answers,
  gradeService,
  useCombinedQuestionnaireQuestion,
}: {
  mode: 'edit' | 'readonly';
  answers: SurveyAnswerType;
  gradeService: GradeInterface;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
}): (p: {
  grade: IdName;
  onClose: () => void;
  save: (survey: any) => Promise<void>;
}) => JSX.Element {
  const SurveyForm = renderSurveyAnswerForm({
    useCombinedQuestionnaireQuestion,
  });
  return function InspectionForm({
    grade,
    onClose,
    save,
  }: {
    grade: IdName;
    onClose: () => void;
    save: (survey: any) => Promise<void>;
  }): JSX.Element {
    const [properties, setProperties] = useState<any>({});
    useEffect(() => {
      if (grade.id) {
        gradeService.getProperties(grade.id).then((res) => {
          const props: any = {};
          for (const p of res) {
            props[p.name] = p.value?.data;
          }
          setProperties(props);
        });
      }
      // eslint-disable-next-line
    }, []);

    if (properties['coa-questions']) {
      const topicId = properties['coa-questions'];
      if (topicId) {
        return (
          <SurveyForm
            answers={answers}
            mode={mode}
            id={topicId}
            dynamicData={{
              grade,
              properties,
            }}
            onSave={async (p) => {
              await save(p);
              onClose();
            }}
          />
        );
      }

      return <div>Unable to find COA Questions</div>;
    }

    return <div>Loading..</div>;
  };
}
