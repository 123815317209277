/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import { renderEnquiryInfoForm } from './enquiry-info';
import { renderOfferDetailsForm } from './offer-details';
import { renderOrderAcceptanceForm } from './order-acceptance';

export function renderdSalesDetails({
  useCombinedClient,
  useCombinedGrade,
  useCombinedSalesOrders,
  // useCombinedSalesOrdersItems
}): () => JSX.Element {
  return function SalesDetails(): JSX.Element {
    // eslint-disable-next-line
    const { id } = useParams();

    const Enquiry = renderEnquiryInfoForm({
      useCombinedClient,
      useCombinedGrade,
      // useCombinedSalesOrders,
      // useCombinedSalesOrdersItems
    });

    const Offer = renderOfferDetailsForm({ useCombinedGrade });

    const OrderAcceptance = renderOrderAcceptanceForm({ useCombinedGrade });

    return (
      <>
        {' '}
        <div>
          <Enquiry />
        </div>
        <br />
        <div>
          <Offer />
        </div>
        <br />
        <div>
          <OrderAcceptance />
        </div>
        <br />
      </>
    );

    //   const header: CardHeader = {
    //     title: (
    //       <div className='flex'>
    //       </div>
    //     ) as any,

    //   };

    //   const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   ['Enquiry',Enquiry],
    //   ['Offer',Offer],
    //   ['Order Acceptance', OrderAcceptance]
    // ]);

    //   const body: CardBody = {
    //     type: 'jsx-component',
    //     body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    //   };

    //   return <Card header={header} body={body} />;
    // };
  };
}
