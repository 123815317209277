import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { eval as expEval, parse } from 'expression-eval';
import _ from 'lodash';

export function renderFormula(
  item: ItemPropertyValue,
  formula,
  parentValues: ItemPropertyValue[]
): string {
  // console.log(formula, parentValues);
  if (!formula) {
    return '';
  }

  if (formula) {
    const parentProps: any = {};
    let updatedFormula = formula;
    parentValues.forEach((x) => {
      const camelCased = _.camelCase(x.name);
      if (camelCased !== x.name) {
        updatedFormula = _.replace(
          updatedFormula,
          new RegExp(x.name, 'g'),
          camelCased
        );
      }

      // console.log('updated formula', updatedFormula);
      let valueToSave = x.value?.data || undefined;
      if (valueToSave && typeof valueToSave === 'string') {
        try {
          const intVal = parseFloat(valueToSave);
          if (intVal) {
            valueToSave = intVal;
          }
        } catch (e) {}
      }
      parentProps[camelCased] = valueToSave;

      if (x.childrenProperties) {
        _.each(x.childrenProperties, (child, key) => {
          const childCamelCased = _.camelCase(key);
          if (childCamelCased !== key) {
            updatedFormula = _.replace(
              updatedFormula,
              new RegExp(`${camelCased}.${key}`, 'g'),
              `${camelCased}.${childCamelCased}`
            );
          }

          updatedFormula = _.replace(
            updatedFormula,
            new RegExp(`${camelCased}.${childCamelCased}`, 'g'),
            `${camelCased}_${childCamelCased}`
          );

          if (child.type !== 'formula') {
            // console.log(
            //   'updated formula',
            //   updatedFormula,
            //   x.name,
            //   key,
            //   x.value?.data
            // );
            let valueToSave = (x.value?.data && x.value.data[key]) || undefined;
            if (valueToSave && typeof valueToSave === 'string') {
              try {
                const intVal = parseFloat(valueToSave);
                if (intVal) {
                  valueToSave = intVal;
                }
              } catch (e) {}
            }
            parentProps[`${camelCased}_${childCamelCased}`] = valueToSave; //  `value of ${camelCased}_${childCamelCased}`; //x.value?.data || undefined;
          }
        });
      }
    });

    try {
      const expression = parse(updatedFormula);
      // console.log(item.name, updatedFormula, parentProps);
      const response = expEval(expression, parentProps);
      // console.log(response);
      if (['string', 'number'].includes(typeof response)) {
        if (response) {
          return response;
        }
      }

      return 'Invalid-formula';
    } catch (e) {
      console.log(e);
      return 'Invalid-formula';
    }
  }

  return '';
}

export function renderFormulaInSimpleArray(
  formula,
  prop: string,
  x: ItemPropertyValue,
  idx: number
): string {
  const dataObject = x.value?.data && x.value.data[idx];

  if (!dataObject) {
    return '';
  }

  const sanitizedObject: any = {};
  _.keys(dataObject).forEach((key) => {
    let valueToSave = dataObject[key] || undefined;
    if (valueToSave && typeof valueToSave === 'string') {
      try {
        const intVal = parseFloat(valueToSave);
        if (intVal) {
          valueToSave = intVal;
        }
      } catch (e) {}
    }

    sanitizedObject[key] = valueToSave;
  });

  try {
    const expression = parse(formula);
    const response = expEval(expression, sanitizedObject);
    if (response) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }

  return '';
}

export function renderHeaderTrailerNestedInComplexArray(
  formula: string,
  prop: string,
  mainType: 'header' | 'trailer',
  index: number,
  x: ItemPropertyValue
) {
  if (!formula) {
    return '';
  }

  const dataObject = x.value?.data || {};

  // console.log(dataObject);

  const sanitizedObject: any = {
    header: {},
    variable: {},
    trailer: {},
  };

  let sanitizedFormula = formula;

  dataObject?.headerRows.forEach((row, idx) => {
    _.keys(row).forEach((key) => {
      const data = row[key];
      if (typeof data === 'object') {
        // this means it further has keys
        _.keys(data).forEach((subKey) => {
          // we dont go beyond this..
          let subData = data[subKey] || undefined;
          if (subData && typeof subData === 'string') {
            try {
              const intVal = parseFloat(parseFloat(subData).toFixed(2));
              if (intVal) {
                subData = intVal;
              }
            } catch (e) {}
          }

          sanitizedObject[
            `header_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
          ] = subData;
          // console.log(
          //   'sanitized',
          //   sanitizedFormula,
          //   `header[${idx}].${key}.${subKey}`
          // );
          if (sanitizedFormula.includes(`header[${idx}].${key}.${subKey}`)) {
            sanitizedFormula = _.replace(
              sanitizedFormula,
              new RegExp(`header\\[${idx}\\]\\.${key}\\.${subKey}`, 'g'),
              `header_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
            );
          }
        });
      } else {
        let finalData = data || undefined;
        if (finalData && typeof finalData === 'string') {
          try {
            const intVal = parseFloat(parseFloat(finalData).toFixed(2));
            if (intVal) {
              finalData = intVal;
            }
          } catch (e) {}
        }

        sanitizedObject[`header_${idx}_${_.camelCase(key)}`] = finalData;
        if (sanitizedFormula.includes(`header[${idx}].${key}`)) {
          sanitizedFormula = _.replace(
            sanitizedFormula,
            new RegExp(`header[${idx}].${key}`, 'g'),
            `header_${idx}_${_.camelCase(key)}`
          );
        }
      }
    });
  });

  dataObject?.variableRows.forEach((row, idx) => {
    _.keys(row).forEach((key) => {
      const data = row[key];
      if (typeof data === 'object') {
        // this means it further has keys
        _.keys(data).forEach((subKey) => {
          // we dont go beyond this..
          let subData = data[subKey] || undefined;
          if (subData && typeof subData === 'string') {
            try {
              const intVal = parseFloat(parseFloat(subData).toFixed(2));
              if (intVal) {
                subData = intVal;
              }
            } catch (e) {}
          }

          sanitizedObject[
            `variable_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
          ] = subData;
          if (sanitizedFormula.includes(`variable[${idx}].${key}.${subKey}`)) {
            sanitizedFormula = _.replace(
              sanitizedFormula,
              new RegExp(`variable[${idx}].${key}.${subKey}`, 'g'),
              `variable_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
            );
          }

          if (idx === 0) {
            // For every variable property there needs to be SUM formula
            // This is executed only once
            let val = '';
            if (sanitizedFormula.includes(`SUM(${key}.${subKey})`)) {
              // console.log(
              //   'search for ',
              //   `SUM(${key}.${subKey})`,
              //   sanitizedFormula
              // );
              _.times(dataObject?.variableRows.length, (timesIdx) => {
                val = val
                  ? `${val} + variable_${timesIdx}_${_.camelCase(
                      key
                    )}_${_.camelCase(subKey)}`
                  : `variable_${timesIdx}_${_.camelCase(key)}_${_.camelCase(
                      subKey
                    )}`;
              });
              sanitizedFormula = _.replace(
                sanitizedFormula,
                `SUM(${key}.${subKey})`,
                val
              );
            }
          }
        });
      } else {
        let finalData = data || undefined;
        if (finalData && typeof finalData === 'string') {
          try {
            const intVal = parseFloat(parseFloat(finalData).toFixed(2));
            if (intVal) {
              finalData = intVal;
            }
          } catch (e) {}
        }

        sanitizedObject[`variable_${idx}_${_.camelCase(key)}`] = finalData;
        if (sanitizedFormula.includes(`variable[${idx}].${key}`)) {
          sanitizedFormula = _.replace(
            sanitizedFormula,
            new RegExp(`variable[${idx}].${key}`, 'g'),
            `variable_${idx}_${_.camelCase(key)}`
          );
        }

        if (idx === 0) {
          // For every variable property there needs to be SUM formula
          // This is executed only once
          let val = '';
          if (sanitizedFormula.includes(`SUM(${key})`)) {
            // console.log('search for ', `SUM(${key})`, sanitizedFormula);
            _.times(dataObject?.variableRows.length, (timesIdx) => {
              val = val
                ? `${val} + variable_${timesIdx}_${_.camelCase(key)}}`
                : `variable_${timesIdx}_${_.camelCase(key)}}`;
            });
            sanitizedFormula = _.replace(sanitizedFormula, `SUM(${key})`, val);
          }
        }
      }
    });
  });

  dataObject?.trailerRows.forEach((row, idx) => {
    _.keys(row).forEach((key) => {
      const data = row[key];
      if (typeof data === 'object') {
        // this means it further has keys
        _.keys(data).forEach((subKey) => {
          // we dont go beyond this..

          let subData = data[subKey] || undefined;
          if (subData && typeof subData === 'string') {
            try {
              const intVal = parseFloat(parseFloat(subData).toFixed(2));
              if (intVal) {
                subData = intVal;
              }
            } catch (e) {}
          }

          sanitizedObject[
            `trailer_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
          ] = subData;
          if (sanitizedFormula.includes(`trailer[${idx}].${key}.${subKey}`)) {
            sanitizedFormula = _.replace(
              sanitizedFormula,
              new RegExp(`trailer[${idx}].${key}.${subKey}`, 'g'),
              `trailer_${idx}_${_.camelCase(key)}_${_.camelCase(subKey)}`
            );
          }
        });
      } else {
        let finalData = data || undefined;
        if (finalData && typeof finalData === 'string') {
          try {
            const intVal = parseFloat(parseFloat(finalData).toFixed(2));
            if (intVal) {
              finalData = intVal;
            }
          } catch (e) {}
        }

        sanitizedObject[`trailer_${idx}_${_.camelCase(key)}`] = finalData;
        if (sanitizedFormula.includes(`trailer[${idx}].${key}`)) {
          sanitizedFormula = _.replace(
            sanitizedFormula,
            new RegExp(`trailer[${idx}].${key}`, 'g'),
            `trailer_${idx}_${_.camelCase(key)}`
          );
        }
      }
    });
  });

  // console.log(sanitizedObject);
  // console.log('sanitized formula', sanitizedFormula);

  try {
    const expression = parse(sanitizedFormula);
    let response = expEval(expression, sanitizedObject);
    if (response) {
      if (typeof response == 'number') {
        const floatValue = parseFloat(parseFloat(`${response}`).toFixed(2));
        if (floatValue) {
          response = floatValue;
        }
      }

      return response;
    }
  } catch (e) {
    console.log(e);
  }

  return 'nht' + prop + ':' + formula;
}

export function renderVariableNestedInComplexArray(
  formula: string,
  prop: string,
  index: number,
  x: ItemPropertyValue
) {
  if (!formula) {
    return '';
  }

  if (!x.value?.data?.variableRows) {
    return '';
  }

  if (!x.value?.data?.variableRows[index]) {
    return '';
  }
  const dataObject = x.value?.data?.variableRows[index];

  // console.log(dataObject[index]);

  let sanitizedFormula = formula;
  let sanitizedObject: any = {};

  _.keys(dataObject).forEach((key) => {
    let valueToSave = dataObject[key] || undefined;
    if (valueToSave && typeof valueToSave === 'string') {
      try {
        const intVal = parseFloat(valueToSave);
        if (intVal) {
          valueToSave = intVal;
        }
      } catch (e) {}
      sanitizedObject[_.camelCase(key)] = valueToSave;
      sanitizedFormula = _.replace(
        sanitizedFormula,
        new RegExp(key, 'g'),
        _.camelCase(key)
      );
    } else if (valueToSave && typeof valueToSave === 'object') {
      _.keys(valueToSave).forEach((subKey) => {
        let subValueToSave = valueToSave[subKey] || undefined;
        if (subValueToSave && typeof subValueToSave === 'string') {
          try {
            const intVal = parseFloat(subValueToSave);
            if (intVal) {
              subValueToSave = intVal;
            }
          } catch (e) {}
        }
        sanitizedFormula = _.replace(
          sanitizedFormula,
          new RegExp(key, 'g'),
          _.camelCase(key)
        );

        // console.log(
        //   'replacing ',
        //   `${_.camelCase(key)}.${subKey}`,
        //   `${_.camelCase(key)}_${_.camelCase(subKey)}`
        // );
        sanitizedFormula = _.replace(
          sanitizedFormula,
          new RegExp(`${_.camelCase(key)}.${subKey}`, 'g'),
          `${_.camelCase(key)}_${_.camelCase(subKey)}`
        );
        sanitizedObject[
          `${_.camelCase(key)}_${_.camelCase(subKey)}`
        ] = subValueToSave;
      });
    } else {
      sanitizedObject[_.camelCase(key)] = valueToSave;
      sanitizedFormula = _.replace(
        sanitizedFormula,
        new RegExp(key, 'g'),
        _.camelCase(key)
      );
    }
  });

  // console.log(sanitizedFormula, sanitizedObject);

  try {
    const expression = parse(sanitizedFormula);
    const response = expEval(expression, sanitizedObject);
    if (response) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }

  return 'nv' + prop + ':' + formula;
}
