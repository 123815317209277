import { Recat } from '@erp_core/erp-icons/icons/recat';
import {
  AttendanceType,
  CombinedAttDataType,
  LeaveAppType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  Congratulations,
  DateSelector,
  downloadFile,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { FingerPrintIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseCombinedGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseCombinedOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import { UseCombinedOffSiteWorkHour } from '../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import { UseCombinedRotationalShiftDay } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseCombinedShiftSchedule } from '../../../../hooks/planning/shift-schedule/use-shift-schedule';
import { AttendanceInterface } from '../../../../models/interfaces/hrd/attendance';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../../models/interfaces/planning/log-record';
import { UserRendererInterface } from '../../../common/fragments/user';
import { saveNewOvertime } from '../util/add-overtime';
import { renderBulkShiftChange } from '../util/bulk-shift-change';
import { computePayableDayRatio } from '../util/payable-day';
import { renderActions } from './components/table/table-actions';
import { renderAttendanceBody } from './components/table/table-body';
import { Header } from './components/table/table-header';

export function createNewAttendancePage({
  attendanceService,
  useCombinedAttendance,
  useCurrentUserRoles,
  useCombinedLeave,
  useCombinedEmployeeProfile,
  useCombinedGatePass,
  useCombinedOvertime,
  useCombinedOffSiteWorkHour,
  useCombinedShiftSchedule,
  leaveService,
  logRecordService,
  useCombinedRotationalShiftDay,
  userRendererService,
}: {
  attendanceService: AttendanceInterface;
  useCombinedAttendance: UseCombinedAttendance;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeave: UseCombinedLeave;
  useCombinedGatePass: UseCombinedGatePass;
  useCombinedOvertime: UseCombinedOvertime;
  useCombinedOffSiteWorkHour: UseCombinedOffSiteWorkHour;
  leaveService: LeaveInterface;
  logRecordService: LogRecordInterface;
  useCombinedShiftSchedule: UseCombinedShiftSchedule;
  useCombinedRotationalShiftDay: UseCombinedRotationalShiftDay;
  userRendererService: UserRendererInterface;
}): () => JSX.Element {
  let Table = renderTableComponent();
  let Card = renderCardComponent();
  const tableHeader = Header({ type: 'daybook' });

  return function NewAttendancePage(): JSX.Element {
    const {
      list: shiftSchedules,
      getAll: getShiftSchedules,
    } = useCombinedShiftSchedule();

    const today = moment().format('YYYY-MM-DD');

    // TODO: This needs to be removed
    const defaultWorkArea = {
      id: '9B4C5A4F-BA23-41C1-B33A-DB6F46E17886',
      name: 'Production Area',
    };

    const { syncSet: setRotationalShiftDay } = useCombinedRotationalShiftDay();

    const [date, setDate] = useState(
      localStorage.getItem('attendance-date') ||
        moment.utc().format('YYYY-MM-DD')
    );
    const [combinedData, setCombinedData] = useState<
      Array<CombinedAttDataType>
    >([]);
    const [loading, setLoading] = useState<boolean>(false);
    const {
      companyGroup: currentCompanyGroup,
      location: currentLocation,
      company: currentCompany,
      cgSetting,
    } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);
    const { getListSync: getAllAttendanceSync } = useCombinedAttendance();
    const { syncSet: setAttendance } = useCombinedAttendance();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const { getListSync: getLeavesSync } = useCombinedLeave();
    const { syncSet: setLeave } = useCombinedLeave();
    const { getSync: getEmployeeSync } = useCombinedEmployeeProfile();
    const { syncSet: setGatePass } = useCombinedGatePass();
    const { syncSet: setOffsite } = useCombinedOffSiteWorkHour();
    const { syncSet: setOvertime } = useCombinedOvertime();
    const [isHr, setIsHr] = useState<boolean>(false);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }

      setIsSystemAdmin(
        currentUserRoles?.find((x) => x.name === 'System-Admin') ? true : false
      );
    }, [currentUserRoles]);

    const attForSupervision = combinedData?.filter((d) => {
      if (d.finalized) {
        return false;
      }

      if (
        d.employeeProfile.details?.authorizations?.attendanceSupervisor?.id ===
          currentUser?.id ||
        d.shiftDay?.supervisor?.id === currentUser.id
      ) {
        return true;
      }

      return false;
    });

    const attForAuthorization = combinedData?.filter((d) => {
      if (d.finalized) {
        return false;
      }

      if (
        d.employeeProfile.details?.authorizations?.attendanceAuthorizer?.id ===
          currentUser?.id &&
        d.isLateForShift &&
        d.details &&
        !d.details.lateMark
      ) {
        return true;
      }

      return false;
    });

    const attUnderCurrentUser = combinedData.filter((d) => {
      if (
        d.employee.id === currentUser.id ||
        d.employeeProfile.details?.authorizations?.attendanceSupervisor?.id ===
          currentUser?.id ||
        d.employeeProfile.details?.authorizations?.attendanceAuthorizer?.id ===
          currentUser?.id ||
        d.employeeProfile.details?.authorizations?.attendanceSupervisorMonitor
          ?.id === currentUser?.id ||
        d.shiftDay?.supervisor?.id === currentUser.id
      ) {
        return true;
      }

      return false;
    });

    useEffect(() => {
      if (currentLocation.id) {
        getShiftSchedules({
          location: currentLocation.name.toLowerCase(),
        });
      }
      // eslint-disable-next-line
    }, [currentLocation]);

    async function fetchData(showLoader?: boolean) {
      if (showLoader) {
        setLoading(true);
      }
      attendanceService
        .getAttendanceByDate({
          date,
          location: currentLocation.name.toLowerCase(),
        })
        .then((res) => {
          setCombinedData(res);
          if (showLoader) {
            setLoading(false);
          }
        });
    }

    useEffect(() => {
      if (date) {
        const val = localStorage.getItem('attendance-date');
        if (val !== date) {
          localStorage.setItem('attendance-date', date);
        }
      }
    }, [date]);

    useEffect(() => {
      if (currentLocation.name && currentUserRoles?.length && currentUser.id) {
        fetchData(false);
      }
      // eslint-disable-next-line
    }, [currentLocation, currentUser, currentUserRoles]);

    useEffect(() => {
      if (currentLocation.name && currentUserRoles?.length && currentUser.id) {
        fetchData(true);
      }
      // eslint-disable-next-line
    }, [date]);

    const supMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Verify',
          show: () => true,
          behaviour: 'confirm',
          onConfirm: (entries) => {
            const data = attForSupervision.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to approve verification for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  if (!c.verified || c.verified.verified !== 'yes') {
                    const verified = {
                      verified: 'yes',
                      verifiedAt: moment().utc().format(),
                      verifiedBy: {
                        id: currentUser.id,
                        name: currentUser.name,
                      },
                    };
                    if (c.id) {
                      await setAttendance({
                        id: c.id,
                        verified,
                      } as AttendanceType);
                    } else {
                      await setAttendance({
                        name: c.name,
                        date: c.date,
                        employee: c.employee,
                        status: c.status,
                        details: c.details,
                        verified: verified,
                        finalizedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                      } as AttendanceType);
                    }
                  }
                }
                await fetchData();
              },
            };
          },
        },
      ],
    };

    const authMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Approve Latemark',
          behaviour: 'confirm',
          show: () => true,
          onConfirm: (entries) => {
            const data = attForAuthorization.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to approve latemark for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  let lateMark: any = {
                    status: 'approved',
                    reason: 'bulk approved',
                    approvedBy: currentUser,
                  };
                  setAttendance({
                    id: c.id,
                    details: {
                      lateMark,
                    },
                  } as any);
                }
                await fetchData();
              },
            };
          },
        },
        {
          name: 'Reject Latemark',
          behaviour: 'confirm',
          show: () => true,
          onConfirm: (entries) => {
            const data = attForAuthorization.filter((x, idx) =>
              entries.includes(idx)
            );
            return {
              title: `Are you sure you want to reject latemark for ${data.map(
                (x) => x.employee.name
              )}`,
              type: 'warning',
              onConfirm: async () => {
                for (const c of data) {
                  let lateMark: any = {
                    status: 'rejected',
                    reason: 'bulk rejected',
                    approvedBy: currentUser,
                  };
                  setAttendance({
                    id: c.id,
                    details: {
                      lateMark,
                    },
                  } as any);
                }
                await fetchData();
              },
            };
          },
        },
      ],
    };

    const BulkShiftChange = renderBulkShiftChange();

    const hrMultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Bulk Shift Change',
          behaviour: 'modal',
          show: () => true,
          modal: {
            size: 'large',
            title: 'Bulk Shift Change',
            content: ({ entries, onClose }) => {
              return (
                <BulkShiftChange
                  setRotationalShiftDay={setRotationalShiftDay}
                  workArea={defaultWorkArea}
                  onClose={onClose}
                  combinedData={combinedData}
                  entries={entries}
                  shiftSchedules={shiftSchedules || []}
                />
              );
            },
          },
        },
      ],
    };

    const AttBody = renderAttendanceBody({
      isDaybook: true,
      userRendererService,
      currentUser,
      getAllAttendanceSync,
      setAttendance,
      fetchData: fetchData,
    });

    const tableBody: TableBody = AttBody({
      type: 'daybook',
      list: combinedData.map((c) => ({
        attendance: c,
      })),
    });
    const filteredSupervisionTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attForSupervision.map((c) => ({
        attendance: c,
        reason: (c.pendingActions || [])?.map(
          (x) => `${x.action} - ${x.actor.name}`
        ),
      })),
    });
    const filteredAuthorizationTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attForAuthorization.map((c) => ({
        attendance: c,
        reason: (c.pendingActions || [])?.map(
          (x) => `${x.action} - ${x.actor.name}`
        ),
      })),
    });
    const filteredUnderCurrentUserTableBody: TableBody = AttBody({
      type: 'daybook',
      list: attUnderCurrentUser.map((c) => ({
        attendance: c,
        reason: (c.pendingActions || [])?.map(
          (x) => `${x.action} - ${x.actor.name}`
        ),
      })),
    });

    const Actions = renderActions({
      isHr,
      setAttendance,
      leaveService,
      currentUser,
      useCombinedEmployeeProfile,
      useCombinedLeave,
      getEmployeeSync,
      setGatePass,
      setOffsite,
      useCombinedOvertime,
      saveNewOvertime,
      setOvertime,
      currentCompany,
      companyGroupSetting: cgSetting,
      fetchData,
      isSystemAdmin,
      attendanceService,
      logRecordService,
      getLeavesSync,
      setLeave,
    });
    const actions = Actions();

    function renderBody() {
      if (loading) {
        return (
          <div className='text-center'>
            <Recat className='h-5 inline animate-pulse mx-4' />
          </div>
        );
      }

      if (
        date > today ||
        moment(date).format('ddd MMM DD YYYY') === 'Invalid date'
      ) {
        return (
          <div className='w-full h-48 font-semibold flex justify-center items-center'>
            Invalid date, select date till {today}
          </div>
        );
      }
      return (
        <div>
          {filteredSupervisionTableBody.length ? (
            <>
              <Card
                disclosure={true}
                header={{
                  title: 'Requests for you (Supervisor)',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredSupervisionTableBody}
                      multiSelect={supMultiSelect}
                      actions={actions}
                      auth={currentUser?.authorization}
                    />
                  ),
                }}
              />
            </>
          ) : (
            <>
              {!isHr && !loading ? (
                <>
                  <Congratulations message='Congratulations! All your Supervision activities are completed. Great Job!' />
                </>
              ) : null}
            </>
          )}
          {filteredAuthorizationTableBody.length ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'Requests for you (Authorizer)',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredAuthorizationTableBody}
                      multiSelect={authMultiSelect}
                      actions={actions}
                      auth={currentUser?.authorization}
                    />
                  ),
                }}
              />
            </div>
          ) : (
            <>
              {!isHr && !loading ? (
                <>
                  <Congratulations message='Congratulations! All your Authorizer activities are completed. Great Job!' />
                </>
              ) : null}
            </>
          )}
          {filteredUnderCurrentUserTableBody.length ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'Attendances under your supervision / authorization',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      body={filteredUnderCurrentUserTableBody}
                      actions={actions}
                      auth={currentUser?.authorization}
                    />
                  ),
                }}
              />
            </div>
          ) : null}

          {isHr ? (
            <div className='my-2'>
              <Card
                disclosure={true}
                header={{
                  title: 'All Attendances',
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <Table
                      header={tableHeader}
                      multiSelect={hrMultiSelect}
                      body={tableBody}
                      auth={currentUser?.authorization}
                      actions={actions}
                    />
                  ),
                }}
              />
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className='pb-52'>
        <Card
          header={{
            sticky: true,
            title: 'Employee Attendance',
            icon: (
              <FingerPrintIcon className='w-8 h-8 stroke-indigo-900 self-center' />
            ),
            subheading: (
              <div className='flex items-center'>
                <span className='text-blue-700 font-bold'>
                  {moment(date).format('ddd MMM DD YYYY')}
                </span>
              </div>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder right-5 top-24 bg-slate-300'>
                    <DateSelector
                      max={today}
                      initialState={date}
                      format='YYYY-MM-DD'
                      onChange={(date) => setDate(date)}
                    />
                  </div>
                ),
              },
            ],
            menu: {
              actions: [
                {
                  name: 'Auto-Finalize Day',
                  show: () => true,
                  auth: 'UI:BTN-ATTEN-FINALIZE:VIEW',
                  behaviour: 'confirm',
                  onConfirm: () => {
                    return {
                      title: 'Are you sure you want to run Auto-finalization?',
                      type: 'warning',
                      onConfirm: async () => {
                        await attendanceService.autofinalize(date);
                        toast.success(
                          `Autofinalization successfully triggered for ${date}`
                        );
                      },
                    };
                  },
                },
                {
                  name: 'Bulk Unpaid Leave',
                  show: () =>
                    currentCompanyGroup.id ===
                    '0836C6F1-207E-4990-9BB4-605572012E4D',
                  behaviour: 'confirm',
                  onConfirm: () => {
                    const list = combinedData.filter((x) => {
                      if (x.finalized) {
                        return false;
                      }

                      if (
                        x.employeeProfile?.details?.jobProfile?.shiftType ===
                        'Flexible'
                      ) {
                        return false;
                      }
                      if (x.isOnWeeklyOff || x.isLocationOff) {
                        return false;
                      }
                      if (
                        x.punchResult.checkInList?.length ||
                        x.punchResult.checkOutList?.length
                      ) {
                        return false;
                      }
                      if (
                        x.empLeaves.filter(
                          (y) => y.leave.status !== 'cancelled'
                        ).length
                      ) {
                        return false;
                      }
                      if (
                        x.punchResult.checkInList?.length === 0 &&
                        x.punchResult.checkOutList?.length === 0
                      ) {
                        return true;
                      }
                      return false;
                    });

                    return {
                      title:
                        'Are you sure you want to add unpaid leaves for all employees with zero punches?',
                      message: `Employees: ${list
                        .map((x) => x.employee.name)
                        .join()}`,
                      type: 'warning',
                      onConfirm: async () => {
                        for (const x of list) {
                          await leaveService.applyLeave({
                            dateStart: date,
                            dateEnd: date,
                            duration: 'full-day',
                            reason: 'Auto Unpaid Leave',
                            type: 'unpaid-leave',
                            employee: {
                              id: x.employee.id,
                              name: x.employee.name,
                            },
                            details: {},
                            appliedBy: {
                              id: currentUser.id,
                              name: currentUser.name,
                            },
                            approvedBy: {
                              id: currentUser.id,
                              name: currentUser.name,
                            },
                          } as LeaveAppType);
                        }
                      },
                    };
                  },
                },
                {
                  name: 'Download Report',
                  behaviour: 'click',
                  show: () => isHr,
                  onClick: async () => {
                    let result =
                      `,,,${currentCompany.name} - ${date} Attendance,,,\n,,,${currentLocation.name},,,\n` +
                      'Employee code, Employe name, Finalized, Check-in, Check-out, Late (Yes / No), OT hours, Total no of hours worked, Payable Day Ratio\n';

                    combinedData.forEach((at) => {
                      const pdr = computePayableDayRatio(at);
                      result +=
                        `${at.employeeProfile?.details?.employeeId},` +
                        `${at.employee.name},` +
                        `${at.finalized ? 'yes' : 'no'},` +
                        `${
                          at.details?.summary?.originalPunch?.checkIn &&
                          at.details?.summary?.originalPunch?.checkIn !== '-'
                            ? moment(
                                at.details?.summary?.originalPunch?.checkIn
                              ).format('DD MMM HH:mm')
                            : '-'
                        },` +
                        `${
                          at.details?.summary?.originalPunch?.checkout &&
                          at.details?.summary?.originalPunch?.checkout !== '-'
                            ? moment(
                                at.details?.summary?.originalPunch?.checkout
                              ).format('DD MMM HH:mm')
                            : '-'
                        },` +
                        `${at.details?.summary?.lateMarkMins ? 'yes' : 'no'},` +
                        `${at.details?.summary?.overTimeHours || '-'},` +
                        `${at.details?.summary?.totalWorkHours || '-'},` +
                        `${pdr.total || '-'}\n`;
                    });

                    downloadFile({
                      fileType: 'application/csv',
                      fileName: `${date}-report.csv`,
                      result: result,
                    });
                  },
                },
                {
                  name: 'Download Month Report',
                  behaviour: 'click',
                  show: () => isHr,
                  onClick: async () => {
                    let result =
                      `,,,${currentCompany.name} - ${date} Attendance,,,\n,,,${currentLocation.name},,,\n` +
                      `Employee code, Employe name ${dayHeaders(date)}\n`;

                    for (const att of combinedData) {
                      const empAtts = _.sortBy(
                        await attendanceService.getEmployeeBook(
                          date.substring(0, 7),
                          att.employee.id
                        ),
                        'date'
                      );
                      console.log(empAtts);
                      result +=
                        `${att.employeeProfile?.details?.employeeId},` +
                        `${att.employee.name},`;

                      let hoursTotal = 0;
                      let payableTotal = 0;
                      // eslint-disable-next-line
                      empAtts.forEach((at) => {
                        const pdr = computePayableDayRatio(at);
                        result +=
                          `${
                            at.details?.summary?.originalPunch?.checkIn &&
                            at.details?.summary?.originalPunch?.checkIn !== '-'
                              ? moment(
                                  at.details?.summary?.originalPunch?.checkIn
                                ).format('DD MMM HH:mm')
                              : '-'
                          },` +
                          `${
                            at.details?.summary?.originalPunch?.checkout &&
                            at.details?.summary?.originalPunch?.checkout !== '-'
                              ? moment(
                                  at.details?.summary?.originalPunch?.checkout
                                ).format('DD MMM HH:mm')
                              : '-'
                          },` +
                          `${at.details?.summary?.totalWorkHours || '-'},` +
                          `${pdr.total || '-'},`;
                        hoursTotal += at.details?.summary?.totalWorkHours || 0;
                        payableTotal += pdr.total || 0;
                      });

                      result += `${hoursTotal},${payableTotal}`;
                      result += '\n';
                    }

                    downloadFile({
                      fileType: 'application/csv',
                      fileName: `${date}-report.csv`,
                      result: result,
                    });
                  },
                },
              ],
            },
          }}
          body={{
            type: 'jsx-component',
            body: renderBody(),
          }}
          auth={currentUser?.authorization}
        />
      </div>
    );
  };
}

export function dayHeaders(date) {
  const days = moment(date).daysInMonth();
  let result = '';
  for (let i = 1; i <= days; i++) {
    result += `, Check-in (${date.substring(
      0,
      7
    )}-${i}), Check-out (${date.substring(
      0,
      7
    )}-${i}),  Hours worked, Payable Day Ratio`;
  }

  result += ', Hours Total, Payable Total, Salary Take Home';

  return result;
}
