import {
  downloadFile,
  LoadingButton,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import axios from 'axios';
import { UseFileTransfer } from 'hooks/file-transfer/use-file-transfer';
import { useState } from 'react';
import { FILE_MANAGER_SERVER_URI } from '../../../../../config/server-uri';

export function renderTestTemplate({
  useFileTransfer,
}: {
  useFileTransfer: UseFileTransfer;
}) {
  const Card = renderCardComponent();
  return function TestTemplate({ url }: { url: string }) {
    const [dynamicData, setDynamicData] = useState<any>({});
    const { get: getSpecTemplate } = useFileTransfer();

    return (
      <div>
        <Card
          header={{ title: 'Test Template' }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <div className='m-1 border border-gray-100 p-1'>
                  <textarea
                    rows={5}
                    className='w-1/2 border border-gray-100'
                    defaultValue={JSON.stringify(dynamicData)}
                    onBlur={(evt) => {
                      try {
                        const newValue = JSON.parse(evt.target.value);
                        if (newValue) {
                          setDynamicData(newValue);
                        }
                      } catch (e) {
                        setDynamicData({});
                      }
                    }}
                  ></textarea>
                  <LoadingButton
                    text={'Test'}
                    behaviorFn={async () => {
                      const html = await getSpecTemplate(url);
                      const axiosInstance = axios.create({
                        baseURL: FILE_MANAGER_SERVER_URI,
                        responseType: 'arraybuffer',
                        headers: {
                          'Content-Type': 'application/json',
                          Accept: 'application/pdf',
                        },
                      });

                      const response = await axiosInstance.post('/pdf', {
                        template: `<div style="padding: 20px;">${html}</div>`,
                        data: dynamicData,
                      });
                      const fType = 'application/pdf';
                      const fName = 'test-template.pdf';
                      const res = response.data;
                      downloadFile({
                        fileType: fType,
                        fileName: fName,
                        result: res,
                      });
                    }}
                  />
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
