import {
  CompanyGroup,
  CurrentUserType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  Revision,
  RevisionType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  ExpressionType,
  ItemProperty,
  ItemPropertyValue,
} from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { UseCombinedAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { renderEditArrayObjectPropertyChangeReq } from './forms/edit-array-object-property-change-request';
import { renderEditChecklistPropertyChangeReq } from './forms/edit-checklist-property-change-request';
import { renderEditComplexArrayObjectPropertyChangeReq } from './forms/edit-complex-array-object-property-change-request';
import { renderEditExpValueUOMPropertyChangeReq } from './forms/edit-exp-value-uom-property-change-request';
import { renderEditImageRequest } from './forms/edit-image-request';
import { renderEditChangeRequest } from './forms/edit-input-change-request';
import { renderEditObjectPropertyChangeReq } from './forms/edit-object-property-change-request';
import { renderEditSearchableMultiSelectPropertyChangeReq } from './forms/edit-searchable-multiselect-property-change-request';
import { renderEditSearchableSelectPropertyChangeReq } from './forms/edit-searchable-select-property-change-request';
import { renderEditSelectPropertyChangeReq } from './forms/edit-select-property-change-request';
import { renderTagsEditChangeRequest } from './forms/edit-tags-change-request';
import { renderEditValueUOMPropertyChangeReq } from './forms/edit-value-uom-property-change-request';
import { ChangeRequestDataType } from './forms/types';

export function createChangeRequest({
  useCombinedGST,
  useCombinedMetric,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
  useCombinedGrade,
  useFileTransfer,
  useCombinedHazardGhsClass,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGrade: UseCombinedGrade;
  useFileTransfer: UseFileTransfer;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}) {
  return function ChangeRequest({
    propType,
    value,
    property,
    itemProperty,
    entityId,
    currentUser,
    setRevision,
    type,
    onClose,
    propOptions,
    searchSelectOptions,
    currentValue,
    currentCompanyGroup,
  }: {
    currentCompanyGroup: CompanyGroup;
    propType: string;
    property: string;
    propOptions?: string | Array<string>;
    itemProperty: ItemProperty;
    entityId: string;
    setRevision: (data: Revision) => Promise<void>;
    currentUser: CurrentUserType;
    onClose: () => void;
    type: RevisionType;
    searchSelectOptions?: string;
    currentValue: ItemPropertyValue;
    value:
      | {
          data?: string | number | IdName | undefined | any;
          owner?: {
            type: 'stockgroup' | 'item' | 'grade';
            id: string;
            name: string;
          };
        }
      | undefined;
  }): JSX.Element {
    const currentChReqValUOMValue = ((value?.data as unknown) as {
      value: number;
      uom: IdName;
    }) || { value: 0, uom: { id: '', name: '' } };
    const currentChReqExpValUOMValue = ((value?.data as unknown) as {
      exp: ExpressionType;
      value: number;
      uom: IdName;
      remarks?: {
        type?: 'text' | 'uom' | '';
        value?: string | number;
        uom?: IdName;
      };
    }) || { exp: '', value: 0, uom: { id: '', name: '' } };
    const currentChReqIdNameValue = ((value?.data as unknown) as IdName) || {};
    const currentChReqArrayIdNameValue =
      ((value?.data as unknown) as Array<IdName>) || [];
    const currentChReqChecklistValue = (value?.data as Array<string>) || [];

    const EditPropertyChangeReq = renderEditChangeRequest();
    const EditTagsPropertyChangeReq = renderTagsEditChangeRequest();
    const EditImageChnageReq = renderEditImageRequest({
      useFileTransfer,
    });
    const EditSelectPropertyValChangeReq = renderEditSelectPropertyChangeReq();
    const EditValueUOMPropertyValChangeReq = renderEditValueUOMPropertyChangeReq(
      { useCombinedMetric }
    );
    const EditExpValueUOMPropertyValChangeReq = renderEditExpValueUOMPropertyChangeReq(
      { useCombinedMetric }
    );
    const EditCheckListPropertyValChangeReq = renderEditChecklistPropertyChangeReq();
    const EditSearchSelectPropertyChangeReq = renderEditSearchableSelectPropertyChangeReq(
      {
        useCombinedMetric,
        useCombinedGST,
        useCombinedColor,
        useCombinedEmployeeProfile,
        useCombinedOdour,
        useCombinedAppearance,
        useCombinedGrade,
        useCombinedHazardGhsClass,
      }
    );

    const EditMultiSearchSelectPropertyChangeReq = renderEditSearchableMultiSelectPropertyChangeReq(
      {
        useCombinedMetric,
        useCombinedGST,
        useCombinedColor,
        useCombinedEmployeeProfile,
        useCombinedGrade,
        useCombinedHazardGhsClass,
        useCombinedAppearance,
        useCombinedOdour,
      }
    );
    const EditObjectPropertyChangeReq = renderEditObjectPropertyChangeReq({
      useCombinedMetric,
      useCombinedGST,
      useCombinedAppearance,
      useCombinedColor,
      useCombinedOdour,
      useCombinedEmployeeProfile,
      useCombinedGrade,
      useCombinedHazardGhsClass,
    });
    const EditArrayObjectPropertyChangeReq = renderEditArrayObjectPropertyChangeReq(
      {
        useCombinedMetric,
        useCombinedGST,
        useCombinedAppearance,
        useCombinedColor,
        useCombinedOdour,
        useCombinedEmployeeProfile,
        useCombinedGrade,
        useCombinedHazardGhsClass,
      }
    );

    const EditComplexArrayObjectPropertyChangeReq = renderEditComplexArrayObjectPropertyChangeReq(
      {
        useCombinedMetric,
        useCombinedGST,
        useCombinedAppearance,
        useCombinedColor,
        useCombinedOdour,
        useCombinedEmployeeProfile,
        useFileTransfer,
        useCombinedGrade,
        useCombinedHazardGhsClass,
      }
    );

    const currentChReqValue = value?.data || '';

    const currentTagsValue = value?.data || [];

    async function createChangeRequest({
      newValue,
      reason,
    }: {
      newValue: ItemPropertyValue;
      reason: string;
    }) {
      try {
        const finalRevRequest = {
          resource: entityId,
          name: property,
          reason: reason,
          type: type,
          isLatest: false, // TODO: Unsure about this field
          status: 'pending',
          requestedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          operation: 'value-updated',
          changeRequest: {
            currentValue: currentValue,
            newValue: newValue,
          },
          details: {
            property: property,
            value: newValue.value?.data,
            itemProperty: itemProperty,
          },
        } as Revision;

        console.log('finalData', finalRevRequest);
        await setRevision(finalRevRequest);
        toast('Change request submitted successfully');
        onClose();
      } catch (e) {
        console.log('Something went wrong');
      }
    }

    const handleEditPropertyChangeReq = async (form: ChangeRequestDataType) => {
      if (
        form.changeRequest.newValue.value.data !== '' &&
        form.changeRequest.newValue.value.data !== currentChReqValue
      ) {
        createChangeRequest({
          newValue: form.changeRequest.newValue as ItemPropertyValue,
          reason: form.reason,
        });
      }
    };

    const handleEditTagsPropertyChangeReq = async (
      form: ChangeRequestDataType
    ) => {
      if (
        !_.isEqual(currentTagsValue, form.changeRequest.newValue.value.data)
      ) {
        createChangeRequest({
          newValue: form.changeRequest.newValue as ItemPropertyValue,
          reason: form.reason,
        });
      }
    };

    const handleEditIdNamePropertyChangeReq = async (
      form: ChangeRequestDataType
    ) => {
      if (
        form.changeRequest.newValue.value.data &&
        form.changeRequest.newValue.value.data !== currentChReqValue
      ) {
        createChangeRequest({
          newValue: form.changeRequest.newValue as ItemPropertyValue,
          reason: form.reason,
        });
      }
    };

    const handleEditValueUOMPropertyChangeReq = async (
      form: ChangeRequestDataType
    ) => {
      if (
        form.changeRequest.newValue?.value?.data?.value &&
        form.changeRequest.newValue.value.data.value !==
          currentChReqValUOMValue?.value
      ) {
        createChangeRequest({
          newValue: form.changeRequest.newValue as ItemPropertyValue,
          reason: form.reason,
        });
      }
    };

    const handleEditExpValueUOMPropertyChangeReq = async (
      form: ChangeRequestDataType
    ) => {
      if (
        form.changeRequest.newValue?.value?.data?.value &&
        form.changeRequest.newValue.value.data.value !==
          currentChReqExpValUOMValue?.value
      ) {
        createChangeRequest({
          newValue: form.changeRequest.newValue as ItemPropertyValue,
          reason: form.reason,
        });
      }
    };

    if (
      itemProperty.valueType === 'fixed' &&
      itemProperty.owner?.id !== entityId
    ) {
      return (
        <div className='text-center animate-pulse'>
          Cannot Set value for a Fixed Value Type at Current Level
        </div>
      );
    }

    switch (propType) {
      case 'input':
      case 'date':
      case 'number':
      case 'formula':
        return (
          <EditPropertyChangeReq
            validPattern={itemProperty.validPattern || ''}
            propType={propType}
            currentValue={String(currentChReqValue)}
            onSave={handleEditPropertyChangeReq}
          />
        );
      case 'image':
        return (
          <EditImageChnageReq
            propType={propType}
            currentValue={currentChReqValue}
            path={`${currentCompanyGroup.id}/inventory/properties/images/${property}`}
            onSave={handleEditPropertyChangeReq}
          />
        );
      case 'select':
        return (
          <EditSelectPropertyValChangeReq
            propOptions={propOptions || ''}
            currentValue={String(currentChReqValue)}
            onSave={handleEditPropertyChangeReq}
          />
        );
      case 'checklist':
        return (
          <EditCheckListPropertyValChangeReq
            propOptions={propOptions || ''}
            currentValue={currentChReqChecklistValue}
            onSave={handleEditPropertyChangeReq}
          />
        );
      case 'value-uom':
        return (
          <EditValueUOMPropertyValChangeReq
            itemProperty={itemProperty}
            iscurrentLevelOwnerShip={itemProperty.owner?.id === entityId}
            currentValue={currentChReqValUOMValue}
            onSave={handleEditValueUOMPropertyChangeReq}
          />
        );
      case 'exp-value-uom':
        return (
          <EditExpValueUOMPropertyValChangeReq
            itemProperty={itemProperty}
            iscurrentLevelOwnerShip={itemProperty.owner?.id === entityId}
            currentValue={currentChReqExpValUOMValue}
            onSave={handleEditExpValueUOMPropertyChangeReq}
          />
        );
      case 'searchable-select':
        return (
          <EditSearchSelectPropertyChangeReq
            currentValue={currentChReqIdNameValue}
            onSave={handleEditIdNamePropertyChangeReq}
            useResources={searchSelectOptions || ''}
          />
        );
      case 'searchable-multiselect':
        return (
          <EditMultiSearchSelectPropertyChangeReq
            currentValue={currentChReqArrayIdNameValue}
            onSave={handleEditIdNamePropertyChangeReq}
            useResources={searchSelectOptions || ''}
          />
        );
      case 'object': {
        return (
          <EditObjectPropertyChangeReq
            entityId={entityId}
            itemProperty={itemProperty}
            currentValue={currentChReqIdNameValue}
            onSave={handleEditIdNamePropertyChangeReq}
          />
        );
      }
      case 'fixed-array-object':
      case 'array-object': {
        return (
          <EditArrayObjectPropertyChangeReq
            entityId={entityId}
            itemProperty={itemProperty}
            currentValue={currentChReqIdNameValue}
            onSave={handleEditIdNamePropertyChangeReq}
          />
        );
      }
      case 'complex-array-object':
        return (
          <EditComplexArrayObjectPropertyChangeReq
            entityId={entityId}
            type={type}
            itemProperty={itemProperty}
            currentValue={currentChReqIdNameValue}
            onSave={handleEditIdNamePropertyChangeReq}
          />
        );
      case 'tags':
        return (
          <EditTagsPropertyChangeReq
            propType={propType}
            currentValue={currentTagsValue}
            onSave={handleEditTagsPropertyChangeReq}
          />
        );
      default:
        return <div>Unknown Property Type</div>;
    }
  };
}
