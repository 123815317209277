import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  Revision,
  RevisionType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { AdvancedLoadingButton } from '@erp_core/erp-ui-components';
import { TrashIcon, WrenchIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { ValueRenderer } from '../value-renderer/value-renderer';

export function RHSRenderer({
  type,
  x,
  entityId,
  currentUser,
  useFileTransfer,
  changeRequested,
  parentValues,
  setRevision,
}: {
  type: RevisionType;
  changeRequested: boolean;
  entityId: string;
  currentUser: CurrentUserType;
  x: ItemPropertyValue;
  useFileTransfer: UseFileTransfer;
  parentValues: ItemPropertyValue[];
  setRevision: (data: Revision) => Promise<void>;
}): JSX.Element {
  return (
    <div>
      <div className='text-md font-medium text-gray-900'>
        <div className='flex'>
          <div className='flex-auto'>
            <div className='flex'>
              <div className='flex-auto'>
                {!x.value ? (
                  <div className='text-md font-medium text-gray-900'>
                    Value not set...
                  </div>
                ) : (
                  <ValueRenderer
                    key={x.name}
                    x={x}
                    useFileTransfer={useFileTransfer}
                    parentValues={parentValues}
                  />
                )}
              </div>
              {changeRequested ? (
                <WrenchIcon className='inline w-5 text-green-500 animate-pulse' />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {x.value?.owner ? (
        <div className='text-gray-700 text-right italic text-sm'>
          set at {x.value?.owner?.name}
          {x?.value?.owner?.id === entityId ? (
            <AdvancedLoadingButton
              text={
                <TrashIcon className='inline w-5 h-5 text-red-500 cursor-pointer' />
              }
              defaultStyle='bg-none p-0'
              behaviour='confirm'
              onConfirm={() => {
                return {
                  title: `Are you sure you want to delete this Value of ${x.name}? This cannot be undone.`,
                  message: 'A revision request will be created if you proceed.',
                  type: 'warning',
                  showInput: true,
                  onConfirm: async (inputReason) => {
                    try {
                      const finalRevRequest = {
                        resource: entityId,
                        name: x.name,
                        reason: inputReason,
                        type: type,
                        isLatest: false, // TODO: Unsure about this field
                        status: 'pending',
                        requestedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                        operation: 'value-deleted',
                        changeRequest: {},
                        details: {
                          property: x.name,
                          itemProperty: x,
                        },
                      } as Revision;

                      console.log('finalData', finalRevRequest);
                      await setRevision(finalRevRequest);
                      toast('Change request submitted successfully');
                    } catch (e) {
                      console.log('Something went wrong');
                    }
                  },
                };
              }}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
