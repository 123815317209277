import { ItemRes, Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UseCombinedAppearance } from '../../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCurrentUserRoles } from '../../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedRole } from '../../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedUser } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';
import { UseCombinedRevision } from '../../../../../hooks/inventory/revision/use-revision';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderProperties } from '../../../properties/properties';

export type NewPropsProp = {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCombinedRevision: UseCombinedRevision;
  useCombinedRole: UseCombinedRole;
  useCombinedUser: UseCombinedUser;
  itemService: ItemInterface;
  item: ItemRes;
  setItem: (s: ItemRes) => Promise<ItemRes>;
  setRevision: (r: Revision) => Promise<Revision>;
  useFileTransfer: UseFileTransfer;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
};

export function renderItemPropertiesTab(): (x: NewPropsProp) => JSX.Element {
  return function NewProps({
    itemService,
    item,
    setItem,
    useCombinedMetric,
    useCombinedGST,
    useCombinedAppearance,
    useCombinedColor,
    useCombinedOdour,
    useCurrentUserRoles,
    useCombinedRevision,
    useCombinedRole,
    useCombinedUser,
    setRevision,
    useFileTransfer,
    useCombinedEmployeeProfile,
    useCombinedGrade,
    useCombinedHazardGhsClass,
  }: NewPropsProp): JSX.Element {
    const Properties = renderProperties<ItemRes>({
      useCombinedGrade,
      useCombinedMetric,
      useCombinedGST,
      useCombinedAppearance,
      useCombinedColor,
      useCombinedOdour,
      useCurrentUserRoles,
      useCombinedRevision,
      useFileTransfer,
      useCombinedRole,
      useCombinedUser,
      useCombinedEmployeeProfile,
      useCombinedHazardGhsClass,
    });
    const { cgSetting } = useContext(CurrentContext);
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);
    const [properties, setProperties] = useState<Array<ItemProperty>>([]);

    useEffect(() => {
      let tabs: Array<string> = [];
      const classifiers = _.sortBy(
        cgSetting.details?.inventory?.classifiers || [],
        'sequence'
      ).map((z) => z.name);
      tabs = [...classifiers];
      properties.forEach((p) => {
        p?.classifiers?.forEach((c) => {
          if (c.name && !tabs.includes(c.name)) {
            tabs.push(c.name);
          }
        });
      });

      tabs = [
        // 'Master',
        // ...tabs.sort(),
        ...tabs,
        'Draft',
      ];

      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, [properties, cgSetting]);

    useEffect(() => {
      if (item?.id) {
        itemService.getProperties(item.id).then((c) => {
          setProperties(c);
        });
      }
      // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Master': {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  renderAdd: false,
                  renderEditValue: true,
                }}
                entityService={itemService}
                entity={item}
                setEntity={setItem}
                type='item'
                setRevision={setRevision}
              />
            );
            break;
          }

          default: {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  classifier: currentTab,
                  renderAdd: false,
                  renderEditValue: true,
                }}
                entityService={itemService}
                entity={item}
                setEntity={setItem}
                type='item'
                setRevision={setRevision}
              />
            );
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, item]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full'>{body}</div>
        </div>
      </div>
    );
  };
}
