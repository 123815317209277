// import { InquiryDetails } from '@erp_core/erp-types/dist/modules/order';
import {
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableActionsType,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
import { renderAddEnquiryScheduleForm } from '../component/schedule-form/enquiry-schedule';

export function renderAddEnquiryForm({
  useCombinedClient,
  useCombinedGrade,
}: {
  useCombinedClient: UseCombinedClient;
  useCombinedGrade: UseCombinedGrade;
}) {
  return function AddEnquiryForm({ data }: { data: any }): JSX.Element {
    const { getSync: getClient } = useCombinedClient();
    const Form = renderFormV2({
      onSubmit: async (form) => {
        // onSave(form);
      },
      style: 'md:w-1/2',
      mapTToU: (f) => f,
      initialFormState: {
        product: data?.product || '',
        productCode: data?.productCode || '',
        // productGrade: data?.productGrade || '',
        endUse: data?.endUse || '',
        modeOfApp: data?.modeOfApp || '',
        modeOfEnq: data?.modeOfEnq || '',
        customer: data?.customer || '',
        remarks: data?.remarks || '',
        casno: data?.casno || '',
        date: data?.date || '',
        destination: data?.destination || '',
        refNo: data?.refNo || '',
        alias: data?.alias || '',
        location: data?.location || '',
        address: data?.address || '',
        contactPerson: data?.contactPerson || '',
        quantity: data?.quantity ? data?.quantity : ([] as any),
      },
      onFormDataChange: ({
        oldFormData,
        newFormData,
        updateFormData,
        fields,
        setFields,
      }) => {
        const newFields = [...fields];
        let newFieldsUpdated = false;

        if (
          (oldFormData.customer as any)?.id !==
          (newFormData.customer as any)?.id
        ) {
          getClient((newFormData.customer as any).id).then((clt) => {
            console.log(clt);
            const contactList =
              clt.details?.contact?.map((x) => ({
                text: x.name,
                value: x.name,
              })) || [];
            const contactPerson = newFields.find(
              (x) => x.property === 'contactPerson'
            );
            if (contactPerson) {
              (contactPerson as any).options = [
                { text: 'Select', value: '' },
                ...contactList,
              ];
            }
            const addressList =
              clt.details.locations?.map((x) => ({
                text:
                  x.type +
                  ',' +
                  x.address.addressLine1 +
                  ',' +
                  x.address.addressLine2 +
                  ',' +
                  x.address.city +
                  ',' +
                  x.address.state +
                  '-' +
                  x.address.pincode,
                value:
                  x.type +
                  x.address.addressLine1 +
                  x.address.addressLine2 +
                  x.address.city +
                  x.address.state +
                  x.address.pincode,
              })) || [];
            const address = newFields.find((x) => x.property === 'address');
            if (address) {
              (address as any).options = [
                { text: 'Select', value: '' },
                ...addressList,
              ];
            }
            newFieldsUpdated = true;
            if (newFieldsUpdated) {
              setFields(newFields);
            }
          });
        }

        console.log(oldFormData, newFormData, fields);
      },
      fieldsData: [
        {
          property: 'customer',
          label: 'Client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect: () => null,
          },
        },
        {
          property: 'contactPerson',
          label: 'Contact Person (fetch from client profile)',
          type: 'select',
          options: [
            {
              text: 'Select',
              value: '',
            },
          ],
        },
        {
          property: 'modeOfEnq',
          label: 'Mode of Enquiry (fetch from master)',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Email', value: 'email' },
            { text: 'Phone', value: 'phone' },
            { text: 'In Person', value: 'in-person' },
            { text: 'Whatsapp', value: 'whatsapp' },
          ],
        },
        {
          property: 'modeOfApp',
          label: 'Mode of Approach (fetch from master)',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Customer', value: 'customer' },
            { text: 'Self', value: 'self' },
          ],
        },
        // {
        //   property: 'product',
        //   label: 'Product',
        //   type: 'searchable-select',
        //   searchOptions: {
        //     useSearch: useCombinedItem,
        //     onSearchValueSelect: () => null,
        //   },
        // },
        // {
        //   property: 'casno',
        //   label: 'CAS No (fetch from item)',
        //   type: 'input',
        // },
        // {
        //   property: 'productCode',
        //   label: 'Product Code (fetch from product details)',
        //   type: 'input',
        // },
        // {
        //   property: 'productGrade',
        //   label: 'Product Grade (fetch from product code)',
        //   type: 'input',
        // },
        // {
        //   property: 'endUse',
        //   label: 'End Use Of Product (fetch from product dtls)',
        //   type: 'input',
        // },
        {
          property: 'refNo',
          label: 'Ref No',
          type: 'input',
        },
        // {
        //   property: 'destination',
        //   label: 'Destination',
        //   type: 'input',
        // },
        // {
        //   property: 'location',
        //   type: 'select',
        //   options: [
        //     { text: 'Select', value: '' },
        //     { text: 'factory', value: 'factory' },
        //     { text: 'office', value: 'office' },
        //   ],
        // },
        {
          property: 'address',
          label: 'Address',
          type: 'select',
          options: [
            {
              text: 'Select',
              value: '',
            },
          ],
        },

        // {
        //   property: 'alias',
        //   label: 'Alias (fetch from product)',
        //   type: 'input',
        // },
        {
          property: 'remarks',
          label: 'Remarks',
          type: 'input',
        },
        {
          width: 'w-full',
          property: 'quantity',
          type: 'jsx',
          // dependentOn: (formData) => {
          //   return formData.documents === ;
          // },
          renderObject: ({ formData, field, onSave }) => {
            console.log('property2', formData);
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  useCombinedGrade={useCombinedGrade}
                />
              </div>
            );
          },
        },
      ],
    });

    return <Form />;
  };
}

function ObjectRender({
  formData,
  field,
  onSave,
  useCombinedGrade,
}): JSX.Element {
  console.log('field', field);
  const [props, setProps] = useState<any>(formData[field] || []);
  console.log('props', props);
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  const Form = renderAddEnquiryScheduleForm();

  const cardHeader: CardHeader = {
    title: 'Quantity',
    actions: [
      {
        type: 'button',
        button: {
          behaviour: 'modal',
          name: 'Add',
          modal: {
            title: 'Add Quantity',
            content: ({ onClose }) => {
              const Form1 = renderFormV2({
                mapTToU: (f) => f,
                button: {
                  avoidSubmitBehavior: true,
                },
                initialFormState: {
                  quantity: '',
                  unit: '',
                  date: '',
                  leadTime: '',
                  dateOrLeadTime: '',
                  deliveryPer: '',
                  productGrade: { id: '', name: '' },
                },
                style: 'w-1/4',
                fieldsData: [
                  {
                    property: 'productGrade',
                    type: 'searchable-select',
                    searchOptions: {
                      useSearch: useCombinedGrade,
                      onSearchValueSelect: () => null,
                    },
                  },
                  {
                    property: 'quantity',
                    label: 'quantity',
                    type: 'number',
                    required: true,
                  },
                  {
                    property: 'unit',
                    label: 'unit (fetch from master)',
                    type: 'input',
                    required: true,
                  },
                  {
                    property: 'dateOrLeadTime',
                    label: 'Date',
                    type: 'select',
                    options: [
                      { text: 'Select', value: '' },
                      { text: 'date', value: 'date' },
                      { text: 'leadTime', value: 'leadTime' },
                    ],
                    required: true,
                  },
                  {
                    property: 'date',
                    label: 'Date',
                    type: 'date',
                    dependentOn: (formData) => {
                      return formData.dateOrLeadTime === 'date';
                    },
                  },
                  {
                    property: 'leadTime',
                    label: 'Lead Time(in days)',
                    type: 'input',
                    dependentOn: (formData) => {
                      return formData.dateOrLeadTime === 'leadTime';
                    },
                  },
                  {
                    property: 'deliveryPer',
                    label: 'Delivery Period',
                    type: 'input',
                    required: true,
                  },
                ],
                onSubmit: async (form) => {
                  console.log('props', props);
                  const newProps = [...props];
                  newProps.push(form);
                  setProps(newProps);
                  onClose();
                },
              });
              return <Form1 />;
            },
          },
        },
      },
    ],
  };

  function addActions(): TableActionsType[] {
    return [
      {
        name: 'Add Schedule',
        show: ({ qty }: { qty: any }) => {
          // we want to give ability to edit only when the revision
          // is in pending state.
          if (qty) {
            return true;
          }
          return false;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add schedule',
          content: ({
            data: { qty },
            onClose,
          }: {
            data: {
              qty: any;
            };
            onClose: () => any;
          }) => {
            return (
              <div>
                <Form data={undefined} onClose={onClose} />
              </div>
            );
          },
        },
      },
    ];
  }
  const Card = renderCardComponent();
  return (
    <div>
      <div>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
        <Card
          header={cardHeader}
          body={{
            type: 'columns',
            body: [],
          }}
        />
      </div>

      <Table
        header={[
          [
            { name: 'Product Grade' },
            { name: 'Quantity' },
            { name: 'Unit' },
            { name: 'Date / LeadTime' },
            { name: 'Delivery Period' },
          ],
        ]}
        body={(props || []).map((x) => {
          return {
            cells: [
              { value: x.productGrade.name },
              { value: x.quantity },
              { value: x.unit },
              { value: x.date || x.leadTime + ' days' },
              { value: x.deliveryPer },
            ],
            rowData: {
              qty: x,
            },
          };
        })}
        actions={addActions()}
      />
    </div>
  );
}
