import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';
import { renderAddGrade } from './forms/add-grade';

export type GradesProps = {
  item: ItemRes;
  useCombinedGrade: UseCombinedGrade;
};

export function renderItemGrades(): (p: GradesProps) => JSX.Element {
  const Table = renderTableComponent();
  const Card = renderCardComponent();
  const Form = renderAddGrade();

  return function ItemGrades({
    item,
    useCombinedGrade,
  }: GradesProps): JSX.Element {
    const { list: grades, getAll: getGrades } = useCombinedGrade();
    const { syncSet: setGrade } = useCombinedGrade();

    useEffect(() => {
      if (item.id) {
        getGrades({
          item: item.id,
          getAll: true,
        } as any);
      }
      // eslint-disable-next-line
    }, [item]);

    async function saveAddGrade(data) {
      let finalGradeName = data.name;
      if (!finalGradeName.includes(item.name)) {
        finalGradeName = `${item.name} (${data.name})`;
      }
      if (data.name)
        await setGrade({
          name: finalGradeName,
          gradeId: data.gradeId,
          item: {
            id: item.id,
            name: item.name,
          },
        } as any);
    }

    const header: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Grade-Id' },
        { name: 'Description' },
        { name: 'Item' },
        { name: 'Created By' },
        { name: 'Actions' },
      ],
    ];

    const body: TableBody =
      (grades || []).map((p: any) => {
        return {
          cells: [
            {
              value: p.name,
              link: `/inventory/masters/items/${item.id}/grade/${p.id}/properties`,
            },
            {
              value: p.gradeId || '-',
            },
            {
              value: p.description,
            },
            { value: p.item.name },
            { value: p.createdBy.name || '' },
            { value: '' },
          ],
        };
      }) || [];
    return (
      <div>
        <Card
          header={{
            title: `${item.name || ''} Grades`,
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add Grade',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Grade',
                    content: ({ onClose }) => {
                      return (
                        <Form
                          onSave={async (form) => {
                            await saveAddGrade(form);
                            getGrades({
                              item: item.id,
                              getAll: true,
                            } as any);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={header} body={body} />,
          }}
        />
      </div>
    );
  };
}
