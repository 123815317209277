import { renderFormV2 } from '@erp_core/erp-ui-components';
import { v4 } from 'uuid';

type AddBomProps = { onSave: (data) => Promise<void> };

export function renderAddGrade(): (props: AddBomProps) => JSX.Element {
  return function AddGrade({ onSave }: AddBomProps): JSX.Element {
    const Form = renderFormV2({
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Grade Name',
        },
        {
          property: 'description',
          type: 'input',
          required: true,
          label: 'Grade Description',
        },
        {
          property: 'gradeId',
          type: 'input',
          required: true,
          label: 'Grade Id',
        },
      ],
      initialFormState: {
        name: '',
        description: '',
        gradeId: v4(),
      },
      onSubmit: async (form) => {
        if (!form.gradeId) {
          form.gradeId = v4();
        }
        await onSave(form);
      },
      mapTToU: (x) => {
        return x as any;
      },
      style: 'md:w-1/2',
    });

    return <Form />;
  };
}
