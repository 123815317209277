import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  currentValue: IdName;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
  useResources: string;
};

type EditPropertyIdNameChangeReqFormType = {
  currentValue: IdName;
  newValue: IdName;
  reason: string;
};

export const renderEditSearchableSelectPropertyChangeReq = ({
  useCombinedMetric,
  useCombinedGST,
  useCombinedEmployeeProfile,
  useCombinedColor,
  useCombinedOdour,
  useCombinedAppearance,
  useCombinedGrade,
  useCombinedHazardGhsClass,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedColor: UseCombinedColor;
  useCombinedOdour: UseCombinedOdour;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedGrade: UseCombinedGrade;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
}) => {
  return function EditSearchableSelectPropertyChangeReq({
    currentValue,
    onSave,
    useResources,
  }: EditChangeReqProps) {
    const useSearchResource = (useResources: string) => {
      switch (useResources) {
        case 'useMetrics':
          return {
            resource: useCombinedMetric,
            body: (data) => {
              return (
                <div>
                  <div className='font-bold'>{data.name}</div>
                  <div>{data.symbol}</div>
                </div>
              );
            },
          };
        case 'useGST':
          return {
            resource: useCombinedGST,
            body: undefined,
          };
        case 'useAppearances':
          return {
            resource: useCombinedAppearance,
            body: undefined,
          };
        case 'useColors':
          return {
            resource: useCombinedColor,
            body: undefined,
          };
        case 'useOdours':
          return {
            resource: useCombinedOdour,
            body: undefined,
          };
        case 'useEmployees':
          return {
            resource: useCombinedEmployeeProfile,
            body: undefined,
          };
        case 'useGrade':
          return {
            resource: useCombinedGrade,
            body: undefined,
          };
        case 'useHazardClass':
          return {
            resource: useCombinedHazardGhsClass,
            body: (data) => {
              return (
                <div>
                  <div className='font-bold'>{data.name}</div>
                  <div>
                    {data.chapterNo} {data.categoryName}
                  </div>
                </div>
              );
            },
          };
        default:
          return null;
      }
    };

    const searchResource = useSearchResource(useResources);
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyIdNameChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'searchable-select',
          required: true,
          label: 'New Value',
          searchOptions: {
            searchOptionsBody: searchResource.body
              ? {
                  customBody: searchResource.body,
                }
              : undefined,
            useSearch: searchResource.resource,
            filter:
              searchResource.resource === useCombinedEmployeeProfile
                ? { crossGroup: 'true' }
                : undefined,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue.name,
        newValue: { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyIdNameChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyIdNameChangeReqFormType>(formProps);

    return <Form />;
  };
};
