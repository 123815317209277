import {
  Revision,
  RevisionFilterType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ChangeAssesmentType } from '@erp_core/erp-types/dist/modules/quality-assurance';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  renderTableWithMapperComponent,
  TableActionsType,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { InformationCircleIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/user';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedRevision } from '../../../hooks/inventory/revision/use-revision';
import { UseCombinedChangeAssesment } from '../../../hooks/quality-assurance/change-assesment/use-change-assesment';
import { RevisionInterface } from '../../../models/interfaces/inventory/revision';
import { UserRendererInterface } from '../../common/fragments/user';
import { ValueRenderer } from '../properties/value-renderer/value-renderer';
import { renderSendToQA } from './component/send-to-qa';

type RenderRevisionProps = {
  useCombinedRevision: UseCombinedRevision;
  useCombinedChangeAssesment: UseCombinedChangeAssesment;
  revisionService: RevisionInterface;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
};

export function renderRevisionDetails({
  useCombinedRevision,
  useCombinedChangeAssesment,
  revisionService,
  useFileTransfer,
  userRendererService,
}: RenderRevisionProps): () => JSX.Element {
  return function RevisionDetails(): JSX.Element {
    const { syncSet: setRevision } = useCombinedRevision();

    const { user: currentUser } = useContext(UserContext);

    const { syncSet: setChangeAssesment } = useCombinedChangeAssesment();

    const QAForm = renderSendToQA();
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Revision Details',
    };

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Approve',
          show: ({ revision }: { revision: Revision }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['pending'].includes(revision.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ revision }) => {
            return {
              title: 'Are you sure you want to approve revision?',
              onConfirm: async () => {
                await approveRevision(revision);
              },
            };
          },
        },
        {
          name: 'Reject',
          behaviour: 'confirm',
          show: ({ revision }: { revision: Revision }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['pending'].includes(revision.status)) {
              return true;
            }
            return false;
          },
          onConfirm: ({ revision }) => {
            return {
              title: 'Are you sure you want to reject revision?',
              onConfirm: async () => {
                await rejectRevision(revision);
              },
            };
          },
        },
        {
          name: 'Send to QA',
          show: ({ revision }: { revision: Revision }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['pending'].includes(revision.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Send to QA',
            content: ({
              data: { revision },
              onClose,
            }: {
              data: {
                revision: Revision;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <QAForm
                    onSave={async (e) => {
                      await sendToQA(e, revision);
                    }}
                    onClose={onClose}
                  />
                </div>
              );
            },
          },
        },
      ];
    }
    const Table = renderTableWithMapperComponent<
      Revision,
      RevisionFilterType
    >();
    const SimpleTable = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Type / Operation' },
        { name: 'Date' },
        { name: 'Version' },
        { name: 'Change Request' },
        { name: 'Reason' },
        { name: 'Summary' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Activities
            </>
          ),
        },
      ],
    ];

    const approveRevision = async (revision: Revision) => {
      await revisionService.approveRevision({
        id: revision.id,
        companyGroupId: revision.companyGroup.id,
        summary: revision.summary,
      } as any);
      toast('Approved revision sucessfully');
    };

    const rejectRevision = async (revision: Revision) => {
      const finalData = {
        id: revision.id,
        status: 'rejected',
        approvedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };

      await setRevision(finalData as Revision);
      toast('Rejected sucessfully');
    };

    const sendToQA = async (
      changeAssesment: ChangeAssesmentType,
      revision: Revision
    ) => {
      const finalData = {
        description: changeAssesment.description,
        details: {
          revisionId: revision.id,
          changeRequest: {
            currentValue: revision.changeRequest.currentValue,
            newValue: revision.changeRequest.newValue,
          },
          itemProperty: revision.details.itemProperty,
        },
      };
      console.log('finalData', finalData);
      await setChangeAssesment(finalData as ChangeAssesmentType);
      toast('Send to QA sucessfully');
    };

    const filter: any = {
      version: 'v2',
      sortFields: [
        {
          key: 'status',
          value: 'status',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'pending', value: 'pending' },
            { text: 'approved', value: 'approved' },
            { text: 'rejected', value: 'rejected' },
          ],
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Status !== 'all') {
          filterData.status = filterSelection.status;
        }
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        return (filterData as unknown) as any;
      },
      filterResources: {},
    };

    const bodyMapper = (l: Revision) => {
      const revisionArrayObjChval = l.changeRequest.currentValue;
      const revisionCurrentX: ItemPropertyValue = JSON.parse(
        JSON.stringify(l.details.itemProperty)
      );
      revisionCurrentX.value = (revisionArrayObjChval as any)?.value;

      const revisionArrayObjChvalNew = l.changeRequest.newValue;
      const revisionNewX: ItemPropertyValue = JSON.parse(
        JSON.stringify(l.details.itemProperty)
      );
      revisionNewX.value = (revisionArrayObjChvalNew as any)?.value;

      const cells: Array<TableCell> = [
        {
          value: (
            <div>
              <div className='font-semibold'>{l.name || '-'}</div>
              <div>
                {l.summary?.details?.find((x) => x.id === l.resource)?.name}
              </div>
            </div>
          ),
          link: `/inventory/masters/revisions/${l.id}`,
        },
        {
          value: (
            <>
              <span className='border border-gray-200 rounded mx-1'>
                {l.type}
              </span>
              /
              <span className='border border-gray-200 rounded mx-1'>
                {l.operation}
              </span>
            </>
          ),
        },
        { value: moment(l.createdAt).format('DD-MMM-YYYY') },
        { value: l.version || l.summary?.projectedVersion || '-' },
        {
          value: (
            <div>
              {/* <div className='font-semibold text-md'>{l.details.property}</div> */}
              <div className='text-gray-700 italic text-sm'>
                Current value :{' '}
                <>
                  {revisionCurrentX ? (
                    <>
                      <ValueRenderer
                        x={revisionCurrentX}
                        parentValues={[]} // TODO: This may require values
                        useFileTransfer={useFileTransfer}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
              <div className='text-gray-700 italic text-sm'>
                New value :{' '}
                <>
                  {revisionNewX ? (
                    <>
                      <ValueRenderer
                        x={revisionNewX as ItemPropertyValue}
                        useFileTransfer={useFileTransfer}
                        parentValues={[]}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </div>
          ),
        },
        { value: l.reason || '-' },
        {
          button: {
            show: () => true,
            icon: ({ onClick }) => (
              <InformationCircleIcon className='inline h-5' onClick={onClick} />
            ),
            behaviour: 'modal',
            modal: {
              title: 'Revision Calculated Summary',
              onClose: () => {},
              content: ({ onClose }) => (
                <div>
                  <SimpleTable
                    header={[
                      [
                        { name: 'Type' },
                        { name: 'Name' },
                        { name: 'Change Effect' },
                        { name: 'Version' },
                        { name: 'Reason' },
                      ],
                    ]}
                    body={l.summary?.details?.map((x) => {
                      return {
                        cells: [
                          { value: x.type },
                          { value: x.name },
                          { value: x.incremented ? 'Yes' : 'Skipped' },
                          { value: x.version },
                          { value: x.reason },
                        ],
                      };
                    })}
                  />
                </div>
              ),
            },
          },
          value: (
            <>
              <div>
                {l.summary ? (
                  <>
                    <span className='font-semibold'>
                      {' '}
                      {l.status === 'approved'
                        ? 'Version:'
                        : 'Projected Version:'}
                    </span>
                    <span>{l.summary?.projectedVersion}</span>
                  </>
                ) : null}
              </div>
              {l.summary ? (
                <>
                  <div>
                    <span className='font-semibold'>
                      Downstream Version Increments:
                    </span>
                    <span>
                      &nbsp;{l.summary?.downstreamVersionIncrements?.stockgroup}{' '}
                      - stockgroups, &nbsp;
                      {l.summary?.downstreamVersionIncrements?.item} - items,
                      &nbsp;{l.summary?.downstreamVersionIncrements?.grade} -
                      grades,
                    </span>
                  </div>
                  <div>
                    <span className='font-semibold'>
                      Downstream Versions skipped:
                    </span>
                    <span>
                      &nbsp;{l.summary?.downstreamVersionSkipped?.stockgroup} -
                      stockgroups, &nbsp;
                      {l.summary?.downstreamVersionSkipped?.item} - items,
                      &nbsp;{l.summary?.downstreamVersionSkipped?.grade} -
                      grades,
                    </span>
                  </div>
                </>
              ) : null}
            </>
          ),
        },
        { value: l.status },
        {
          value: (
            <>
              <userRendererService.userCard
                size='small'
                link={true}
                id={l.requestedBy?.id}
                name={l.requestedBy?.name}
                extraInfo={`requested ${moment.utc(l.requestedDate).fromNow()}`}
              />
              {l.status === 'approved' ? (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={l.approvedBy?.id || '-'}
                  name={l.approvedBy?.name || '-'}
                  extraInfo={`approved ${moment
                    .utc(l.lastModifiedAt)
                    .fromNow()}`}
                />
              ) : null}
            </>
          ),
        },
      ];

      return {
        rowData: {
          revision: l,
        },
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              filter={filter}
              type={{
                defaultPageSize: 25,
                type: 'paginated',
                usePaginatedResources: useCombinedRevision,
              }}
              actions={addActions()}
              auth={currentUser.authorization}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
}
